import { trigger, transition, style, animate, group, state } from "@angular/animations";

export function flyInFromAbove(speed: string) {
  return trigger("flyInFromAbove", [
    transition(":enter", [style({ transform: "translateY(-100%)" }), animate(speed, style({ transform: "translateY(0)" }))]),
    transition(":leave", [style({ transform: "translateY(0)" }), animate(speed, style({ transform: "translateY(-100%)" }))]),
  ]);
}

export function flyInFromBelow(speed: string) {
  return trigger("flyInFromBelow", [
    transition(":enter", [style({ transform: "translateY(100%)" }), animate(speed, style({ transform: "translateY(0)" }))]),
    transition(":leave", [style({ transform: "translateY(0)" }), animate(speed, style({ transform: "translateY(100%)" }))]),
  ]);
}

export function fadeInOut(fadeInSpeed: string, fadeOutSpeed: string) {
  return trigger("fadeInOut", [
    transition(":enter", [style({ opacity: 0 }), animate(fadeInSpeed, style({ opacity: 1 }))]),
    transition(":leave", [style({ opacity: 1 }), animate(fadeOutSpeed, style({ opacity: 0 }))]),
  ]);
}

export function flyInFromRight(speed: string) {
  return trigger("flyInFromRight", [
    transition(":enter", [style({ transform: "translateX(100%)" }), animate(speed, style({ transform: "translateX(0)" }))]),
    transition(":leave", [style({ transform: "translateX(0)" }), animate(speed, style({ transform: "translateX(100%)" }))]),
  ]);
}

export function flyLow() {
  return trigger("flyLow", [
    state(
      "in",
      style({
        "max-height": "650px",
        opacity: "1",
        visibility: "visible",
      }),
    ),
    state(
      "out",
      style({
        "max-height": "0px",
        opacity: "0",
        visibility: "hidden",
      }),
    ),
    transition("in => out", [
      group([
        animate(
          "400ms ease-in-out",
          style({
            opacity: "0",
          }),
        ),
        animate(
          "600ms ease-in-out",
          style({
            "max-height": "0px",
          }),
        ),
        animate(
          "700ms ease-in-out",
          style({
            visibility: "hidden",
          }),
        ),
      ]),
    ]),
    transition("out => in", [
      group([
        animate(
          "1ms ease-in-out",
          style({
            visibility: "visible",
          }),
        ),
        animate(
          "600ms ease-in-out",
          style({
            "max-height": "650px",
          }),
        ),
        animate(
          "800ms ease-in-out",
          style({
            opacity: "1",
          }),
        ),
      ]),
    ]),
  ]);
}
