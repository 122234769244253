import { AuditMetadata } from '../../core/core';

export class CreditSummary {
    totalCreditAmount: number;
    availableCredit: number;
    score: number;
    recommendedCreditAmount: number;
    auditMetadata?: AuditMetadata = new AuditMetadata();
    id?: any;

    static isApproved(creditSummary: CreditSummary, firstMonthsRent: number): CreditApproval {
        if (creditSummary == null) {
            return null;
        }

        // if firsts months rent is greater then 25000 then compare the first months rent. otherwise compare with 25000
        const isApproved = firstMonthsRent > 25000 ?
            creditSummary.availableCredit > firstMonthsRent : creditSummary.availableCredit >= 25000;

        return {
            isApproved,
            totalCreditAmount: creditSummary.availableCredit,
            dateUpdated: creditSummary.auditMetadata.modifiedDateTime
        };
    }
}

export class CreditApproval {
    isApproved: boolean;
    totalCreditAmount: number;
    dateUpdated: string;
}
