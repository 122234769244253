<div *ngIf="location.latitude != null && location.longitude != null; else locationEmpty">
  <div class="d-flex mb-4">
    <div class="mr-auto subhead-1">
      <label>{{ header }}</label>
    </div>

    <div class="update-location-container ml-auto" *ngIf="enableUpdate">
      <common-icon-button iconClass="icon-replacement" buttonText="Update Location" (click)="requestUpdateLocation()"> </common-icon-button>
    </div>
  </div>

  <div class="mb-1">
    <az-map #azureMapComponent [locations]="[location]" [mapConfig]="mapConfig" id="machine-map-location" [markerTemplate]="markerTemplate"> </az-map>
    <ng-template #markerTemplate>
      <div class="custom-marker"></div>
      <div class="custom-marker-background"></div>
    </ng-template>
  </div>

  <div class="ml-3">
    <div class="coordinates pt-3"><i class="icon-crosshair text-red mr-1"></i> {{ location.latitude }}, {{ location.longitude }}</div>
    <div class="last-updated text-grey pt-3"><i class="icon-time mr-1 text-grey"></i> Last Updated: {{ requestDate | timeAgo }}</div>
  </div>
</div>
<ng-template #locationEmpty>
  <div class="w-100 text-center empty-message">
    {{ emptyMessage }}
  </div>
</ng-template>
