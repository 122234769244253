<div rt="owner-card" *ngIf="account">
  <div class="d-flex align-items-center mb-3">
    <div class="mr-2 border rounded-circle" *ngIf="showAccountImage">
      <img height="90" width="90" class="rounded-circle" [src]="logo" (error)="onImgError($event)" />
    </div>
    <div>
      <h3 class="mr-3" [class.section-header]="!searchResults">{{ account.name }}</h3>
      <common-top-owner [badges]="account.badges" text="Top Owner" theme="light"></common-top-owner>
    </div>

    <div class="d-none d-lg-flex align-items-center" *ngIf="searchResults">
      <div class="ml-4 divider border-left text-grey">&nbsp;</div>
      <div class="mx-3 vertical-align-auto">
        <h2 class="statistic-value mb-0">{{ account.availableMachineCount }}</h2>
        <div class="statistic-label">MACHINES</div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row mb-3" *ngIf="!searchResults">
    <div class="mr-3 vertical-align-auto">
      <h2 class="statistic-value mb-0">{{ account.availableMachineCount }}</h2>
      <div class="statistic-label">MACHINES</div>
    </div>
  </div>
  <div class="d-flex flex-row" *ngIf="!searchResults">
    <a [routerLink]="['/fleet', account.name]" class="link-green cursor-pointer">View all machines</a>
  </div>
</div>
