import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { StringTransformations } from "../../../core/utils/string-transformations/string-transformations";
import { StatusService } from "../../../core/services/status.service";

export enum StatusLabelStyle {
  label = "status-label",
  pill = "status-pill",
  btnPill = "status-btn-pill",
  ddlItem = "status-ddl",
}

@Component({
  selector: "common-status-label",
  templateUrl: "status-label.component.html",
  styleUrls: ["./status-label.component.scss"],
})
export class StatusLabelComponent implements OnChanges {
  @Input() status: string;
  @Input() labelStyle: string = StatusLabelStyle.label;
  @Input() labelClass: string;

  statusColor: string;
  borderColor: string;
  statusDescription: string;
  statusFound: boolean;
  statusList = [];
  constructor(private statusService: StatusService) {
    this.statusList = this.statusService.statusList;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status.currentValue !== null && changes.status.currentValue !== undefined) {
      this.setStatus(changes.status.currentValue);
    }
  }

  private setStatus(statusToSearch: string) {
    // insert a space before each capital letter to match the enums of the api
    const selectedStatus = this.statusList.find((status) => status.status === statusToSearch);
    if (selectedStatus) {
      this.statusFound = true;
      this.statusColor = selectedStatus.color || "";
      this.statusDescription = StringTransformations.capitalizeAndSeparate(selectedStatus.status) || "";
      this.borderColor = selectedStatus.border || "";
      return;
    }

    this.statusFound = false;

    // insert a space before each capital letter to get separations when status contains more than one word
    this.statusDescription = statusToSearch ? StringTransformations.capitalizeAndSeparate(statusToSearch) : "";
  }
}
