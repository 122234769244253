<div class="my-2" rt="edit-entity-header">
  <div class="d-flex align-items-center" [class.mb-3]="dateCreated">
    <ng-container *ngIf="showImage">
      <img class="img-fit img-fluid rounded-circle mr-2 img-responsive img-table" style="height: 105px; width: 105px" [src]="imgSrc" *ngIf="imgSrc" />

      <div
        *ngIf="!imgSrc"
        class="align-items-center bg-white justify-content-center rounded img-table flex-column rounded-circle mr-2 cursor-pointer d-none d-lg-flex"
        (click)="noPhoto()"
      >
        <i class="icon-camera fs-20 mb-1 mt-2 mt-md-0"></i>
        <div>Add photo</div>
      </div>
      <div
        *ngIf="!imgSrc"
        class="align-items-center bg-white justify-content-center rounded img-table flex-column mr-2 cursor-pointer d-flex d-lg-none w-25 py-3 rounded-circle"
        (click)="noPhoto()"
      >
        <i class="icon-camera fs-20 mb-1 mt-2 mt-md-0"></i>
        <div>Add photo</div>
      </div>
    </ng-container>
    <div class="d-flex flex-column mr-auto">
      <h3 class="mb-0" rt="entity-header-title">
        {{ title }}
        <i
          *ngIf="showCopyIdToClipboard && entityId"
          [cdkCopyToClipboard]="entityId"
          title="Copy Id to clipboard"
          class="icon-clipboard-fill text-green fs-17 ml-1 cursor-pointer"
        ></i>
      </h3>
      <span class="text-grey fs-15 font-weight-light">{{ subTitle }}</span>

      <div rt="entity-header-status" *ngIf="status" class="mt-2 mt-md-0 d-md-none">
        <common-status-label-dropdown
          *ngIf="dropdownForStatus"
          [disabled]="disableStatusChange"
          [status]="status"
          (statusChange)="statusChanged($event)"
          [dropDownItems]="availableStatusList"
        ></common-status-label-dropdown>
        <common-status-label *ngIf="!dropdownForStatus" labelStyle="status-pill" [status]="status"> </common-status-label>
      </div>
    </div>

    <a *ngIf="externalUrl != null" class="mr-4" [href]="externalUrl" target="_blank">{{ externalText }} <i class="icon-listing ml-1"></i></a>

    <div rt="entity-header-secondary-label">
      <div *ngIf="secondaryLabelText" class="text-grey font-weight-light mr-2 secondary-call-out">
        {{ secondaryLabelText }}
      </div>
    </div>

    <div rt="entity-header-status" *ngIf="status" class="mt-2 mt-md-0 d-none d-md-block">
      <common-status-label-dropdown
        *ngIf="dropdownForStatus"
        [disabled]="disableStatusChange"
        [status]="status"
        (statusChange)="statusChanged($event)"
        [dropDownItems]="availableStatusList"
      ></common-status-label-dropdown>
      <common-status-label *ngIf="!dropdownForStatus" labelStyle="status-pill" [status]="status"> </common-status-label>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="text-grey paragraph-text ml-auto" rt="entity-header-date-created" *ngIf="dateCreated">Date created: {{ dateCreated | date }}</div>
  </div>
</div>
