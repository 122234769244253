import { IStatus, StatusAvailableFor } from "../models/status.model";

const colors = {
  red: { color: "#F53331", border: "#F53331" },
  green: { color: "#0AAB9C", border: "#0AAB9C" },
  whiteWithGreenBorder: { color: "#FFF", border: "#0AAB9C" },
  whiteWithYellowBorder: { color: "#FFF", border: "#FDAF09" },
  yellow: { color: "#FDAF09", border: "#FDAF09" },
  darkGrey: { color: "#96A2A7", border: "#96A2A7" },
  white: { color: "#FFF", border: "#D1D3D5" },
};

export const StatusList: IStatus[] = [
  {
    status: "Active",
    ...colors.green,
    availableForList: [new StatusAvailableFor("Account", 2), new StatusAvailableFor("AccountUser", 1)],
  },
  { status: "Approved", ...colors.green },
  {
    status: "Archived",
    ...colors.white,
    availableForList: [
      new StatusAvailableFor("Machine", 4),
      new StatusAvailableFor("Attachment", 4),
      new StatusAvailableFor("MachineCatalog", 3),
      new StatusAvailableFor("AttachmentCatalog", 3),
      new StatusAvailableFor("MachineType", 3),
      new StatusAvailableFor("AttachmentType", 3),
      new StatusAvailableFor("Machine-Owner", 4),
    ],
  },
  { status: "Archive", ...colors.darkGrey },
  { status: "Arrived", ...colors.green },

  { status: "ArrivingSoon", ...colors.green },
  {
    status: "Available",
    ...colors.green,
    availableForList: [new StatusAvailableFor("Machine", 1), new StatusAvailableFor("Attachment", 1), new StatusAvailableFor("Machine-Owner", 1)],
  },
  {
    status: "Cancelled",
    ...colors.yellow,
    availableForList: [new StatusAvailableFor("TransportationLoad", 3)],
  },
  {
    status: "Canceled",
    ...colors.yellow,
    availableForList: [new StatusAvailableFor("TransportationLoad", 3)],
  },
  { status: "Closed", ...colors.darkGrey },
  { status: "Completed", ...colors.darkGrey },
  { status: "Complete", ...colors.darkGrey },
  { status: "Incomplete", ...colors.yellow },
  { status: "Confirmation", ...colors.whiteWithYellowBorder },
  { status: "Confirmed", ...colors.green },
  { status: "Denied", ...colors.red },
  { status: "Dismissed", ...colors.darkGrey },
  { status: "Failed", ...colors.red },
  {
    status: "Delivered",
    ...colors.green,
    availableForList: [new StatusAvailableFor("TransportationLoad", 2)],
  },
  {
    status: "Draft",
    ...colors.yellow,
    availableForList: [
      new StatusAvailableFor("MachineCatalog", 1),
      new StatusAvailableFor("AttachmentCatalog", 1),
      new StatusAvailableFor("MachineType", 1),
      new StatusAvailableFor("AttachmentType", 1),
    ],
  },
  { status: "Expired", ...colors.red },
  { status: "Fulfillment", ...colors.whiteWithGreenBorder },
  { status: "InProgress", ...colors.whiteWithGreenBorder },
  { status: "InProcess", ...colors.whiteWithGreenBorder },
  { status: "New", ...colors.whiteWithGreenBorder },
  { status: "InTransit", ...colors.green },
  {
    status: "Inactive",
    ...colors.darkGrey,
    availableForList: [new StatusAvailableFor("Account", 3), new StatusAvailableFor("AccountUser", 2)],
  },
  { status: "Invalid", ...colors.red },
  {
    status: "Invited",
    ...colors.yellow,
    availableForList: [new StatusAvailableFor("AccountUser", 3)],
  },
  { status: "Needs more info", ...colors.red },
  { status: "Not Requested", ...colors.darkGrey },
  {
    status: "Onboarding",
    ...colors.yellow,
    availableForList: [new StatusAvailableFor("Account", 4), new StatusAvailableFor("Machine", 2), new StatusAvailableFor("Attachment", 2)],
  },
  { status: "OnRent", ...colors.green },
  { status: "On Rent", ...colors.green },
  { status: "Open", ...colors.green },
  { status: "Processed", ...colors.green },
  { status: "Paid", ...colors.green },
  { status: "PastDue", ...colors.red },
  { status: "PaymentPending", ...colors.yellow },
  { status: "Pending", ...colors.whiteWithYellowBorder },
  { status: "PendingReview", ...colors.whiteWithYellowBorder },
  { status: "Processing", ...colors.yellow },
  {
    status: "Prospect",
    ...colors.darkGrey,
    availableForList: [new StatusAvailableFor("Account", 1)],
  },
  {
    status: "Published",
    ...colors.green,
    availableForList: [
      new StatusAvailableFor("MachineCatalog", 2),
      new StatusAvailableFor("AttachmentCatalog", 2),
      new StatusAvailableFor("MachineType", 2),
      new StatusAvailableFor("AttachmentType", 2),
    ],
  },
  { status: "Ready", ...colors.whiteWithGreenBorder },
  { status: "Repair", ...colors.red },
  { status: "Requested", ...colors.red },
  { status: "Quoted", ...colors.red },
  { status: "Reserved", ...colors.green },
  { status: "Return", ...colors.green },
  { status: "Return Fulfillment", ...colors.green },
  { status: "ReturnFulfillment", ...colors.green },
  { status: "Reverse Fulfillment", ...colors.green },
  {
    status: "Scheduled",
    ...colors.green,
    availableForList: [new StatusAvailableFor("TransportationLoad", 1)],
  },
  {
    status: "Unavailable",
    ...colors.darkGrey,
    availableForList: [new StatusAvailableFor("Machine", 3), new StatusAvailableFor("Attachment", 3), new StatusAvailableFor("Machine-Owner", 3)],
  },
  { status: "Upcoming", ...colors.whiteWithGreenBorder },
  { status: "Unpaid", ...colors.red },
  { status: "Valid", ...colors.green },
  { status: "Verified", ...colors.green },
];
