import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss']
})
export class AccountCardComponent {

  @Input() account: any;
  @Input() yardCity: string = "";
  @Input() brandColor: string = "";
  @Input() logo: string = "";

  constructor() { }

  get companyType() {
    return this.account.companyType.replace(/([A-Z])/g, ' $1').trim();
  }
}
