import { CountValueFacetItem, Facet } from "./facet.model";

export class MapFacet extends Facet {
  mapBounds: MapBounds;

  constructor(name: string, mapBounds: any = null) {
    super(name, []);

    this.mapBounds = mapBounds;
  }

  get selected(): boolean {
    return this.mapBounds != null;
  }

  filter(urlParams: URLSearchParams): string {
    if (this.mapBounds == null) {
      return null;
    }
    return `(geo.intersects(location/point, geography'POLYGON((
              ${this.mapBounds.northWest[0]} ${this.mapBounds.northWest[1]},
              ${this.mapBounds.southWest[0]} ${this.mapBounds.southWest[1]},
              ${this.mapBounds.southEast[0]} ${this.mapBounds.southEast[1]},
              ${this.mapBounds.northEast[0]} ${this.mapBounds.northEast[1]},
              ${this.mapBounds.northWest[0]} ${this.mapBounds.northWest[1]}))'))`;
  }

  clear = () => {
    this.mapBounds = null;
  };

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }
    console.error("need to update this logic");
    this.facets = facets;
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return;
    }
    console.error("NEED TO DO ZOOM QUERY PARAM");
    return `${this.mapBounds.northWest[0]},${this.mapBounds.northWest[1]}:${this.mapBounds.southWest[0]},${this.mapBounds.southWest[1]}:${this.mapBounds.southEast[0]},${this.mapBounds.southEast[1]}:${this.mapBounds.northEast[0]},${this.mapBounds.northEast[1]}`;
  };
}

export class MapBounds {
  southWest: number[];
  northEast: number[];
  northWest: number[];
  southEast: number[];
}
