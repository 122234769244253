export class Charge {
  name: string;
  amount: number;
  quantity: number;
  deferred: boolean;
  chargeCategory: string;
  chargeType: string;
  amountBeforeDiscount: number;
  discountAmount: number;
  discountPercent: number;

  constructor(amount: number, chargeCategory: string, chargeType: string) {
    this.amount = amount;
    this.chargeCategory = chargeCategory;
    this.chargeType = chargeType;
  }
}
