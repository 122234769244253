import { AccountExternalIdentifiers } from "./account.model";

export class User {
  id: string;
  partitionKey: string;
  subjectId: string;
  accountId: string;
  username: string;
  password: string;
  isActive: boolean;
  claims: Claim[];
  clientLogins?: any;
  itemType: string[];
  status: string;
  fullName: string; // only on index
  firstName: string;
  lastName: string;
  phone: string;
  roles: string[];
  email: string;
  location: any;
  profilePicture: string;
  accountName: string; // only on index
  externalIdentifiers: AccountExternalIdentifiers;
  salesCommissionPercentage: number;
  supplyCommissionPercentage: number;

  static hasClaim(claimKey: string, claims: Claim[], expectedValue: string = "true"): boolean | null {
    const claim = claims.find((d) => d.key === claimKey);
    if (!claim) {
      return null;
    }

    return claim.value === expectedValue;
  }
}

export class Claim {
  key: string;
  value: string;

  constructor(key: string, value: string = "") {
    this.key = key;
    this.value = value;
  }
}

export const UserClaimKeys = {
  NotificationSms: "notification.sms",
};
