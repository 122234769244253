import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { EnvironmentConfig, BaseApiService, AuthenticationService, SearchResult } from "../../core/core";
import { switchMap } from "rxjs/operators";
import { TypeDefinition, UnitOfMeasure } from "../models/machine-type.interface";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class InventoryTypeService extends BaseApiService<TypeDefinition> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/type", true, authenticationService);
  }

  getMeasurements(): Observable<SearchResult<UnitOfMeasure>> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}/api/assets/v2/measurement`, { headers });
  }

  setImage(id: string, selectedFile: any): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${id}/image`, uploadData, { headers });
  }

  getSyncStatus(id: string): Observable<boolean> {
    if (!id) {
      return of(false);
    }

    const headers = this.getAuth();
    return this.httpClient
      .get<any>(`${this.baseUrl}/api/assets/v2/type/${id}/status`, { headers })
      .pipe(
        switchMap(resp => {
          return resp.status == "OK" ? of(true) : of(false);
        }),
      );
  }

  getUnitsOfMeasure(): Observable<UnitOfMeasure[]> {
    return of([
      {
        name: "Hours",
        system: "Standard",
        primaryAbbreviation: "hrs",
        alternateAbbreviations: [],
        domain: "Usage",
      } as UnitOfMeasure,
      {
        name: "Miles",
        system: "Standard",
        primaryAbbreviation: "mls",
        alternateAbbreviations: [],
        domain: "Usage",
      } as UnitOfMeasure,
    ]);
  }
}
