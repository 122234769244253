import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfig, BaseApiService, AuthenticationService } from "../../core/core";
import { Email } from "../models/email.model";
import { Sms } from "../models/sms.model";

declare var environment: EnvironmentConfig;

@Injectable({ providedIn: "root" })
export class NotificationService extends BaseApiService<Email> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.adminApiDomain, "/api/admin/v1/notification", true, authenticationService);
  }

  resendEmail(email: Email) {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/email`, email, { headers });
  }

  resendSms(sms: Sms) {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/text`, sms, { headers });
  }
}
