<div *ngIf="iconClass.length" class="input-group">
  <div class="input-group-prepend input-group-text bg-transparent pr-0 icon-input">
    <span [ngClass]="iconClass" class="border-right-0"></span>
  </div>
  <input
    *ngIf="autofocus"
    class="form-control border-left-0 font-weight-light"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (keyup)="keyUpEvent()"
    autofocus
  />
  <input
    *ngIf="!autofocus"
    class="form-control border-left-0 font-weight-light"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (keyup)="keyUpEvent()"
  />
</div>
