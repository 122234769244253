import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../account";
import { Contact, Name } from "../models/contact.model";

@Pipe({
  name: "contact",
})
export class ContactPipe implements PipeTransform {
  constructor() {}

  transform(contact: Contact, accountId?: string, accountName?: string): any {
    if (contact == null) {
      return "";
    }

    let name = "";
    if (!contact.name) {
      name = contact.userId;
    } else if (contact.name?.firstName && contact.name?.lastName) {
      name = `${contact.name.firstName} ${contact.name.lastName}`;
    } else {
      name = contact.name;
    }

    // if we have a user id and account id we can link back to him
    if (contact.userId && accountId) {
      name = `<a target='_blank' href='account/items/${accountId}/users/${contact.userId}'>${name}</a>`;
    }

    let account = "";
    if (accountName) {
      account = `<a target='_blank' href='account/items/${accountId}'>${accountName}</a>`;
    }

    return [name, account, contact.email, contact.phoneNumber].filter(d => d != "").join("<br/>");
  }

  transformUser(user: User) {
    if (user == null) {
      return "";
    }

    let name = user.fullName;
    if (!name) {
      name = `${user.firstName} ${user.lastName}`;
    }

    // if we have a user id and account id we can link back to him
    if (user.id && user.accountId) {
      name = `<a target='_blank' href='account/items/${user.accountId}/users/${user.id}'>${name}</a>`;
    }

    return [name, user.email, user.phone].join("<br/>");
  }
}
