import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "common-spinner-loader",
  templateUrl: "./spinner-loader.component.html",
  styleUrls: ["./spinner-loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerLoaderComponent {
  constructor() {}
}
