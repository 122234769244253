import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class DateRange {
  static monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  fromDate: NgbDate;
  toDate: NgbDate;

  constructor(fromDate: NgbDate, toDate: NgbDate) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  static from(fromDateString: string, toDateString: string): DateRange {
    if (fromDateString == null || toDateString == null) {
      return new DateRange(null, null);
    }

    return new DateRange(this.createNgbDateFromString(fromDateString), this.createNgbDateFromString(toDateString));
  }

  static createDateFromString(dateString: string) {
    // the reason for replacing - to / is because safari
    let date = new Date(dateString.replace(/-/g, "/"));
    if (isNaN(date.getFullYear())) {
      date = new Date(dateString);
    }

    return date;
  }

  static createNgbDateFromString(dateString: string) {
    const date = this.createDateFromString(dateString);
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  static createDateFromNgbDate(ngbDate: NgbDateStruct): Date {
    const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return date;
  }

  static monthDifference(date1: Date, date2: Date) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  get isSelected(): boolean {
    return this.fromDate != null && this.toDate != null;
  }

  get toString() {
    if (this.fromDate == null || this.fromDate.day == null || this.toDate == null || this.toDate.day == null) {
      return "";
    }

    return (
      `${DateRange.monthNames[this.fromDate.month]} ${this.fromDate.day}, ${this.fromDate.year} - ` +
      `${DateRange.monthNames[this.toDate.month]} ${this.toDate.day}, ${this.toDate.year}`
    );
  }

  calculateDaysDiff(): number {
    if (!this.isSelected) {
      return 0;
    }

    const fromDate: Date = DateRange.createDateFromNgbDate(this.fromDate);
    const toDate: Date = DateRange.createDateFromNgbDate(this.toDate);
    const daysDiff = Math.floor(Math.abs((fromDate as any) - (toDate as any)) / (1000 * 60 * 60 * 24));
    return daysDiff;
  }

  get toDaysDiffString(): string {
    const daysDiff = this.calculateDaysDiff();

    const months = Math.floor(daysDiff / 30);
    const days = daysDiff % 30;

    const monthString = months === 1 ? `${months} month` : months > 1 ? `${months} months` : "";
    const dayString = days === 1 ? `${days} day` : days > 1 ? `${days} days` : "";
    const commaString = monthString !== "" && dayString !== "" ? "," : "";

    return `${monthString}${commaString} ${dayString}`;
  }
}
