import { Injectable } from "@angular/core";
import { LogService } from "./log.service";
import { AuthenticationService } from "../core";

@Injectable({
  providedIn: "root",
})
export class ShareService {
  constructor(private logService: LogService, private authenticationService: AuthenticationService) {}

  canShare(): boolean {
    return navigator.share != null;
  }

  share(title: string, text: string, url: string, trackingData?: any) {
    if (trackingData) {
      this.logService.trackEvent(
        "share",
        {
          event: "share",
          ...trackingData,
        },
        true,
      );
    }

    const user = this.authenticationService.getUserNonAsync();
    if (user) {
      if (url.includes("?")) {
        url += `&affId=${user.profile.sub}`;
      } else {
        url += `?affId=${user.profile.sub}`;
      }
    }

    if (!this.canShare()) {
      this.logService.info(this.constructor.name, "navigator.share does not exist on the client, using mailTo functionality.");
      const body = `${text} \n ${encodeURIComponent(url)}`;
      window.open(`mailto:?subject=${title}&body=${body}`);
      return;
    }

    navigator.share({
      title,
      text,
      url,
    });
  }

  shareFile() {
    throw Error("share file not supported yet");
  }

  facebookShare(url: string) {
    window.open(
      `https://www.facebook.com/dialog/share?app_id=782476022712156&display=popup&href=${url}&redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer`,
    );
  }
}
