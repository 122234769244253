<div class="modal-header" rt="update-machine-modal-header">
  <div></div>
  <h4 class="mb-0">Update Status</h4>
  <i class="icon-close text-grey" id="icon-close" (click)="activeModal.dismiss()"></i>
</div>

<div class="modal-body" rt="update-machine-modal-body">
  <div class="pb-2" *ngIf="newStatus==='Available' || newStatus==='Onboarding'">Are you sure you want to set the machine status to {{ newStatus }}?</div>

  <div *ngIf="newStatus==='Archived'">
    <div class="alert alert-danger" role="alert">Warning, you are about to archive this machine. It will no longer show up on your fleet list.</div>
  </div>

  <div *ngIf="newStatus!=='Available'">
    <form class="admin-form" name="update-machine-status-form">
      <div *ngIf="newStatus==='Archived'" class="form-group">
        <label for="unavailabilityReasonSelect">Select archival reason</label>
        <select class="form-control" id="unavailabilityReasonSelect" [(ngModel)]="unavailableReason" (ngModelChange)="onUnavailableReasonChanged($event)">
          <option>Sold machine</option>
          <option>I no longer wish to participate in the Rubbl program</option>
          <option *ngIf="clientId == 'rubbl.admin'">Admin Archive</option>
        </select>
      </div>

      <div *ngIf="newStatus==='Unavailable'" class="form-group">
        <label for="unavailabilityReasonSelect">Select unavailability reason</label>
        <select class="form-control" id="unavailabilityReasonSelect" [(ngModel)]="unavailableReason" (ngModelChange)="onUnavailableReasonChanged($event)">
          <option>Currently in operation</option>
          <option>Mechanical breakdown</option>
        </select>
      </div>

      <div *ngIf="newStatus==='Unavailable'" class="form-group">
        <div class="mt-4 mb-3 subheader">Would you like to make your machine automatically available on a certain date?</div>
        <div class="form-check form-check-inline radio-button">
          <common-radio-button
            [id]="'update-machine-status-modal-on'"
            [groupName]="'update-machine-status-modal'"
            [label]="'Yes'"
            [value]="true"
            [(model)]="isRadioSelected"
          >
          </common-radio-button>
        </div>
        <div class="form-check form-check-inline radio-button">
          <common-radio-button
            [id]="'update-machine-status-modal-off'"
            [groupName]="'update-machine-status-modal'"
            [label]="'No'"
            [value]="false"
            [(model)]="isRadioSelected"
          >
          </common-radio-button>
        </div>

        <div class="p-4" *ngIf="isRadioSelected">
          <label>Select date for which you would like machine to become available again</label>
          <div class="d-flex justify-content-center mb-3">
            <common-date-picker
              [useAsInput]="false"
              [numberOfDaysToBlockOff]="1"
              [maxNumberOfDays]="180"
              #datepicker
              (dateSelected)="autoAvailableDateSelected($event)"
              [date]="startDate"
            ></common-date-picker>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" rt="cancel-button" class="btn btn-outline-dark btn-modal-footer mr-4" id="cancel-accept-modal-cancel" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-modal-footer text-white bg-success"
    id="cancel-accept-modal-accept"
    rt="confirm-button"
    (click)="confirmOrUpdatedClicked()"
    [disabled]="disableConfirm"
  >
    Update
  </button>
</div>
