export class EnvironmentConfig {
  production: string;
  adminApiDomain: string;
  assetsApiDomain: string;
  inspectionsApiDomain: string;
  ordersApiDomain: string;
  transportationApiDomain: string;
  identityProvider: string;
  clientLoginRedirectUri: string;
  clientLogoutRedirectUri: string;
  clientSilentLoginRedirectUri: string;
  clientId: string;
  appInsightsKey: string;
  azureSearchKey: string;
  azureSearchUrl: string;
  azureMapKey: string;
  baseImagePath: string;
  enableLiveChat: string;
  loginRedirect: string;
  helloSignClientId: string;
  routePrefix: string;
  gtmId: string;
  googleAnalyticsId: string;
  identityScope: string;
  buildId: string;
  hostnamePrefix: string;
  stripeKey: string;
}
