<button
  class="navbar-toggler d-flex align-items-center d-lg-none border-0"
  type="button"
  aria-controls="navbarHeader"
  aria-expanded="false"
  aria-label="Toggle navigation"
  (click)="toggleOverlay()"
>
  <img [src]="baseImagePath + '/pubweb/marketplace/images/icons/account.svg'" />
  <div class="ml-2 fs-16" *ngIf="user">{{ user.profile.given_name }}</div>
  <span class="caret icon-carrot" style="color: white; font-size: 10px"></span>
</button>

<div ngbDropdown #myDrop="ngbDropdown" class="d-none d-lg-inline-block" display="dynamic" [autoClose]="true">
  <div class="cursor-pointer account-container text-white d-flex align-items-center" ngbDropdownToggle>
    <img [src]="baseImagePath + '/pubweb/marketplace/images/icons/account.svg'" />
    <div class="ml-2" *ngIf="user">{{ user.profile.given_name }}</div>
    <span class="caret icon-carrot" style="color: white; font-size: 10px"></span>
  </div>
  <div ngbDropdownMenu class="account-dropdown fs-15" id="account-dropdown">
    <div class="arrow-up"></div>
    <ng-container *ngIf="!user">
      <button ngbDropdownItem (click)="login()" rt="login">Login</button>
      <button ngbDropdownItem (click)="signUp()" rt="sign-up">Sign up</button>
      <div class="dropdown-divider"></div>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['/lp/rent-machines']">How Rubbl Works</a>
      <a class="font-weight-bold" ngbDropdownItem href="https://support.rubbl.com/renters">Renter FAQs</a>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['help/rental-terms']">Rental Terms</a>
      <div class="dropdown-divider"></div>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['/lp/list-your-machines']">Become an Owner</a>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['/owner/fleet/create']">List a Machine</a>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['/earnings-calculator']">Earnings Calculator</a>
      <a class="font-weight-bold" ngbDropdownItem href="https://support.rubbl.com/insurance-protection">Insurance & Protection</a>

      <a class="font-weight-bold" ngbDropdownItem href="https://support.rubbl.com/owners">Owner FAQs</a>
      <div class="dropdown-divider"></div>
      <a class="font-weight-bold" ngbDropdownItem [routerLink]="['/lp/careers']">Careers</a>
      <a class="font-weight-bold" ngbDropdownItem href="tel:(866) 455-8531">(866) 455-8531</a>
    </ng-container>
    <ng-container *ngIf="user">
      <div class="px-3">
        <div class="py-3 bb-light" *ngFor="let navigationItem of routesService.allNavigationItems; last as isLast">
          <div class="section-header mb-2 text-blue d-flex align-items-center">
            {{ navigationItem.label }}
          </div>
          <div class="d-flex flex-wrap">
            <div class="mb-2" *ngFor="let subnavItem of navigationItem.children; last as isLast">
              <a class="text-dark-grey" [routerLink]="[subnavItem.route]" (click)="myDrop.close()">{{ subnavItem.label }}</a>
              <span class="mx-2" *ngIf="!isLast">|</span>
            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="section-header mb-2 text-blue d-flex align-items-center">Support</div>
          <div class="d-flex flex-wrap">
            <div class="mb-2">
              <a class="text-dark-grey" href="https://support.rubbl.com" (click)="myDrop.close()">Knowledge base</a>
              <span class="mx-2">|</span>
              <a class="text-dark-grey" href="tel:(866) 455-8531">(866) 455-8531</a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex bt-light px-2 pt-2">
        <button class="btn btn-info ml-auto" (click)="logOut()">Log out</button>
      </div>
    </ng-container>
  </div>
</div>
