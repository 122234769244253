import { Injectable } from "@angular/core";
import { SortList } from "../sort-list/sort-list";

@Injectable()
export class GroupList {
  // used to get the count of groupedBy objects
  static groupByCount(property: string, data: Array<any>): Array<any> {
    let groupedData = [];
    const currentData = data.reduce((p, c) => {
      const name = c[property];
      if (!p.hasOwnProperty(name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});

    Object.entries(currentData).forEach(([key]) => {
      groupedData.push({
        value: key,
        count: currentData[key],
      });
    });

    groupedData = SortList.sortByProperty(groupedData, "value");

    return groupedData;
  }

  static groupBy(property: string, data: Array<any>): { key: string; value: any }[] {
    const groupedData = [];
    const currentData = data.reduce((p, c) => {
      const name = c[property];
      if (!p.hasOwnProperty(name)) {
        p[name] = [c];
        return p;
      }
      p[name].push(c);
      return p;
    }, {});

    Object.entries(currentData).forEach(([key]) => {
      groupedData.push({
        key,
        value: currentData[key],
      });
    });
    return groupedData;
  }
}
