export class SearchModel {
  pageSize: number;
  offset: number;
  sortByField: string;
  sortAscending: boolean;
  continuationToken: string;
  active?: boolean;
  partitionKey: string;

  constructor() {
    // set default value if thing is null
    this.pageSize = 500; // setting it to 500 for now since we are not using paging and it does not work on API
    this.offset = 0;
    this.sortByField = "id";
    this.sortAscending = true;
    this.continuationToken = "";
  }
}
