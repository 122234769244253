import { Params } from "@angular/router";
import { AzureSuggestsResult } from "../../azure-search/azure-search";
import { AttachmentFacet } from "./facets/attachment-facet.model";
import { CountValueFacet } from "./facets/count-value-facet.model";
import { CountValueFacetItem } from "./facets/facet.model";
import { GroupFacet } from "./facets/group-facet.model";
import { LocationFacet } from "./facets/location-facet.model";
import { MapFacet } from "./facets/map-facet.model";
import { SliderFacet } from "./facets/slider-facet.model";
import { PrimaryTypeFacet } from "./facets/primary-type-facet.model";
import { OrderBy } from "./order-by.model";
import { BoolFacet } from "./facets/bool-facet.model";

export class SearchRequest {
  currentLocationSearch: boolean = false;
  searchTerm: string = "";
  selectedSuggestion: AzureSuggestsResult;
  orderBy: OrderBy = new OrderBy();
  pageIndex: number = 0;

  // facets
  location: LocationFacet = new LocationFacet("location");
  facetsSetup: boolean = false;
  map: MapFacet;
  primaryType: PrimaryTypeFacet;
  typeDefinition: CountValueFacet;
  rpoEnabled: BoolFacet;
  buyItNowEnabled: BoolFacet;
  make: CountValueFacet;
  model: CountValueFacet;
  hours: SliderFacet;
  enginePower: SliderFacet;
  year: SliderFacet;
  weight: GroupFacet;
  attachment: AttachmentFacet;
  // end of facets

  initialFilter =
    "(status eq 'Available' or status eq 'Onboarding') and " +
    "(rentalRate ne 0) and " +
    "(requiresAdminApproval eq false) and " + // we can get rid of this in a few days
    "(approvalStatus eq 'Approved' or approvalStatus eq null)";

  constructor() {
    this.map = new MapFacet("map");
  }

  initializeFacets(newFacets: any): void {
    this.facetsSetup ? this.updateFacets(newFacets) : this.setupfacets(newFacets);
  }

  private setupfacets(facets: any) {
    this.primaryType = new PrimaryTypeFacet("primaryType", facets.primaryType || []);
    this.typeDefinition = new CountValueFacet("typeDefinition", facets.typeDefinition || []);
    this.rpoEnabled = new BoolFacet("rpoEnabled", facets.rpoEnabled || [], "Rent to Own");
    this.buyItNowEnabled = new BoolFacet("buyItNowEnabled", facets.buyItNowEnabled || [], "Buy It Now");
    this.make = new CountValueFacet("make", facets.make || []);
    this.model = new CountValueFacet("model", facets.model || []);
    this.hours = new SliderFacet("hours", "hrs", 100, facets.hours || []);
    this.enginePower = new SliderFacet("enginePower", "hp", 1, facets.enginePower || []);
    this.year = new SliderFacet("year", "", 1, facets.year || []);
    this.weight = new GroupFacet("weight", "lbs", facets.weight || []);
    this.attachment = new AttachmentFacet("attachment", facets["relatedAttachments/typeDefinition"], facets["relatedAttachments/size"]);

    this.facetsSetup = true;
  }

  private updateFacets(newFacets) {
    this.primaryType.update(newFacets.primaryType);
    this.typeDefinition.update(newFacets.typeDefinition);
    this.rpoEnabled.update(newFacets.rpoEnabled);
    this.buyItNowEnabled.update(newFacets.buyItNowEnabled);
    this.make.update(newFacets.make);
    this.model.update(newFacets.model);
    this.hours.update(newFacets.hours);
    this.year.update(newFacets.year);
    this.enginePower.update(newFacets.enginePower);
    this.weight.update(newFacets.weight);
    this.attachment.update(newFacets["relatedAttachments/typeDefinition"], newFacets["relatedAttachments/size"]);
  }

  get newsearchFilter(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return [this.location?.filter(urlParams), this.initialFilter].filter(d => d != null).join(" and ");
  }

  get filter(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return [
      this.location?.filter(urlParams),
      this.map?.filter(urlParams),
      this.primaryType?.filter(urlParams),
      this.typeDefinition?.filter(urlParams),
      this.rpoEnabled?.filter(urlParams),
      this.buyItNowEnabled?.filter(urlParams),
      this.make?.filter(urlParams),
      this.model?.filter(urlParams),
      this.hours?.filter(urlParams),
      this.year?.filter(urlParams),
      this.enginePower?.filter(urlParams),
      this.weight?.filter(urlParams),
      this.attachment?.filter(urlParams),
      this.initialFilter,
    ]
      .filter(d => d != null)
      .join(" and ");
  }

  get skip(): number {
    let page: number = 0;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      page = +urlParams.get("page");
      this.pageIndex = page;
    }

    return page * 12;
  }

  get order(): string {
    let orderBy: string = this.orderBy.getFromQueryParam();
    if (orderBy == "sortlocation" || orderBy == null) {
      if (this.location.selected) {
        orderBy = `geo.distance(location/point, geography'POINT(${this.location.location.longitude} ${this.location.location.latitude})') asc`;
      } else {
        orderBy = "viewsTotal desc";
      }
    }

    console.log(orderBy);

    return orderBy;
  }

  toQueryParam() {
    const queryParams: Params = {};
    queryParams["location"] = this.location.toQueryParam();
    queryParams["primaryType"] = this.primaryType.toQueryParam();
    queryParams["typeDefinition"] = this.typeDefinition.toQueryParam();
    queryParams["rpoEnabled"] = this.rpoEnabled.toQueryParam();
    queryParams["buyItNowEnabled"] = this.buyItNowEnabled.toQueryParam();
    queryParams["make"] = this.make.toQueryParam();
    queryParams["model"] = this.model.toQueryParam();
    queryParams["hours"] = this.hours.toQueryParam();
    queryParams["year"] = this.year.toQueryParam();
    queryParams["enginePower"] = this.enginePower.toQueryParam();
    queryParams["weight"] = this.weight.toQueryParam();
    const [attachmentCatalogQueryParam, attachmentSpecQueryParam] = this.attachment.toQueryParam();
    queryParams["attachmentCatalog"] = attachmentCatalogQueryParam;
    queryParams["attachmentSpec"] = attachmentSpecQueryParam;
    queryParams["orderBy"] = this.orderBy.selectedOrderBy || undefined;
    return queryParams;
  }

  // when we change the machine type we want to kill the children facets (make, model, etc....)
  onPrimaryTypeChange(type?: string) {
    this.primaryType.facets = type ? ([{ label: type, count: 1, value: type, checked: true }] as CountValueFacetItem[]) : [];
    this.primaryType.selectedPrimaryType = type ? type : "Any";
    this.typeDefinition.clear();
    this.onTypeDefinitionChange();
  }

  onTypeDefinitionChange() {
    this.make.clear();
    this.onMakeChange();
  }

  onMakeChange() {
    this.model.clear();
    this.onModelChange();
  }

  onModelChange() {
    this.enginePower.clear();
    this.attachment.clear();
    this.weight.clear();
  }

  clearFilters() {
    this.make?.uncheck();
    this.primaryType?.uncheck();
    this.typeDefinition?.uncheck();
    this.rpoEnabled?.uncheck();
    this.buyItNowEnabled?.uncheck();
    this.model?.uncheck();
    this.attachment?.uncheck();
    this.hours?.uncheck();
    this.enginePower?.uncheck();
    this.year?.uncheck();
    this.weight?.uncheck();
    this.orderBy.clear();
  }

  get facetSelectedCount(): number {
    let count = [
      this.typeDefinition,
      this.primaryType,
      this.rpoEnabled,
      this.buyItNowEnabled,
      this.make,
      this.model,
      this.hours,
      this.year,
      this.enginePower,
      this.attachment,
    ].filter(d => d.selected).length;
    if (this.orderBy.selectedOrderBy !== null) {
      count++;
    }
    return count;
  }

  get userSearchedWithSuggestion(): boolean {
    return this.selectedSuggestion != null && this.searchTerm === this.selectedSuggestion.value;
  }

  searchResultsUrl(ownerSearch: boolean = false): string {
    this.facetsSetup = false;
    this.clearFilters();

    let searchResultsUrl = window.location.pathname.startsWith("/map") ? "map" : "equipment-rentals";
    if (ownerSearch) {
      searchResultsUrl = "fleet";
    }

    if (!this.userSearchedWithSuggestion || (this.selectedSuggestion != null && this.selectedSuggestion.autoFacetValues.length === 0)) {
      return `${searchResultsUrl}/${this.searchTerm}`;
    }

    for (const facetValue of this.selectedSuggestion.autoFacetValues.slice(0, 2)) {
      searchResultsUrl += `/${facetValue}`;
    }

    return searchResultsUrl;
  }

  // dont like this implementation but it works
  swapView() {
    this.map.clear();
    let searchResultsUrl = window.location.pathname.startsWith("/map") ? "equipment-rentals" : "map";
    if (!this.userSearchedWithSuggestion || (this.selectedSuggestion != null && this.selectedSuggestion.autoFacetValues.length === 0)) {
      return `${searchResultsUrl}/${this.searchTerm}`;
    }

    for (const facetValue of this.selectedSuggestion.autoFacetValues.slice(0, 2)) {
      searchResultsUrl += `/${facetValue}`;
    }

    return searchResultsUrl;
  }

  get queryParams() {
    const queryParams = {};
    if (this.location?.selected && this.location?.location?.address?.address1 != "Current Location") {
      queryParams["location"] = `${this.location.toQueryParam()}`;
    }

    return queryParams;
  }
}
