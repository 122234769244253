import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { AzureMapConfig } from "../../models/azure-map-config";
import { Address, Location } from "../../../core/core";
import { AzureMapComponent } from "../azure-map/azure-map.component";
import { AzureMapService } from "../../services/azure-map.service";

@Component({
  selector: "common-location-typeahead-map",
  templateUrl: "./location-typeahead-map.component.html",
  styleUrls: ["./location-typeahead-map.component.scss"],
})
export class LocationTypeaheadMapComponent implements OnInit {
  @Input() location: Location;

  @Input() placeHolderText: string = "Enter City";
  @Input() showIcon: boolean = false;
  @Input() width: string; // must set parent form-group width for this to work
  @Input() onlyAddresses: boolean = false;
  @Input() dropdownClass: string = "";
  @Input() valid: boolean = true;
  @Input() addressText: string = "Jobsite";
  @Input() reverseGeoCodeInCoordinates: boolean = false; // if clickToAddMarker is true, then you can set this to true and it will attempt to get an address from the coordinates
  @Input() allowPartialAddress: boolean = false; // if true, only a city and state are required for an address to be returned by the reverse geocode
  @Input() isInModal: boolean = false;
  @Input() displayCityInCoordinates: boolean = false;

  @Output() locationSelected = new EventEmitter<Location>();
  @ViewChild("azureMapComponent") azureMapComponent: AzureMapComponent;

  useCoordinates: boolean = false;
  mapConfig = new AzureMapConfig();

  longitude: string = "";
  latitude: string = "";

  constructor(private mapService: AzureMapService) {}

  ngOnInit() {
    this.mapConfig.zoom = 3;
    if (this.location) {
      // if address is null, use coordinates
      this.useCoordinates = this.location.address === null || this.location.address?.empty === true;
      this.mapConfig.zoom = 12;
      this.longitude = this.location.longitude.toString();
      this.latitude = this.location.latitude.toString();
    } else {
      this.location = new Location();
    }
  }

  locationIsSelected(location: Location) {
    if (location === null || !location?.latitude || !location?.longitude) {
      return;
    }

    if (!location.address?.address1 || !location.address?.city || !location.address?.stateProvince || !location.address?.postalCode) {
      location.address = new Address();
      this.switchToCoordinates();
    } else {
      this.switchToAddress();
    }

    this.location = location;
    this.latitude = this.location.latitude.toString();
    this.longitude = this.location.longitude.toString();
    this.locationSelected.emit(this.location);
  }

  switchToCoordinates() {
    this.useCoordinates = true;
  }

  switchToAddress() {
    this.useCoordinates = false;
  }

  latitudeChange(latitude: string) {
    this.latitude = latitude;
    if (!this.latitude?.length || !this.longitude?.length) {
      this.locationSelected.emit(null);
      return;
    }

    this.updateLocationByCoordinates(latitude, this.longitude);
  }

  longitudeChange(longitude: string) {
    this.longitude = longitude;
    if (!this.latitude?.length || !this.longitude?.length) {
      this.locationSelected.emit(null);
      return;
    }

    this.updateLocationByCoordinates(this.latitude, longitude);
  }

  updateLocationByCoordinates(latitude, longitude) {
    if (!this.location) {
      this.location = new Location();
    }

    this.location.longitude = parseFloat(longitude);
    this.location.latitude = parseFloat(latitude);

    if (this.reverseGeoCodeInCoordinates) {
      this.mapService.getReversedLocation(this.location, this.allowPartialAddress).subscribe(locationReversed => {
        if (!locationReversed.address) {
          locationReversed.address = new Address();
        }
        this.location = locationReversed;
        this.azureMapComponent.locations = [this.location];
        this.azureMapComponent.onLocationChange();
        this.locationSelected.emit(this.location);
      });
    } else {
      this.location.address = new Address();
      this.azureMapComponent.locations = [this.location];
      this.azureMapComponent.onLocationChange();
      this.locationSelected.emit(this.location);
    }
  }
}
