import { OperationalSpec } from './operational-spec.model';
import { AuditMetadata } from '../../core/models/audit-metadata.model';
import { EquipmentCategory } from '../inventory';

export class InventorySpec {
    itemType: string[];
    services: any[];
    attachments: any[];
    engineSpecifications: any[];
    operationalSpecifications: OperationalSpec[];
    partitionKey: string;
    make: string;
    model: string;
    year: string;
    slug?: string;
    version?: any;
    displayName?: string;
    shortDescription?: any;
    categories: EquipmentCategory[];
    applications: any[];
    assembledDimensions: OperationalSpec[];
    shippingDimensions: any[];
    msrp: number;
    auditMetadata: AuditMetadata;
    id?: string;
}
