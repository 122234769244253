<p class="section-header">Rent to Own</p>
<p class="font-weight-light mb-2" [ngClass]="fontClass">
  This equipment is available for purchase. 80% of your rent, up to 6 months, will be applied to the option price.
</p>
<div class="transparent-comparison">
  <table class="border-0 transparent">
    <thead>
      <tr>
        <!-- <th *ngIf="!mobile"></th> -->
        <th class="tl"></th>
        <th class="compare-heading" *ngFor="let header of comparisonTable.headers">
          <ng-container *ngIf="header.title">{{ header.title }}</ng-container>
          <ng-container *ngIf="header.imagePath"> <img [src]="baseImagePath + header.imagePath" /></ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of comparisonTable.rows">
        <tr>
          <td></td>
          <td [attr.colspan]="colspan" [innerHtml]="row?.columns[0]?.label"></td>
        </tr>
        <tr class="compare-row">
          <td [class.bg-secondary]="column.useCheckmark" *ngFor="let column of row.columns; let i = index" [attr.colspan]="i == 0 && !mobile ? '2' : '0'">
            <div *ngIf="column.label" [class.text-white]="column.useCheckmark" [innerHtml]="column.label"></div>
            <div *ngIf="column.useCheckmark && column.label == null" class="checkmark"><i class="icon-checkmark fs-24 text-white"></i></div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<p class="font-weight-light"><small>*All repairs will be added to the purchase price.</small></p>
