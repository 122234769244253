<div id="map" style="height: 100vh"></div>
<!-- <div
  class="filter-mobile br-5 d-block d-xl-none"
  [ngClass]="{ 'text-dark': !accountMapConfig.filtersAppliedCount, 'filter-applied': accountMapConfig.filtersAppliedCount }"
  (click)="showModalFilters()"
>
  Filters {{ accountMapConfig && accountMapConfig.filtersAppliedCount > 0 ? "(" + accountMapConfig.filtersAppliedCount + ")" : "" }}
</div> -->
<nav class="sidebar-container d-none d-xl-block">
  <common-account-map-filter></common-account-map-filter>
</nav>
<nav class="fixed-bottom" *ngIf="accountMapModel != null && (!onMobile || !popup.isOpen())" [@flyInFromBelow]>
  <div class="container bg-white">
    <div class="row">
      <div class="col-md-4 add-border pt-3">
        <div class="media">
          <i class="icon-crawler-loader text-grey mr-4"></i>
          <div class="media-body">
            <h2 class="mt-2">{{ accountMapModel.numberOfMachines }}</h2>
            <p>MACHINES ON RUBBL</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 add-border pt-3">
        <div class="media">
          <i class="icon-money text-secondary mr-4"></i>
          <div class="media-body">
            <h2 class="mt-2">${{ accountMapModel.amountCommitted | thousandSuffix: 1 }}</h2>
            <p>IN COMMITED ASSETS</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 pt-3">
        <div class="media">
          <i class="icon-user text-primary mr-4"></i>
          <div class="media-body">
            <h2 class="mt-2">{{ accountMapModel.numberOfAccounts }}</h2>
            <p>TOTAL PARTNERS</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- <app-mobile-modal id="account-map-filter-modal">
  <div class="close-button-container" (click)="closeModalFilters()">
    <span class="icon-close text-grey"></span>
  </div>
  <div class="custom-modal-body">
    <common-account-map-filter [isMobile]="true"></common-account-map-filter>
  </div>
</app-mobile-modal> -->
