import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "common-edit-inventory-images",
  templateUrl: "./edit-inventory-images.component.html",
  styleUrls: ["./edit-inventory-images.component.scss"],
})
export class EditInventoryImagesComponent {
  @ViewChild("canvas", { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasBg", { static: true }) canvasBgRef!: ElementRef<HTMLCanvasElement>;

  @Input() originalImageSource: string;

  private ctx!: CanvasRenderingContext2D;
  private ctxBg!: CanvasRenderingContext2D;
  globalWidth = window.innerWidth;
  globalHeight = window.innerHeight;
  private ratio = window.devicePixelRatio || 1;
  private drawing = false;
  private color = "#0000ff";
  private mousePos = { x: 0, y: 0 };
  private lastPos = this.mousePos;

  private history = {
    redoList: [] as string[],
    undoList: [] as string[],
    saveState: (canvas: HTMLCanvasElement, list: string[], keepRedo = false) => {
      if (!keepRedo) {
        this.history.redoList = [];
      }
      (list || this.history.undoList).push(canvas.toDataURL());
    },
    undo: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
      this.history.restoreState(canvas, ctx, this.history.undoList, this.history.redoList);
    },
    redo: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
      this.history.restoreState(canvas, ctx, this.history.redoList, this.history.undoList);
    },
    restoreState: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, pop: string[], push: string[]) => {
      if (pop.length) {
        this.history.saveState(canvas, push, true);
        const restoreState = pop.pop()!;
        const image = new Image();
        image.src = restoreState;
        image.onload = () => {
          ctx.clearRect(0, 0, this.globalWidth * this.ratio, this.globalHeight * this.ratio);
          ctx.drawImage(image, 0, 0, this.globalWidth, this.globalHeight);
        };
      }
    },
  };
  ready: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ctx = this.canvasRef.nativeElement.getContext("2d")!;
      this.ctxBg = this.canvasBgRef.nativeElement.getContext("2d")!;
      this.setup();
      this.setBackground();
    }, 1000);
  }

  private setup() {
    window.addEventListener("resize", () => this.rescale());
    this.rescale();
  }

  get cssWrapper() {
    return {
      height: this.globalHeight + "px",
      width: this.globalWidth + "px",
    };
  }

  savePhoto() {
    if (!confirm("Are you sure you want to save this image? This is irreversible. Make sure to download all photos first.")) {
      return;
    }

    var imageCanvas = this.canvasBgRef.nativeElement;
    var drawCanvas = this.canvasRef.nativeElement;

    var imageContext = imageCanvas.getContext("2d");
    imageContext.drawImage(drawCanvas, 0, 0);

    imageCanvas.toBlob(blob => {
      this.activeModal.close(blob);
    });
  }

  private setBackground() {
    const background = new Image();
    background.src = this.originalImageSource;
    background.crossOrigin = "Anonymous";
    background.onload = () => {
      this.ctxBg.drawImage(background, 0, 0, this.globalWidth, this.globalHeight);
    };
  }

  private rescale() {
    const img = new Image();
    img.onload = test => {
      this.canvasRef.nativeElement.width = test.target["width"] * this.ratio;
      this.canvasRef.nativeElement.height = test.target["height"] * this.ratio;

      this.canvasBgRef.nativeElement.width = test.target["width"] * this.ratio;
      this.canvasBgRef.nativeElement.height = test.target["height"] * this.ratio;

      this.globalWidth = test.target["width"];
      this.globalHeight = test.target["height"];

      this.ready = true;
    };
    img.src = this.originalImageSource;

    this.ctx.scale(this.ratio, this.ratio);
    this.setBackground();
    this.ctxBg.scale(this.ratio, this.ratio);
  }

  undo() {
    this.history.undo(this.canvasRef.nativeElement, this.ctx);
  }

  redo() {
    this.history.redo(this.canvasRef.nativeElement, this.ctx);
  }

  watchColorPicker(event: Event) {
    const input = event.target as HTMLInputElement;
    this.color = input.value;
  }

  private getMousePos(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    return { x: event.clientX - rect.left, y: event.clientY - rect.top };
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent) {
    this.drawing = true;
    this.lastPos = this.getMousePos(event);
    this.ctx.beginPath();
    this.ctx.moveTo(this.lastPos.x, this.lastPos.y);
    this.history.saveState(this.canvasRef.nativeElement, this.history.undoList);
  }

  @HostListener("mouseup")
  onMouseUp() {
    this.drawing = false;
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove(event: MouseEvent) {
    if (!this.drawing) return;
    this.mousePos = this.getMousePos(event);
    this.ctx.lineTo(this.mousePos.x, this.mousePos.y);
    this.ctx.lineWidth = this.ratio * 10;
    this.ctx.lineJoin = this.ctx.lineCap = "round";
    this.ctx.strokeStyle = this.color;
    this.ctx.stroke();
    this.lastPos = this.mousePos;
  }
}
