import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { StringTransformations } from "../core/core";

@Injectable()
export class FormUtils {
  static markFormGroup(form: FormGroup) {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();

      if ((control as any).controls) {
        this.markFormGroup(control as FormGroup);
      }
    });
  }

  static showInputError(form: FormGroup, property: string): boolean {
    const control = form.get(property);
    return control.invalid && control.touched && !control.pristine;
  }

  static showControlError(control: FormControl) {
    return control.invalid && control.touched && !control.pristine;
  }

  static updateValueAndValidity(form: FormGroup) {
    Object.values(form.controls).forEach((control) => {
      control.updateValueAndValidity();

      if ((control as any).controls) {
        this.updateValueAndValidity(control as FormGroup);
      }
    });
  }

  static handleServerErrors(form: FormGroup, error: HttpErrorResponse) {
    const validationErrors = error.error;
    Object.keys(validationErrors).forEach((prop) => {
      const formControl = form.get(StringTransformations.camelCase(prop));
      if (formControl) {
        // activate the error message
        formControl.setErrors({
          serverError: validationErrors[prop],
        });
      }
    });
  }

  static patchValue(form: FormGroup, property: string, value: any) {
    const control = form.get(property);
    control.patchValue(value);
  }
}
