import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { LogService } from "../services/log.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private log: LogService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // for some reason the auth guard was getting initialized before the auth serive
    // this led to the user being null when the user would log in and get redirected to an authorized page such as checkout/review
    // which would lead to an infinite loop going from checkout/review to identity to checkout/review to identity and so on and so on

    return new Promise(resolve => {
      this.authenticationService
        .getUser()
        .then(user => {
          if (!user || user.expired) {
            this.log.info(this.constructor.name, "No user, redirecting to login.");
            // unauthenticated user, need to find out why they are
            // our default action is to go to signup, but you can override with route parameter if you want to go to login instead
            resolve(false);

            next.data.login === true ? this.authenticationService.login(state.url) : this.authenticationService.signUp(state.url);
          } else {
            resolve(true);
          }

          const userClaims = user.profile;
          if (next.data.permissionsAll && next.data.permissionsAll.length > 0) {
            for (const perm of next.data.permissionsAll) {
              if (!userClaims.some(c => c.key === perm)) {
                resolve(false);
              }
            }
            resolve(true);
            return true;
          }

          if (next.data.permissionsAny && next.data.permissionsAny.length > 0) {
            for (const perm of next.data.permissionsAny) {
              if (userClaims.some(c => c.key === perm)) {
                resolve(true);
              }
            }
            resolve(false);
          }
          resolve(true);
        })
        .catch(err => {
          resolve(false);
        });
    });
  }
}
