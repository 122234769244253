export abstract class Facet {
  facetType: string;
  name: string;
  label: string;
  facets: CountValueFacetItem[];
  allFacets: CountValueFacetItem[];

  constructor(name: string, facets: CountValueFacetItem[], label?: string) {
    this.name = name;
    this.label = label;
    this.facets = facets;
    this.allFacets = facets;
  }

  abstract get selected(): boolean;
  abstract filter(urlParams: URLSearchParams): string;
  abstract toQueryParam(): string;
  abstract update(facets: CountValueFacetItem[], secondFacets?: any, thirdFacets?: any): void;

  clear = () => {
    this.facets = [];
  };

  uncheck = () => {
    this.facets.forEach((c) => (c.checked = false));
  };

  protected get minValue(): number | string {
    return this.facets.length > 0 ? this.facets[0].value : 0;
  }

  protected get maxValue(): number | string {
    return this.facets.length > 0 ? this.facets[this.facets.length - 1].value : 0;
  }
}

export class CountValueFacetItem {
  label: string;
  value: string | boolean | any;
  count: number;
  checked: boolean;
  parentValue?: string;

  uncheck = () => {
    this.checked = false;
  };
}

export class GroupFacetItem extends CountValueFacetItem {
  from: number;
  to?: number;

  constructor(from: number, to: number = null, endUnit: string = "") {
    super();
    this.from = from;
    this.to = to;

    this.label = this.to != null ? `${from.toLocaleString()} - ${to.toLocaleString()} ${endUnit}` : `${this.from.toLocaleString()}+`;
  }
}
