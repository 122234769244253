import { AuditMetadata } from "../../core/core";

export class InventoryItemSummary {
  id: string;
  accountId: string;
  catalogItemId: string;
  catalogSpecId?: string;
  auditMetadata?: AuditMetadata;
  serialNumber: string;
  make: string;
  model: string;
  primaryType: string;
  usage: string;
  year: number;
  locationSummary: string;
  rubblAssetId: string;
  customerAssetId: string;
  partitionKey: string;
  isActive: boolean;
  rpoEnabled: boolean;
  weight: number;

  constructor(id: string, catalogItemId: string, accountId: string, weight: number) {
    this.id = id;
    this.catalogItemId = catalogItemId;
    this.accountId = accountId;
    this.rpoEnabled = true;
    this.weight = weight;
  }
}
