import { AuditMetadata, Image, Location } from "../../core/core";
import { Industry } from "./industry.interface";
import { BrandProfile } from "../../core/models/branding-profile.model";
import { CreditSummary } from "./credit-summary.model";

export interface Account {
  name: string;
  badges: AccountBadges[];
  brandProfile: BrandProfile;
  logos: Image[];
  logo: string;
  summary?: AccountSummary;
  yards: Location[];
  partnerProgram: PartnerProgram;
  creditSummary: CreditSummary;
  taxId: string;
  externalIdentifiers?: AccountExternalIdentifiers;
  businessHeadquarters: Location;
  industries: Industry[];
  companyType: string;
  phoneNumber: string;
  id: string;
  isActive: boolean;
  status: AccountStatus;
  auditMetadata: AuditMetadata;
  verificationStatus: "Verified" | "Unverified" | "Denied";
  internalNotes: string;
  machines: number;
  users: string[]; // index object
  configuration: any;
  affiliateId: string;
  bypassCreditCard: boolean;
  createdDateTime: string; // index
  displayDate: string; // front end
}

export enum AccountBadges {
  TopOwner = "Top Owner",
}

export enum AccountStatus {
  Prospect = "Prospect",
  Active = "Active",
  Inactive = "Inactive",
  Onboarding = "Onboarding",
}

export enum AccountType {
  RentalCompany = "RentalCompany",
  ServiceProvider = "ServiceProvider",
  Dealer = "Dealer",
  Contractor = "Contractor",
}

export enum AccountVerificationStatus {
  Unverified = "Unverified",
  Verified = "Verified",
  Denied = "Denied",
}

export interface AccountExternalIdentifiers {
  Unknown: string;
  Experian: string;
  HelloSign: string;
  Stripe: string;
  HubSpot: string;
}

export interface PartnerProgram {
  committedMachines?: CommittedMachines;
  totalCommittedMachines: number;
  committedAmount: number;
}

export interface CommittedMachines {
  additionalProp1: number;
  additionalProp2: number;
  additionalProp3: number;
}

export interface AccountSummary extends Account {
  availableMachineCount: number;
  completedFulfillmentsCount: number;
}
