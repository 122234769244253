<div class="thumbnail-container cursor-pointer" [class.cursor-pointer]="thumbnailClickPath != ''">
  <div class="x-container" (click)="xClick()" *ngIf="!hideX"><span class="icon-close"></span></div>
  <img
    *ngIf="imageLoadSuccess"
    class="thumbnail"
    [src]="fullImageUrl"
    [style.height]="height"
    [style.width]="width"
    [style.border-radius]="borderRadius"
    (click)="thumbnailClick()"
  />
  <div class="align-items-center bg-light d-flex justify-content-center rounded img-table flex-column" *ngIf="!imageLoadSuccess">
    <i class="icon-camera fs-20 mb-1 mt-2 mt-md-0"></i>
    <div>No photo</div>
  </div>
</div>
