import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap, startWith, Subscription } from "rxjs";
import { DropDownItem } from "../dropdown/dropdown-item.model";
import { StatusLabelStyle } from "../status-label/status-label.component";

@Component({
  selector: "common-display-entity-header",
  templateUrl: "./display-entity-header.component.html",
})
export class DisplayEntityHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() entityId: string;
  @Input() status: string;
  @Input() dateCreated: string;
  @Input() availableStatusList: DropDownItem[];
  @Input() secondaryLabelText: string;
  @Input() externalUrl: string;
  @Input() externalText: string;
  @Input() showCopyIdToClipboard: boolean;
  @Input() dropdownForStatus: boolean = true;
  @Input() showImage: boolean;
  @Input() imgSrc: boolean;

  @Output() statusChange = new EventEmitter<string>();
  @Output() noPhotoClick = new EventEmitter<null>();

  pillStatusStyle: string = StatusLabelStyle.pill;
  disableStatusChange: boolean = false;
  routerSub: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.routerSub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.router),
        map(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap(route => route.data),
      )
      .subscribe(data => {
        this.disableStatusChange = data.disableStatusChange;
      });

    let optionsArray = ["Canceled", "Cancelled", "Completed", "Complete", "On Rent"];
    if (optionsArray.includes(this.status)) {
      this.dropdownForStatus = false;
    }
  }

  ngOnDestroy() {
    this.routerSub?.unsubscribe();
  }

  statusChanged(newStatus: string) {
    this.statusChange.emit(newStatus);
  }

  copyIdToClipboard() {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", this.entityId);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  noPhoto() {
    this.noPhotoClick.emit();
  }
}
