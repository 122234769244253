import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { ErrorsHandler } from "../core";
import { ToastrService } from "ngx-toastr";
import { NgProgress, NgProgressRef } from "ngx-progressbar";

export abstract class BaseResolverService implements Resolve<any> {
  progressBar: NgProgressRef;

  constructor(private errorHandler: ErrorsHandler, private toastrService: ToastrService, private ngProgress?: NgProgress) {}

  startProgressBar(progressBarId: string = "progressBar") {
    if (!this.ngProgress) {
      this.logError("you are not injecting NgProgress! fail");
      return;
    }
    this.progressBar = this.ngProgress.ref(progressBarId);
    if (!this.progressBar.isStarted) {
      this.progressBar.start();
    }
  }

  finishProgressBar() {
    this.progressBar.complete();
  }

  abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never>;

  logError = (error, showToast: boolean = true) => {
    // this.constructor.name will grab the derived resolvers name, such as RentalResolver and RentalItemResolverService
    this.errorHandler.handleError(error);
    if (showToast) {
      let errorMessage = "Error occurred, contact support.";

      if (error.status === 403) {
        errorMessage = "You are not authorized for this.<br/> Contact support.";
      }

      this.toastrService.error(errorMessage, null, { positionClass: "toast-top-center" });
    }

    this.finishProgressBar();
    return of(null);
  };
}
