export class AccountMapFilterFacets {
  companyTypes: ValueFacet[];
  accountTypes: ValueFacet[];
  constructor() {
    this.companyTypes = [];
    this.accountTypes = [];
  }
}
export class ValueFacet {
  label: string;
  value: string;
  color?: string;
  checked: boolean = false;
}
