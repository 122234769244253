export class DisplayDataSection {
  header?: string;
  subHeader?: string;
  editButtonText?: string;
  editComponent?: any;
  items: DisplayDataItem[];
  disableEditButton: boolean;

  constructor(
    header: string,
    editComponent: any,
    items: DisplayDataItem[],
    editButtonText: string = "Edit",
    subHeader?: string,
    disableEditButton: boolean = false,
  ) {
    this.header = header;
    this.editComponent = editComponent;
    this.items = items;
    this.editButtonText = editButtonText;
    this.subHeader = subHeader;
    this.disableEditButton = disableEditButton;
  }
}

export class DisplayDataItem {
  label: string;
  value: string | number;
  columnClass: string;
  cellClass: string;
  errors: string[];
  useStatusLabel: boolean;
  href: string;

  constructor(
    label: string,
    value: string | number,
    columnClass: string = "col-md-4",
    errors: string[] = [],
    useStatusLabel: boolean = false,
    cellClass?: string,
    href?: string,
  ) {
    this.label = label;
    this.value = value;
    this.columnClass = columnClass;
    this.cellClass = cellClass;
    this.useStatusLabel = useStatusLabel;
    this.errors = errors;
    this.href = href;
  }
}
