import { Pipe, PipeTransform } from '@angular/core';
import { UnitOfMeasure } from '../models/machine-type.interface';

@Pipe({
  name: 'unitString'
})
export class UnitStringPipe implements PipeTransform {

  transform(unit: UnitOfMeasure, args?: any): any {
    throw Error("not sure if needed anymore for marketplace")
    // if (unit == null) {
    //   return "";
    // }

    // if (unit.abbreviations.length > 0 && args !== "full") {
    //   return unit.abbreviations[0];
    // }

    // return unit.fullName;
  }

}
