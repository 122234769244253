<div class="map-wrapper">
  <div [id]="mapId" class="map-absolute-position"></div>
  <div class="click-notification px-3" *ngIf="clickToAddMarker && locationMarker">Click map to change location</div>
</div>
<ng-template #defaultMarker>
  <div class="custom-marker"></div>
  <div class="custom-marker-background"></div>
</ng-template>
<ng-template #defaultPopup>
  <div>
    <h2>~name~</h2>
  </div>
</ng-template>
<div style="display: none">
  <div id="marker" #marker>
    <ng-container *ngTemplateOutlet="markerTemplate ? markerTemplate : defaultMarker"></ng-container>
  </div>
  <div id="popup" #popup>
    <ng-content select="[popupTemplate]"></ng-content>
  </div>
</div>
