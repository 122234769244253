import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";

@Component({
  selector: "common-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent implements OnChanges {
  @Input() groupName: string;
  @Input() id: string;
  @Input() label: string;
  @Input() model: any;
  @Input() value: any;
  @Output() modelChange = new EventEmitter();

  isChecked: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.value) {
      this.isChecked = this.getIsChecked(changes.model.currentValue, changes.value.currentValue);
    }
  }

  updateModel() {
    this.model = this.value;
    this.modelChange.emit(this.model);
    this.isChecked = this.getIsChecked(this.model, this.value);
  }

  getIsChecked(model?: boolean, value?: boolean) {
    if (model === null || model === undefined || value === null || value === undefined) {
      return false;
    }
    return model === value;
  }
}
