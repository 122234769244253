import { Injectable } from '@angular/core';

@Injectable()

export class StringTransformations {

  static capitalizeAndSeparate(input: string) {
    if (!input.length) {
      return "";
    }
    return input.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).trim();
  }

  static camelCase(input: string) {
    return input.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
}
