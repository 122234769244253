import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin } from "rxjs";
import { AccountMapConfig } from "./account-map-config.model";
import { tap } from "rxjs/operators";
import { AccountService } from "../../../account/account";
import { AccountMapFilterFacets, ValueFacet } from "./account-map-filter-facets.model";

@Injectable({
  providedIn: "root",
})
export class AccountMapService {
  constructor(private accountService: AccountService) {}

  accountMapConfig = new AccountMapConfig();
  accountMapConfig$ = new BehaviorSubject<AccountMapConfig>(this.accountMapConfig);

  init() {
    this.init$().subscribe(result => {});
  }

  init$() {
    return forkJoin(this.accountService.getCompanyTypes(), this.accountService.getStatuses()).pipe(
      tap(result => {
        const companyTypes = result[0];
        const statuses = result[1];
        this.accountMapConfig.filtersConfig.companyTypes = [];
        companyTypes.forEach(companyType => {
          this.accountMapConfig.filtersConfig.companyTypes.push({
            label: companyType.displayName,
            value: companyType.companyType,
            color: companyType.color,
            checked: true,
          });
        });
        this.accountMapConfig.companyTypes = companyTypes;
        this.accountMapConfig.statuses = statuses;

        this.accountMapConfig.filtersConfig.accountTypes = [
          { label: "Customers", value: "Active,Onboarding", checked: true },
          { label: "Prospects", value: "Prospect", checked: true },
        ];

        this.accountMapConfig.isInitialized = true;
        this.accountMapConfig$.next(this.accountMapConfig);
      }),
    );
  }

  setConfig(accountMapConfig: AccountMapConfig) {
    this.accountMapConfig = accountMapConfig;
  }

  setFilters(accountMapFilterFacets: AccountMapFilterFacets) {
    this.accountMapConfig.filtersConfig = accountMapFilterFacets;

    //  calculate applied filters
    if (
      this.accountMapConfig.filtersConfig.companyTypes.some(companyType => !companyType.checked) ||
      this.accountMapConfig.filtersConfig.accountTypes.some(accountType => !accountType.checked)
    ) {
      const calculatedFiltersCount = this.calculateFiltersAppliedCount(
        this.accountMapConfig.filtersConfig.companyTypes,
        this.accountMapConfig.filtersConfig.accountTypes,
      );

      const totalFiltersConsiderCount = calculatedFiltersCount[0];
      const totalFiltersAppliedCount = calculatedFiltersCount[1];

      this.accountMapConfig.filtersToConsiderCount = totalFiltersConsiderCount;
      this.accountMapConfig.filtersAppliedCount = totalFiltersAppliedCount;
    } else {
      this.accountMapConfig.filtersAppliedCount = 0;
      this.accountMapConfig.filtersToConsiderCount = 0;
    }

    this.accountMapConfig$.next(this.accountMapConfig);
  }

  calculateFiltersAppliedCount(companyTypes: ValueFacet[], accountTypes: ValueFacet[]): number[] {
    let totalFiltersConsiderCount = 0;
    let totalFiltersAppliedCount = 0;

    //  Consider to count as filter applied only if some item is unchecked
    if (companyTypes.some(companyType => !companyType.checked)) {
      totalFiltersConsiderCount += companyTypes.length;
      totalFiltersAppliedCount += companyTypes.filter(companyType => companyType.checked).length;
    }

    if (accountTypes.some(accountType => !accountType.checked)) {
      totalFiltersConsiderCount += accountTypes.length;
      totalFiltersAppliedCount += accountTypes.filter(accountType => accountType.checked).length;
    }

    return [totalFiltersConsiderCount, totalFiltersAppliedCount];
  }
}
