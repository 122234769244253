import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, NavigationStart } from "@angular/router";
import { PlatformLocation } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private ngbModal: NgbModal, private router: Router, private location: PlatformLocation) {}

  // https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
  open(content: any, config?: any) {
    window["HubSpotConversations"]?.widget?.remove();
    const modal = this.ngbModal.open(content, config);
    const instance = (modal as any)._windowCmptRef.instance;

    const fx = (modal as any)._removeModalElements.bind(modal);
    (modal as any)._removeModalElements = () => {
      if (config && config.windowCloseClass) {
        instance.windowClass = config.windowCloseClass;
      }
      window["HubSpotConversations"]?.widget?.refresh();
      setTimeout(fx, 250);
    };

    const routerSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart && e.navigationTrigger === "popstate") {
        if (modal.dismiss) {
          modal.dismiss();
        }

        if (routerSubscription.unsubscribe) {
          routerSubscription.unsubscribe();
        }
      }
    });

    return modal;
  }

  standardModal(content: any, staticBackDrop: boolean) {
    return this.open(content, {
      windowClass: "default-modal",
      windowCloseClass: "default-modal default-modal-leave",
      backdrop: staticBackDrop ? "static" : true,
    });
  }

  largeModal(content: any, staticBackDrop: boolean) {
    return this.open(content, {
      windowClass: "large-modal",
      windowCloseClass: "large-modal large-modal-leave",
      backdrop: staticBackDrop ? "static" : true,
    });
  }

  extraLargeModal(content: any, staticBackDrop: boolean) {
    return this.open(content, {
      windowClass: "xl-modal",
      size: "xl",
      windowCloseClass: "xl-modal xl-modal-leave",
      backdrop: staticBackDrop ? "static" : true,
    });
  }

  slideFromRight(content: any, staticBackDrop: boolean) {
    return this.open(content, {
      windowClass: "right-aside-modal",
      backdropClass: "right-aside-overlay",
      windowCloseClass: "right-aside-modal right-aside-modal-leave",
      backdrop: staticBackDrop ? "static" : true,
    });
  }

  fullPageModal(content: any) {
    return this.open(content, {
      windowClass: "full-page-modal",
      backdropClass: "full-page-overlay",
      windowCloseClass: "full-page-modal full-page-modal-leave",
      backdrop: false,
    });
  }
}
