<div class="mobile-search-overlay no-scroll">
  <div class="mobile-search-overlay-container px-3 py-2">
    <form (submit)="search()">
      <div class="d-flex align-items-baseline">
        <div class="form-group transparent">
          <label for="machine-type-simple"><span class="icon-mag-glass text-grey"></span></label>
          <input
            type="text"
            class="form-control text-white search-term-input"
            [class.active]="searchSuggestions.showSearchSuggestions"
            id="machine-type-simple"
            autocomplete="off"
            placeholder="Skid, dozer, excavator, etc"
            name="searchterm"
            #headerSearchInput
            [(ngModel)]="searchRequest.searchTerm"
          />
        </div>
        <button class="btn btn-danger" (click)="search(searchRequest.searchTerm)">Search</button>
      </div>

      <div class="search-suggestions" [class.active]="searchSuggestions.showSearchSuggestions">
        <app-search-suggests
          [searchInput]="headerSearchInput"
          customClass="search-results-suggestions"
          [useSearchSuggestionsOverlay]="false"
          (suggestionSelected)="suggestionSelected($event)"
          #searchSuggestions
        >
        </app-search-suggests>
      </div>
    </form>
  </div>
</div>
