import { DropDownItem } from "../../../forms/forms";
import { CountValueFacetItem, Facet } from "./facet.model";

// this can probably be a dropdown facet to be more generic
export class PrimaryTypeFacet extends Facet {
  // probably dont need the primaryTypes dropdown items, would need to rework show/hide logic of make and model on the sidebar search results
  primaryTypes: DropDownItem[];
  showDropdown: boolean = false;
  selectedPrimaryType: string = "Any";

  constructor(name: string, facets: CountValueFacetItem[]) {
    super(name, facets);

    this.primaryTypes = facets?.map(d => {
      return { label: d.value, value: d.value, subLabel: `(${d.count.toString()})` } as DropDownItem;
    });

    this.showDropdown = facets?.length > 1;
    this.selectedPrimaryType = "Any";
  }

  get selected(): boolean {
    return this.selectedPrimaryType != "Any";
  }

  uncheck = () => {
    this.selectedPrimaryType = "Any";
    this.facets.forEach(c => (c.checked = false));
  };

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    if (param) {
      // iterate through all the primary types
      for (const dropdownItem of this.primaryTypes) {
        // once we find the selected one we need to updat the facets to be just one
        // and set the selected primary type
        // and push the filter onto it
        if (dropdownItem.value === param) {
          // we found one, lets return the filter right away
          this.facets = [{ label: param, value: param, checked: true } as CountValueFacetItem];
          this.selectedPrimaryType = param;
          return `(${this.name} eq '${param}')`;
        }
      }
    }

    // if we couldnt find a primary type then lets reset the facets
    this.selectedPrimaryType = "Any";
    this.facets = this.primaryTypes?.map(d => {
      return { label: d.value, value: d.value } as CountValueFacetItem;
    });
    return null;
  }

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }

    this.facets = facets;
    // since we update the facets to just be one in the filter method
    // we need to update the primary types back from the facets
    this.primaryTypes = facets?.map(d => {
      return { label: d.value, value: d.value, subLabel: `(${d.count.toString()})` } as DropDownItem;
    });
    this.showDropdown = facets?.length > 1;
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return this.selectedPrimaryType;
  };
}
