export class AzureSearchRequest {
  count: boolean = true;
  facets: string[] = [];
  filter: string = "";
  highlight: string;
  highlightPreTag: string = "<em class='highlight'>";
  highlightPostTag: string = "</em>";
  minimumCoverage: number;
  orderby: string;
  scoringParameters: string[] = [];
  scoringProfile: string;
  search: string = ""; // the text to search for
  searchFields: string;
  searchMode: string = "any";
  select: string;
  skip: number = 0;
  top: number = 15;
  queryType: string = "simple";
}
