import { Component } from "@angular/core";
import { AuthenticationService, EnvironmentConfig } from "../../../core/core";
import { OverlayService } from "../../services/overlay.service";
import { User } from "oidc-client";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { RoutesService } from "../../services/routes.service";

declare var environment: EnvironmentConfig;
@Component({
  selector: "app-header-account",
  templateUrl: "./header-account.component.html",
  styleUrls: ["./header-account.component.scss"],
})
export class HeaderAccountComponent {
  user: User;
  showHeaderOverlay = false;
  baseImagePath: string;

  constructor(
    private overlayService: OverlayService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public routesService: RoutesService,
  ) {
    this.baseImagePath = environment.baseImagePath;
    this.authenticationService.getUser().then(user => (this.user = user));

    // subscribe to route events to always close the header
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(result => {
      if (this.showHeaderOverlay) {
        this.showHeaderOverlay = false;
        this.overlayService.closeOverlay("header");
      }
    });
  }

  toggleOverlay() {
    this.showHeaderOverlay = !this.showHeaderOverlay;
    this.overlayService.toggleOverlay("header");
  }

  signUp() {
    this.authenticationService.signUp();
  }

  login() {
    this.authenticationService.login();
  }

  logOut() {
    this.authenticationService.logout();
  }
}
