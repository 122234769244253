import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { EnvironmentConfig } from "../../../core/core";

declare const environment: EnvironmentConfig;

@Component({
  selector: "common-update-machine-status-modal",
  templateUrl: "./update-machine-status-modal.html",
  styleUrls: ["./update-machine-status-modal.scss"],
})
export class UpdateMachineStatusModal implements OnInit {
  clientId = environment.clientId;
  newStatus: string;
  unavailableReason: string = "";
  autoAvailableDate: string = "";

  isUnavailableReasonSelected: boolean = false;
  disableConfirm: boolean = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.newStatus === "Available" || this.newStatus === "Onboarding") {
      this.disableConfirm = false;
    }
  }

  autoAvailableDateSelected(date: NgbDate) {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    this.autoAvailableDate = jsDate.toISOString();
  }

  onUnavailableReasonChanged(reason: string) {
    this.unavailableReason = reason;
    this.isUnavailableReasonSelected = true;
    this.disableConfirm = false;
  }

  confirmOrUpdatedClicked() {
    this.activeModal.close({
      unavailableReason: `${this.unavailableReason}`,
      autoAvailableDate: `${this.autoAvailableDate}`,
    });
  }
}
