import { Directive, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "../../services/modal.service";
import { LogService } from "../../services/log.service";
import { ApiTableComponent, IndexTableComponent } from "../../../tables/tables";

@Directive()
export abstract class BaseEntityTableComponent {
  @ViewChild("apiTableComponent") apiTableComponent: ApiTableComponent;
  @ViewChild("indexTableComponent") indexTableComponent: IndexTableComponent;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected logService: LogService,
    protected editEntityModalComponent: any = null,
  ) {}

  create() {
    if (!this.editEntityModalComponent) {
      console.error("need to inject an edit entity modal component");
      return;
    }

    const modalRef = this.modalService.slideFromRight(this.editEntityModalComponent, false);
    modalRef.componentInstance.newEntity = true;
    this.addToCreateModalRef(modalRef);
    modalRef.result.then(
      (entity: any) => {
        // after server response from save
        this.afterCreateModalSuccess(entity);

        this.refreshTableData();
      },
      () => {
        // fail
      },
    );
  }

  edit(entity: any) {
    if (!this.editEntityModalComponent) {
      console.error("need to inject an edit entity modal component");
      return;
    }

    const modalRef = this.modalService.slideFromRight(this.editEntityModalComponent, true);
    modalRef.componentInstance.entity = Object.assign({}, entity);
    modalRef.componentInstance.newEntity = false;
    modalRef.componentInstance.activatedRoute = this.activatedRoute;

    this.addToEditModalRef(modalRef);

    modalRef.result.then(
      (entity: any) => {
        // after server response from save
        this.afterEditModalSuccess(entity);

        // refresh api/index table
        this.refreshTableData();
      },
      () => {
        // fail
      },
    );
  }

  refreshTableData() {
    // if we have an api table component lets refresh the data
    this.apiTableComponent?.search();

    // if we have index table component we need to grab the page offset from the query string and do a search
    if (this.indexTableComponent) {
      let paramMap = this.activatedRoute.snapshot.queryParams;
      this.indexTableComponent.search({ offset: +paramMap["offset"] || 0 }, true);
    }
  }

  addToCreateModalRef(modalRef: NgbModalRef) {}
  addToEditModalRef(modalRef: NgbModalRef) {}
  afterCreateModalSuccess(entity: any) {}
  afterEditModalSuccess(entity: any) {}
}
