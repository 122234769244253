import { Address } from "./address.model";

export interface ILocation {
  latitude: number;
  longitude: number;
  name: string;
  address?: Address;
  properties?: any;
}

export class Location {
  latitude: number;
  longitude: number;
  name: string;
  address?: Address = new Address();
  point: any;
  properties?: any;
  title: string; // front end only

  constructor() {
    this.latitude = undefined;
    this.longitude = undefined;
    this.name = "";
    this.address = new Address();
  }

  static fromIndexLocation(inventoryItemPoint: IndexLocation) {
    return { latitude: inventoryItemPoint.coordinates[1], longitude: inventoryItemPoint.coordinates[0] } as Location;
  }
}

// i believe this is only used for index results
export class IndexLocation {
  type: string;
  coordinates: number[];
  crs: Crs;
  name: string;
  address: Address;
}
export class Crs {
  type: string;
  properties: Properties;
}

export class Properties {
  name: string;
}
