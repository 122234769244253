import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentConfig, BaseApiService, AuthenticationService } from "../../core/core";
import { MachineCatalog } from "../inventory";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class CatalogService extends BaseApiService<MachineCatalog> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/catalog", true, authenticationService);
  }

  getByEquipmentWatchId(make: string, model: string, equipmentWatchId: number) {
    let params = new HttpParams();
    params = params.append("make", make);
    params = params.append("model", model);
    params = params.append("ewid", equipmentWatchId);

    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}`, { params });
  }

  getImages(catalogId: string) {
    return this.httpClient.get(`${this.baseUrl}${this.endpoint}/${catalogId}/image`);
  }

  uploadImage(catalogId: string, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${catalogId}/image`, uploadData, { headers });
  }

  deleteImage(catalogId: string, imageId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${catalogId}/image/${imageId}`, { headers });
  }
}
