import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../models/environment-config.model';

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor() { }

  get() {
    console.warn(`this is deprecated, use environment instead of`);
    if (typeof environment === 'undefined') {
      return new EnvironmentConfig();
    }
    return environment;
  }
}
