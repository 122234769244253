import { CountValueFacetItem, Facet } from "./facet.model";

export class ParentChildCollectionFacet extends Facet {
  queryStringName: string;
  allFacets: CountValueFacetItem[];

  constructor(name: string, queryStringName: string, facets: CountValueFacetItem[]) {
    super(name, facets);
    this.queryStringName = queryStringName;

    this.facets.forEach(d => {
      d.label = d.value.split("|").pop();
    });

    this.allFacets = Object.assign([], this.facets);
  }

  get selected(): boolean {
    return this.facets.some(d => d.checked);
  }

  filter(urlParams: URLSearchParams, join: string = "or"): string {
    const param = urlParams.get(this.queryStringName);
    if (!param) {
      this.uncheck();
      return null;
    }

    const filters = [];
    const split = this.name.split("/");
    const parent = split[0];
    const child = split[1];
    const selectedValues = param.split(",");
    for (let facet of this.facets) {
      if (selectedValues.includes(facet.value)) {
        facet.checked = true;
        filters.push(`${parent}/any(t: t/${child} eq '${facet.value}')`);
      } else {
        facet.checked = false;
      }
    }

    return filters.length ? `(${filters.join(` ${join} `)})` : null;
  }

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }

    this.facets = facets;
    this.facets.forEach(d => {
      d.label = d.value.split("|").pop();
    });

    this.allFacets = Object.assign([], this.facets);
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return this.facets
      .filter(d => d.checked)
      .map(d => d.value)
      .join(",");
  };
}
