import { SearchModel } from "../../core/models/search.model";

export class NotificationSearchModel extends SearchModel {
  public rentalRequestId: string;
  public fulfillmentId: string;

  constructor() {
    super();
  }
}
