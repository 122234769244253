import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private overlays = {}; // key value. key is the name of overlay. value is boolean to determine to show overlay or not
  private overlaysBSubject = new BehaviorSubject({});

  constructor() { }

  registerOverlay(overlayName: string) {
    this.overlays[overlayName] = false;
  }

  subscribeToOverlayUpdates(overlayName: string, callback: any): Subscription {
    return this.overlaysBSubject.subscribe((overlays) => {
      callback(overlays[overlayName]);
    });
  }

  closeOverlay(overlayName: string) {
    this.overlays[overlayName] = false;
    this.overlaysBSubject.next(this.overlays);
  }

  toggleOverlay(overlayName: string) {
    this.overlays[overlayName] = !this.overlays[overlayName];
    this.overlaysBSubject.next(this.overlays);
  }

  toggleOverlayWithBool(overlayName: string, show: boolean) {
    this.overlays[overlayName] = show;
    this.overlaysBSubject.next(this.overlays);
  }
}
