export class AzureSuggestsResult {
    displayText: string;
    value: string;
    autoFacetFields: string[];
    autoFacetValues: string[];

    constructor(displayText: string, value: string, autoFacetFields: string[], autoFacetValues: string[]) {
        this.displayText = displayText;
        this.value = value;
        this.autoFacetFields = autoFacetFields;
        this.autoFacetValues = autoFacetValues;
    }
}
