import { Directive, HostListener, Input } from '@angular/core';
import { LogService } from '../services/log.service';


@Directive({
  selector: '[commonEventTracker]'
})
export class EventTrackerDirective {

  @Input('commonEventTracker') eventName: string;
  @Input() properties: { [key: string]: any };

  @HostListener('click', ['$event'])
  onClick(event) {
    this.logService.trackEvent(this.eventName, this.properties);
  }

  constructor(private logService: LogService) { }
}
