import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UnitOfMeasure } from '../models/machine-type.interface';

@Pipe({
  name: 'specMeasurement',
  pure: false
})
export class SpecMeasurementPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(measurements: UnitOfMeasure[], args?: any): any {
    throw Error("need to update here for marketplace");
    // if (measurements == null || measurements.length === 0) {
    //   return "";
    // }

    // let measurement = null;
    // switch (args) {

    //   case "metric":
    //     measurement = measurements.find(d => d.system === SystemOfMeasure.Metric);
    //     break;

    //   case "standard":
    //   default:
    //     measurement = measurements.find(d => d.system === SystemOfMeasure.Standard);
    //     break;
    // }

    // // grab the first item in the array and return the value
    // if (measurement == null) {
    //   return measurements[0].value;
    // }

    // let unit = "";
    // if (measurement.unit != null) {
    //   unit = measurement.unit.abbreviations != null && measurement.unit.abbreviations.length > 0
    //     ? measurement.unit.abbreviations[0]
    //     : measurement.unit.fullName;
    // }

    // if (!measurement.value) {
    //   measurement.value = 0;

    // }

    // return `${this.decimalPipe.transform(measurement.value)}${unit}`;
  }

}
