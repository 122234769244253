import { AccountMapFilterFacets } from "./account-map-filter-facets.model";

export class AccountMapConfig {
  isInitialized: boolean = false;
  filtersConfig: AccountMapFilterFacets;
  filtersToConsiderCount: number = 0;
  filtersAppliedCount: number = 0;
  statuses: string[] = [];
  companyTypes: any[] = [];
  constructor() {
    this.filtersConfig = new AccountMapFilterFacets();
  }
}
