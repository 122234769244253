import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseApiService, AuthenticationService, EnvironmentConfig } from "../../core/core";
import { AssociatedItem } from "../inventory";
import { Observable } from "rxjs";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class AssociatedItemService extends BaseApiService<AssociatedItem> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/inventory", true, authenticationService);
  }

  createAssociatedItem(inventoryId: string, model: AssociatedItem): Observable<AssociatedItem> {
    const headers = this.getAuth();
    return this.httpClient.post<AssociatedItem>(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment`, model, { headers });
  }

  updateAssociatedItem(inventoryId: string, id: string, model: AssociatedItem): Observable<AssociatedItem> {
    const headers = this.getAuth();
    return this.httpClient.put<AssociatedItem>(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${id}`, model, { headers });
  }

  deleteAssociatedItem(inventoryId: string, id: string, accountId: string) {
    const headers = this.getAuth();
    let params = new HttpParams();
    params = params.append("accountId", accountId);
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${id}?accountId=${accountId}`, { headers, params });
  }
}
