
export class RangeSlider {
    unit: string = "";

    step: number = 50;

    minValue: number = 0;
    maxValue: number = 5000;

    floor: number = 0;
    ceil: number = 5000;

    constructor(unit: string, step: number, minValue: number, maxValue: number) {
        this.unit = unit;
        this.step = step;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.floor = minValue;
        this.ceil = maxValue;
    }

    get sliderSelected(): boolean {
        return this.ceil !== this.maxValue || this.floor !== this.minValue;
    }
}
