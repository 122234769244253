import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'common-cart-item-remove-confirmation',
  templateUrl: './cart-item-remove-confirmation.component.html'
})
export class CartItemRemoveConfirmationComponent {

  cartItemTitle: string = "";
  constructor(public activeModal: NgbActiveModal) { }

}
