import { Component, Input, OnInit } from "@angular/core";
import { CartService } from "../../../cart.service";
import { Attachment, InventoryService } from "../../../../inventory/inventory";

@Component({
  selector: "common-add-to-cart-attachments",
  templateUrl: "./add-to-cart-attachments.component.html",
  styleUrls: ["./add-to-cart-attachments.component.scss"],
})
export class AddToCartAttachmentsComponent implements OnInit {
  @Input() machineId: string;
  @Input() attachments: Attachment[];
  @Input() attachmentsFromIndex: Attachment[];

  standardAttachments: Attachment[];
  nonStandardAttachments: Attachment[];

  standardFront: Attachment[];
  standardRear: Attachment[];
  nonStandardFront: Attachment[];
  nonStandardRear: Attachment[];

  constructor(private inventoryService: InventoryService, private cartService: CartService) {}

  ngOnInit(): void {
    const attachmentIdsInCart = this.cartService.getOtherAttachmentIds(this.machineId);
    for (let attachment of this.attachments) {
      const attachmentFromIndex = this.attachmentsFromIndex.find(d => d.id === attachment.inventoryItemId);
      if (!attachmentFromIndex) {
        continue;
      }

      if (attachmentFromIndex.specifications?.length) {
        attachment.specifications = attachmentFromIndex.specifications;
        attachment.description = this.inventoryService.getFormattedSpecifications(attachmentFromIndex.specifications);
      }

      attachment.alreadyInCart = attachmentIdsInCart.includes(attachment.inventoryItemId);
      attachment.compatibleWith = attachmentFromIndex.compatibleWith;
      attachment.accountId = attachmentFromIndex.accountId;
      attachment.childItemId = attachment.inventoryItemId; // cart item uses child item id
      attachment.price = attachment.cost; // cart item uses price
    }

    this.standardAttachments = this.attachments.filter(d => d.standard);
    this.nonStandardAttachments = this.attachments.filter(d => !d.standard);

    this.standardFront = this.standardAttachments.filter(d => d.position === "Front");
    this.standardRear = this.standardAttachments.filter(d => d.position !== "Front");

    this.nonStandardFront = this.nonStandardAttachments.filter(d => d.position === "Front");
    this.nonStandardRear = this.nonStandardAttachments.filter(d => d.position !== "Front");
  }

  selectAttachment(attachment: Attachment) {
    // first select or deselect attachment
    attachment.selected = !attachment.selected;

    // if it is getting deselected, we need to deselect install
    if (!attachment.selected) {
      attachment.install = false;
      // if this attachment that we are deselecting has a price of 0. we need to grab the other first standard attachment and set to 0
      if (attachment.price === 0) {
        const selectedAttachment = this.standardAttachments.find(d => d.selected);
        if (selectedAttachment) {
          selectedAttachment.price = 0;
        }
      }
      attachment.price = attachment.cost;

      return;
    }

    if (attachment.standard) {
      // if attachment is standard we need to see if this is the first one selected. if it is then set the cost/price to $0
      const standardAttachmentsInstalled = this.standardAttachments.some(d => d.selected && d.inventoryItemId != attachment.inventoryItemId);
      if (!standardAttachmentsInstalled) {
        attachment.price = 0;
      }
    }

    // check if this is the first attachment that is selected, because we need to set it to install
    const attachmentsAlreadySelected = this.attachments.some(d => d.selected && d.inventoryItemId != attachment.inventoryItemId);
    if (!attachmentsAlreadySelected) {
      attachment.install = true;
    }
  }

  installAttachment(attachment: Attachment) {
    attachment.install = !attachment.install;

    //  if we are installing attachment we need to un install all the otther ones that are not compatable
    if (attachment.install) {
      // if attachment is standard we need to see if this is the first one selected. if it is then set the cost/price to $0
      const standardAttachmentsInstalled = this.standardAttachments.some(d => d.selected && d.inventoryItemId != attachment.inventoryItemId);
      if (!standardAttachmentsInstalled && attachment.standard == true) {
        attachment.price = 0;
      }

      const installedAttachments = this.attachments.filter(d => d.install && d.inventoryItemId != attachment.inventoryItemId);
      if (installedAttachments.length) {
        // we need to iterate over all installed attachments to see if either 1 of them is compatable.
        for (const installedAttachment of installedAttachments) {
          if (
            !installedAttachment.compatibleWith.includes(attachment.typeDefinitionId) &&
            !attachment.compatibleWith.includes(installedAttachment.typeDefinitionId)
          ) {
            // the installed attachment or either the attachment we are selecting to install is not compatable with each other
            installedAttachment.install = false;
          }
        }
      }
    }

    attachment.selected = true;
  }
}
