import { Account } from "../../../account/account";

export class Feature<T> {
  geometry: Geometry;
  id: string;
  properties: T;
  type: string = "Feature";

  constructor(geometry: Geometry, properties: T) {
    this.geometry = geometry;
    this.properties = properties;
  }
}

export class Geometry {
  coordinates: number[];
  type: string;
  city: string;

  constructor(coordinates: number[], type: string, city: string) {
    this.coordinates = coordinates;
    this.type = type;
    this.city = city;
  }
}
export class AccountMapModel {
  type: string = "FeatureCollection";
  features: Feature<Account>[] = [];

  numberOfMachines: number = 0;
  amountCommitted: number = 0;
  numberOfAccounts: number = 0;

  constructor(accounts: any[] = []) {
    for (const account of accounts) {
      this.numberOfMachines += account.machinesCommitted;
      this.amountCommitted += account.amountCommitted;
      if (account.amountCommitted !== 0) {
        this.numberOfAccounts++;
      }

      if (account.locations == null || account.locations.length === 0) {
        continue;
      }

      for (const yard of account.locations) {
        this.features.push(new Feature(new Geometry(yard.point.coordinates, yard.point.type, yard.name), account));
      }
    }
  }
}
