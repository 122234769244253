<div class="card mb-4 box-shadow cursor-pointer border-0" (click)="navigate()">
  <div class="img-hover-zoom">
    <img class="card-img-top swiper-lazy" [attr.data-src]="cardImgSrc" />

    <div class="preloader" style="padding: 4.5rem"><common-spinner-loader></common-spinner-loader></div>
  </div>
  <div class="card-body">
    <h2 class="card-text">{{ header }}</h2>
    <p class="card-text">{{ subHeader }}</p>
  </div>
</div>
