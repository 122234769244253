import { Component, Input } from "@angular/core";
import { EnvironmentConfig, AuthenticationService } from "../../../core/core";
import { NavigationItem } from "../../models/navigation-item.model";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-internal-header-account",
  templateUrl: "./internal-header-account.component.html",
  styleUrls: ["./internal-header-account.component.scss"],
})
export class InternalHeaderAccountComponent {
  @Input() navigationItems: NavigationItem[];
  @Input() isLoggedIn: boolean;

  baseImagePath: string = environment.baseImagePath;

  constructor(private authenticationService: AuthenticationService) {}

  logIn() {
    this.authenticationService.login();
  }

  logOut() {
    this.authenticationService.logout();
  }
}
