import { Pipe, PipeTransform } from '@angular/core';
import { Charge } from '../../cart/cart';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'charge'
})
export class ChargePipe implements PipeTransform {

  constructor(private numberPipe: DecimalPipe) { }

  transform(charges: Charge[], chargeType: string = "Monthly", chargeCategory: string = "Rent"): any {

    const correspondingCharge = charges.find(d => d.chargeType == chargeType && d.chargeCategory == chargeCategory);
    if (correspondingCharge == null) {
      return null;
    }

    return this.numberPipe.transform(correspondingCharge.amount);
  }

}
