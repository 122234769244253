import { Component, OnInit } from "@angular/core";
import { AuthenticationService, LogService } from "@rubbl-core";
import { HeaderService } from "@rubbl-layout";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "admin";

  constructor(private authService: AuthenticationService, private logger: LogService, private headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.showAdminHeaderItems();
  }
}
