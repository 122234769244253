<div class="card mb-3 box-shadow cursor-pointer border-0" rt="account-card">

  <div class="px-2 account-card-container">

    <div *ngIf="brandColor.length || logo.length" class="d-flex branding-header" [ngStyle]="{ 'background-color': brandColor.length ? brandColor : '#ffffff' }">
      <div class="branding-logo" [ngStyle]="{ 'background-image': logo.length ? 'url('+logo+')' : ''}">
      </div>
    </div>

    <h2>{{account.name}}</h2>

    <div class="d-flex">
      <div class="text-grey subhead-2  mr-2 ">{{companyType}}</div>
      <div class="text-grey subhead-2 mr-2">|</div>
      <div class="text-grey subhead-2">{{yardCity}}</div>
    </div>
    <div class="d-flex">
      <span *ngFor="let industry of account.industries" class="badge badge-pill mr-2 mt-3">{{industry}}</span>
    </div>

    <div class="d-flex align-items-center mt-3">
      <i class="icon-crawler-loader text-grey mr-2"></i>
      <div class="mr-3 supporting-text"><strong>{{account.machinesCommitted}}</strong> Machines</div>
      <i class="icon-money text-grey mr-2"></i>
      <div class="supporting-text"><strong>${{account.amountCommitted | thousandSuffix: 1}}</strong></div>
    </div>

  </div>

</div>
