import { ChangeDetectionStrategy } from "@angular/core";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { ShareService } from "../../../core/core";
import { InventoryService } from "../../../inventory/inventory";

@Component({
  selector: "app-machine-summary",
  templateUrl: "./machine-summary.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./machine-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineSummaryComponent {
  @Input() machine: any;
  @Input() numberOfReviews: number;

  constructor(private shareService: ShareService, private inventoryService: InventoryService) {}

  share() {
    this.shareService.share(
      `Check out this ${this.machine.make} on Rubbl!`,
      `A ${this.machine.year} ${this.machine.make} ${this.machine.model} near ${this.machine.location.name}.`,
      this.inventoryService.machineDetailsUrl(this.machine),
      { target: "machineDetails" },
    );
  }
}
