import { Injectable } from "@angular/core";

@Injectable()
export class PaymentUtils {
  static convertExpirationToDate(expiration: string): Date {
    const split = expiration.split("/");
    const month = split[0];
    const year = "20" + split[1];

    return new Date(parseInt(year), parseInt(month) - 1, 1);
  }
}
