<header>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark-grey">
    <a class="navbar-brand mr-5" routerLink="/"><img [src]="navbarImage()" /></a>
    <a class="align-self-end fs-24 ml-auto mr-4 text-white d-lg-none" href="tel:(866) 455-8531" *ngIf="showPhoneIcon"><i class="icon-call"></i></a>
    <div *ngIf="showAccountDropDown" class="d-lg-none cursor-pointer account-container" (click)="toggleNavbar()">
      <img [src]="baseImagePath + '/pubweb/marketplace/images/icons/account.svg'" />
      <app-rotating-caret [rotateCaret]="false" color="white" fontSize="10px"></app-rotating-caret>
    </div>

    <div class="collapse navbar-collapse mt-3 mt-lg-0" [ngClass]="{ show: navbarOpen }" id="navbarCollapse" rt="header-navigation-container">
      <ul class="navbar-nav mr-auto">
        <ng-container *ngFor="let item of navigationItems">
          <li
            class="nav-item d-lg-flex justify-content-center"
            *ngIf="!item.children"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="routerLinkActiveOptions"
          >
            <a class="nav-link" href="" [routerLink]="item.route">{{ item.label }}</a>
            <div class="border-bar"></div>
          </li>

          <!-- dropdown -->
          <li
            class="nav-item d-lg-flex justify-content-center"
            ngbDropdown
            *ngIf="item.children"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="routerLinkActiveOptions"
          >
            <a class="nav-link cursor-pointer" ngbDropdownToggle> {{ item.label }} <i class="icon-carrot text-grey small ml-1"></i> </a>
            <div class="border-bar"></div>
            <div ngbDropdownMenu class="dropdown-menu">
              <a class="dropdown-item" *ngFor="let children of item.children" href="" [routerLink]="children.route">{{ children.label }}</a>
            </div>
          </li>
        </ng-container>
        <div class="bb bb-spacing my-3"></div>
        <!--  iterate through the account dropdown options for mobile/tablet -->
        <li
          class="nav-item justify-content-center d-lg-none"
          *ngFor="let item of accountNavigationItems"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
        >
          <a class="nav-link" [href]="item.externalLink" *ngIf="item.externalLink">{{ item.label }}</a>
          <a class="nav-link" href="" *ngIf="item.route" [routerLink]="item.route">{{ item.label }}</a>
          <div class="border-bar"></div>
        </li>

        <li class="nav-item d-lg-none">
          <a *ngIf="!isLoggedIn" class="nav-link" (click)="logIn()">Log in</a>
          <a *ngIf="isLoggedIn" class="nav-link" (click)="logOut()">Log out</a>
        </li>
      </ul>

      <ul class="navbar-nav mr-4" *ngIf="rightSideNavigationItem">
        <li class="nav-item justify-content-center d-none d-lg-flex" [routerLinkActive]="['active']" [routerLinkActiveOptions]="routerLinkActiveOptions">
          <a class="nav-link" [href]="rightSideNavigationItem.externalLink" *ngIf="rightSideNavigationItem.externalLink">{{ rightSideNavigationItem.label }}</a>
          <a class="nav-link" href="" *ngIf="rightSideNavigationItem.route" [routerLink]="rightSideNavigationItem.route">{{ rightSideNavigationItem.label }}</a>
          <div class="border-bar"></div>
        </li>
      </ul>

      <common-internal-header-account
        *ngIf="showAccountDropDown"
        [navigationItems]="accountNavigationItems"
        [isLoggedIn]="isLoggedIn"
      ></common-internal-header-account>
    </div>
  </nav>
</header>
