import { StringTransformations } from "../../../core/utils/string-transformations/string-transformations";

export class DropDownItem {
  label: string;
  subLabel?: string;
  action?: any;
  value?: any;

  public static createEmptyListItem() {
    return [{ label: "None", value: "" } as DropDownItem];
  }
  public static createAllListItem() {
    return { label: "All", value: "ALL" } as DropDownItem;
  }
}

export class DropDownItems {
  static createFromEnum(enumToUse): DropDownItem[] {
    const dropdownItems = [] as DropDownItem[];
    Object.keys(enumToUse).forEach((e) => {
      // if the label is all uppercase it is safe to assume it is an acronym and we do not want to capitalize and separate
      dropdownItems.push({
        label: enumToUse[e] === enumToUse[e].toUpperCase() ? enumToUse[e] : StringTransformations.capitalizeAndSeparate(enumToUse[e]),
        value: enumToUse[e],
      });
    });

    return dropdownItems;
  }
}
