import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { AccountMapConfig } from "../account-map/account-map-config.model";
import { AccountMapFilterFacets } from "../account-map/account-map-filter-facets.model";
import { AccountMapService } from "../account-map/account-map.service";

@Component({
  selector: "common-account-map-filter",
  templateUrl: "./account-map-filter.component.html",
  styleUrls: ["./account-map-filter.component.scss"],
})
export class AccountMapFilterComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean = false;

  accountMapFilterFacets: AccountMapFilterFacets;
  accountMapFilterFacetsMirror: AccountMapFilterFacets;
  accountMapConfigSubscription: Subscription;
  accountMapFilterFacetsTouched: boolean;
  accountMapConfig = new AccountMapConfig();
  filtersToConsiderCount: number = 0;
  filtersAppliedCount: number = 0;

  constructor(private accountMapService: AccountMapService) {}

  ngOnInit() {
    this.accountMapConfigSubscription = this.accountMapService.accountMapConfig$.subscribe(config => {
      this.filtersToConsiderCount = config.filtersToConsiderCount;
      this.filtersAppliedCount = config.filtersAppliedCount;

      this.accountMapFilterFacets = { ...config.filtersConfig };

      this.accountMapFilterFacetsMirror = new AccountMapFilterFacets();

      config.filtersConfig.companyTypes.forEach(companyType => {
        this.accountMapFilterFacetsMirror.companyTypes.push({ ...companyType });
      });
      config.filtersConfig.accountTypes.forEach(accountType => {
        this.accountMapFilterFacetsMirror.accountTypes.push({ ...accountType });
      });
      this.validateIfFilterIsTouched();
    });
  }

  ngOnDestroy() {
    this.accountMapConfigSubscription.unsubscribe();
  }

  filtersChange() {
    if (!this.isMobile) {
      this.accountMapService.setFilters(this.accountMapFilterFacets);
      return;
    }
    const calculatedFiltersCount = this.accountMapService.calculateFiltersAppliedCount(
      this.accountMapFilterFacets.companyTypes,
      this.accountMapFilterFacets.accountTypes,
    );

    this.filtersToConsiderCount = calculatedFiltersCount[0];
    this.filtersAppliedCount = calculatedFiltersCount[1];

    this.validateIfFilterIsTouched();
  }

  applyFilterChanges() {
    this.accountMapService.setFilters(this.accountMapFilterFacets);
  }

  validateIfFilterIsTouched() {
    this.accountMapFilterFacetsTouched =
      JSON.stringify(this.accountMapFilterFacets.companyTypes) !== JSON.stringify(this.accountMapFilterFacetsMirror.companyTypes) ||
      JSON.stringify(this.accountMapFilterFacets.accountTypes) !== JSON.stringify(this.accountMapFilterFacetsMirror.accountTypes);
  }

  clearFilters() {
    this.accountMapFilterFacets.companyTypes.forEach(companyType => {
      companyType.checked = true;
    });
    this.accountMapFilterFacets.accountTypes.forEach(accountType => {
      accountType.checked = true;
    });
    this.filtersChange();
  }
}
