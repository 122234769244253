import { CountValueFacetItem, Facet } from "./facet.model";

export class BoolFacet extends Facet {
  facetType = "bool";
  constructor(name: string, facets: CountValueFacetItem[], label?: string) {
    super(name, facets, label);
  }

  get selected(): boolean {
    return this.facets.some((d) => d.checked);
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    if (!param) {
      this.uncheck();
      return null;
    }

    if (param !== "true") {
      return null;
    }

    this.update(this.facets);
    this.facets.forEach((d) => (d.checked = true));

    return `${this.name} eq true`;
  }

  update = (facets: CountValueFacetItem[], showFalseFacet: boolean = false) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }

    const trueFacet = facets.find((d) => d.value === true);
    // if we do not have a true facet we need to return because we do not want to show
    if (!trueFacet) {
      this.facets = [];
      return;
    }

    trueFacet.label = this.label;
    this.facets = showFalseFacet ? facets : [trueFacet];
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return this.facets
      .filter((d) => d.checked)
      .map((d) => d.value)
      .join(":");
  };
}
