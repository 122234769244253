import { CountValueFacetItem, GroupFacetItem, Facet } from "./facet.model";

export class GroupFacet extends Facet {
  // facets here could be of type CountValueFacetItem or GroupFacetItem depending on the length of the facets in order to do the grouping
  facets: any[];
  unit: string;
  minLengthForGrouping: number = 3;

  constructor(name: string, unit: string, facets: CountValueFacetItem[]) {
    super(name, facets);
    this.unit = unit;
    this.group(facets);
  }

  get selected(): boolean {
    return this.facets.some(d => d.checked);
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    const params = param ? param.split(",") : [];
    const filters = [];
    for (const facet of this.facets) {
      if ((this.facets.length > this.minLengthForGrouping && params.includes(`${facet.from}:${facet.to}`)) || params.includes(`${facet.value}`)) {
        facet.checked = true;
      } else {
        facet.checked = false;
      }

      if (!facet.checked) {
        continue;
      }

      // this will only get called when there is less then 4 options for weight. rare but still happens
      // the weight of a machine might be 40000.5
      // but the facets will return +/- 500 since we ae using an interval of 500 in the search-results.service.ts
      // i am ashamed of this solution but since weight is the only count value with range filter facet that we are using currently it works.
      // we need to re implement this when we add weight classes to the types, after we actually get rentals :D - jl
      if (!facet.to && !facet.from && facet.value) {
        facet.from = facet.value - 500;
        facet.to = facet.value + 500;
      }

      if (facet.to != null) {
        filters.push(`(${this.name} gt ${facet.from} or ${this.name} eq ${facet.from})
                and (${this.name} lt ${facet.to} or ${this.name} eq ${facet.to})`);
        continue;
      }

      if (facet.from != null) {
        filters.push(`${this.name} gt ${facet.from} or ${this.name} eq ${facet.from}`);
      }
    }

    return filters.length ? `(${filters.join(" or ")})` : null;
  }

  group(facets: CountValueFacetItem[]) {
    if (facets.length === 0) {
      this.facets = [];
      return;
    }

    // only do the group if there are greater then 4 facet lengths
    // need to grab the bottom and lower
    if (facets.length > this.minLengthForGrouping) {
      const lastFacet = facets[facets.length - 1];
      const firstInterval = Math.floor(+lastFacet.value / 3);
      const secondInterval = firstInterval + firstInterval;
      const thirdInterval = secondInterval + firstInterval;

      const first = new GroupFacetItem(firstInterval, secondInterval, this.unit);
      const second = new GroupFacetItem(secondInterval, thirdInterval, this.unit);
      const third = new GroupFacetItem(thirdInterval, null, null);
      this.facets = [new GroupFacetItem(0, firstInterval, this.unit), first, second, third];
    } else {
      this.facets = facets;
      this.filter(new URLSearchParams(window.location.search)); // hack but it works
    }
  }

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }

    this.group(facets);
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    if (this.facets.length > this.minLengthForGrouping) {
      return this.facets
        .filter(d => d.checked)
        .map(d => d.from + ":" + d.to)
        .join(",");
    } else {
      return this.facets
        .filter(d => d.checked)
        .map(d => d.value)
        .join(",");
    }
  };
}
