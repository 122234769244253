import { trigger, transition, style, animate } from "@angular/animations";
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { AzureSuggestsResult, AzureSuggestsRequest, AzureSearchService } from "../../../azure-search/azure-search";
import { OverlayService } from "../../../layout/services/overlay.service";

@Component({
  selector: "app-search-suggests",
  templateUrl: "./search-suggests.component.html",
  styleUrls: ["./search-suggests.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [style({ opacity: 0 }), animate("250ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("250ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class SearchSuggestsComponent implements OnInit {
  @Input() searchInput: HTMLElement;
  @Input() customClass: string = "";

  @Input() useSearchSuggestionsOverlay: boolean = true;
  @Output() suggestionSelected = new EventEmitter<AzureSuggestsResult>();

  searchTerm = "";
  showSearchSuggestions = false;
  searchSuggestions: AzureSuggestsResult[] = [];
  suggestsRequest = new AzureSuggestsRequest();

  constructor(
    private overlayService: OverlayService,
    private eventManager: EventManager,
    private azureSearchService: AzureSearchService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.eventManager.addEventListener(this.searchInput, "keyup", $event => this.searchInputChange($event));
    this.eventManager.addEventListener(this.searchInput, "blur", () => this.searchInputBlur());
    this.eventManager.addEventListener(this.searchInput, "focus", () => this.searchInputFocus());
  }

  searchInputChange($event) {
    // check if current the key pressed is enter
    if ($event.keyCode === 10 || $event.keyCode === 13) {
      this.showSearchSuggestions = false;
      this.toggleOverlay(false);
      return;
    }
    this.searchTerm = $event.target.value;
    this.showSearchSuggestions = $event.target.value === "" ? false : true;
    if (this.showSearchSuggestions) {
      this.getSearchSuggestions();
      // going to have to remove the z-index from navbar so it goes behind the search suggests overlay
    }
    this.toggleOverlay(this.showSearchSuggestions);
    this.cd.detectChanges();
  }

  searchInputBlur() {
    this.showSearchSuggestions = false;
    this.toggleOverlay(false);
    this.cd.detectChanges();
  }

  searchInputFocus() {
    if (this.searchTerm !== "") {
      this.showSearchSuggestions = true;
      this.toggleOverlay(true);

      // if we cleared out search suggestions due to clicking on a selected search term then we
      // need to repopulate the search suggestions
      if (this.searchSuggestions == null) {
        this.getSearchSuggestions();
      }
    }
  }

  getSearchSuggestions() {
    this.suggestsRequest.search = this.searchTerm;
    // such a stupid hack so plurals work...have to work with items that start with "s" like skid steer
    if (this.suggestsRequest.search.substring(this.suggestsRequest.search.length - 1) == "s" && this.suggestsRequest.search.length > 1) {
      this.suggestsRequest.search = this.suggestsRequest.search.slice(0, this.suggestsRequest.search.length - 1);
    }
    this.suggestsRequest.fuzzy = false;
    this.azureSearchService.suggestions(this.suggestsRequest, "suggestions").subscribe((result: AzureSuggestsResult[]) => {
      this.searchSuggestions = result;
      this.cd.detectChanges();
    });
  }

  selectSuggestedTerm(suggestedTerm: AzureSuggestsResult) {
    this.searchTerm = suggestedTerm.value;
    this.suggestionSelected.emit(suggestedTerm);
    this.searchSuggestions = null; // clear out current search suggestions
    this.showSearchSuggestions = false;
    this.toggleOverlay(false);
  }

  toggleOverlay(show: boolean) {
    if (this.useSearchSuggestionsOverlay) {
      this.overlayService.toggleOverlayWithBool("search-suggests", show);
    }
  }
}
