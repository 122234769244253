import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EntitySyncService {
  private updateEntityItem = new Subject();
  updateEntity$ = this.updateEntityItem.asObservable();

  constructor() {}

  sendEntityUpdate() {
    this.updateEntityItem.next(null);
  }
}
