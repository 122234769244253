<ng-container *ngIf="!useCoordinates">
  <div class="form mr-0 mt-3" [class.show-errors]="!valid">
    <label>{{ addressText }}</label>
    <common-location-typeahead
      (locationSelected)="locationIsSelected($event)"
      [location]="location"
      showIcon="true"
      [inputClass]="!valid ? 'show-errors invalid' : ''"
      onlyAddresses="true"
      placeHolderText="Enter address"
    >
    </common-location-typeahead>
  </div>
  <div *ngIf="!valid" class="form-text text-red">{{ addressText }} required</div>
  <div class="d-inline-flex align-items-center cursor-pointer my-3" (click)="switchToCoordinates()">
    <i class="icon icon-crosshair text-green"></i>
    <span class="ml-2 supporting-text text-green">Or use coordinates</span>
  </div>
</ng-container>

<ng-container *ngIf="useCoordinates">
  <div class="row form mt-3">
    <div class="col-md-6">
      <div class="mr-0" [class.show-errors]="!valid && !location">
        <label>Latitude</label>
        <common-icon-input [value]="latitude" (valueChange)="latitudeChange($event)" placeholder="Eg. 33.2185143" iconClass="icon-latitude"></common-icon-input>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mr-0" [class.show-errors]="!valid && !location">
        <label>Longitude</label>
        <common-icon-input
          [value]="longitude"
          (valueChange)="longitudeChange($event)"
          placeholder="Eg. -117.2793682"
          iconClass="icon-longitude"
        ></common-icon-input>
      </div>
    </div>
  </div>
  <div *ngIf="!valid" class="form-text text-red">{{ addressText }} coordinates required</div>

  <div class="row form mt-3" *ngIf="displayCityInCoordinates">
    <div class="col-md-6">
      <div class="form-group mr-0" [class.show-errors]="!valid && !location?.address?.city">
        <label>City</label>
        <input type="text" class="form-control" [(ngModel)]="location?.address.city" placeholder="Eg. Hollywood" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group mr-0" [class.show-errors]="!valid && !location?.address.stateProvince">
        <label>State</label>
        <input type="text" class="form-control" [(ngModel)]="location?.address.stateProvince" placeholder="Eg. CA" maxlength="2" />
      </div>
    </div>
  </div>

  <small
    *ngIf="!valid && useCoordinates && displayCityInCoordinates && (!location?.address?.city || !location?.address?.stateProvince)"
    class="form-text text-red mb-3"
  >
    {{ addressText }} City and State required
  </small>

  <div class="d-inline-flex align-items-center cursor-pointer my-3" *ngIf="useCoordinates" (click)="switchToAddress()">
    <i class="icon icon-transportation text-green"></i>
    <span class="ml-2 supporting-text text-green">Or use address</span>
  </div>
</ng-container>

<az-map
  [locations]="[location]"
  [mapConfig]="mapConfig"
  id="locationMap"
  [markerTemplate]="markerTemplate"
  clickToAddMarker="true"
  moveSingleMarker="true"
  reverseGeoCode="true"
  (selectedLocation)="locationIsSelected($event)"
  [allowPartialAddress]="allowPartialAddress"
  [isInModal]="isInModal"
  #azureMapComponent
>
</az-map>

<ng-template #markerTemplate>
  <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f)">
      <ellipse cx="26" cy="49" rx="14" ry="3" fill="black" fill-opacity="0.2" />
    </g>
    <g filter="url(#filter1_d)">
      <mask id="path-2-outside-1" maskUnits="userSpaceOnUse" x="9" y="9" width="34" height="40" fill="black">
        <rect fill="white" x="9" y="9" width="34" height="40" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.4609 43.8799C37.4763 37.4914 42 33.3718 42 26C42 17.1634 34.8366 10 26 10C17.1634 10 10 17.1634 10 26C10 32.98 14.3676 37.0443 20.6386 42.8796C21.8931 44.047 23.2238 45.2853 24.6109 46.6319C25.3883 47.3867 26.6288 47.4034 27.4227 46.6659C28.4792 45.6845 29.4937 44.7606 30.4609 43.8799ZM26 32C29.3137 32 32 29.3137 32 26C32 22.6863 29.3137 20 26 20C22.6863 20 20 22.6863 20 26C20 29.3137 22.6863 32 26 32Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.4609 43.8799C37.4763 37.4914 42 33.3718 42 26C42 17.1634 34.8366 10 26 10C17.1634 10 10 17.1634 10 26C10 32.98 14.3676 37.0443 20.6386 42.8796C21.8931 44.047 23.2238 45.2853 24.6109 46.6319C25.3883 47.3867 26.6288 47.4034 27.4227 46.6659C28.4792 45.6845 29.4937 44.7606 30.4609 43.8799ZM26 32C29.3137 32 32 29.3137 32 26C32 22.6863 29.3137 20 26 20C22.6863 20 20 22.6863 20 26C20 29.3137 22.6863 32 26 32Z"
        fill="#F53331"
      />
      <path
        d="M30.4609 43.8799L29.7876 43.1405L30.4609 43.8799ZM20.6386 42.8796L21.3198 42.1476L20.6386 42.8796ZM24.6109 46.6319L25.3074 45.9144H25.3074L24.6109 46.6319ZM27.4227 46.6659L26.7421 45.9333L27.4227 46.6659ZM41 26C41 32.8556 36.8804 36.6815 29.7876 43.1405L31.1342 44.6193C38.0722 38.3012 43 33.888 43 26H41ZM26 11C34.2843 11 41 17.7157 41 26H43C43 16.6112 35.3888 9 26 9V11ZM11 26C11 17.7157 17.7157 11 26 11V9C16.6112 9 9 16.6112 9 26H11ZM21.3198 42.1476C14.9749 36.2434 11 32.4718 11 26H9C9 33.4883 13.7603 37.8451 19.9574 43.6117L21.3198 42.1476ZM25.3074 45.9144C23.9116 44.5593 22.5735 43.3142 21.3198 42.1476L19.9574 43.6117C21.2127 44.7798 22.5359 46.0112 23.9143 47.3494L25.3074 45.9144ZM26.7421 45.9333C26.3402 46.3066 25.705 46.3004 25.3074 45.9144L23.9143 47.3494C25.0716 48.4729 26.9174 48.5002 28.1033 47.3986L26.7421 45.9333ZM29.7876 43.1405C28.8209 44.0209 27.8028 44.9479 26.7421 45.9333L28.1033 47.3986C29.1556 46.421 30.1666 45.5004 31.1342 44.6193L29.7876 43.1405ZM31 26C31 28.7614 28.7614 31 26 31V33C29.866 33 33 29.866 33 26H31ZM26 21C28.7614 21 31 23.2386 31 26H33C33 22.134 29.866 19 26 19V21ZM21 26C21 23.2386 23.2386 21 26 21V19C22.134 19 19 22.134 19 26H21ZM26 31C23.2386 31 21 28.7614 21 26H19C19 29.866 22.134 33 26 33V31Z"
        fill="white"
        mask="url(#path-2-outside-1)"
      />
    </g>
    <defs>
      <filter id="filter0_f" x="8" y="42" width="36" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
      </filter>
      <filter id="filter1_d" x="0" y="0" width="52" height="57.2087" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
</ng-template>
