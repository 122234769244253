import { OnInit, Directive } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { of, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BaseApiService } from "../../services//base-api.service";
import { ModalService } from "../../services/modal.service";
import { AzureSearchService } from "../../../azure-search/services/azure-search.service";
import { DisplayDataSection } from "../display-data-section/display-data-section.model";

@Directive()
export abstract class BaseEntityDisplay implements OnInit {
  entity: any;
  displaySections: DisplayDataSection[];
  newEntity: boolean;
  loaded: boolean;

  constructor(
    protected apiService: BaseApiService<any>,
    protected toastrService: ToastrService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected firstEditModal: any = null,
    protected routeParamForId: string = "id",
    protected usePartitionkey: boolean = false,
    protected azureSearchService: AzureSearchService = null,
    protected indexToQuery: string = null,
  ) {}

  ngOnInit() {
    const entityId = this.activatedRoute.snapshot.paramMap.get(this.routeParamForId) || this.activatedRoute.parent.snapshot.paramMap.get(this.routeParamForId);
    if (entityId === "create") {
      this.newEntity = true;
      this.setupView({}).subscribe(entity => (this.entity = entity));
      this.loaded = true;
      // if we have a first edit modal and its a new entity
      // we want the slide out modal to appear
      if (this.firstEditModal) {
        setTimeout(() => this.edit(this.firstEditModal), 50);
      }
      return;
    }

    this.newEntity = false;

    // some items include partitionkey
    const partitionKey = this.activatedRoute.snapshot.queryParamMap.get("partitionKey");
    let getMethod;

    // we provided an index to query, query using the azure search service
    if (this.indexToQuery) {
      getMethod = this.azureSearchService.getOne(entityId, this.indexToQuery);
    } else {
      getMethod = this.usePartitionkey ? this.apiService.getWithPartitionKey(entityId, partitionKey) : this.apiService.get(entityId);
    }

    getMethod
      .pipe(
        switchMap(entity => this.getRelatedEntities(entity)),
        switchMap(entity => this.setupView(entity)),
      )
      .subscribe(entity => {
        this.entity = entity;
        this.loaded = true;
      });
  }

  // override to get related entities if needed
  getRelatedEntities(entity: any): Observable<any> {
    return of(entity);
  }

  setupView(entity: any): Observable<any> {
    return of(entity);
  }

  edit(editComponent?: any) {
    if (!editComponent) {
      editComponent = this.firstEditModal;
    }

    const modalRef = this.modalService.slideFromRight(editComponent, true);
    modalRef.componentInstance.entity = Object.assign({}, this.entity);
    modalRef.componentInstance.newEntity = this.newEntity;
    modalRef.componentInstance.activatedRoute = this.activatedRoute;

    // can probably rework this to include any modal ref in the display data
    this.addToEditModal(modalRef);

    modalRef.result.then(
      (entity: any) => {
        // after server response from save
        this.afterEditModalSuccess(entity);

        this.entity = entity;
        // if we are creating a new entity we need to grab the data from the api
        // BUT if are using an index to get the entity, lets not get it because it might not be created yet in the index
        this.newEntity && this.indexToQuery == null ? this.ngOnInit() : this.setupView(entity).subscribe();
        // now set the new entity to false
        this.newEntity = false;
      },
      () => {},
    );
  }

  addToEditModal(modalRef: NgbModalRef) {}

  afterEditModalSuccess(entity: any) {}
}
