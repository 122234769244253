import { Component, OnInit, Input } from "@angular/core";
import { INavigationItem } from "../layout";
import { EnvironmentConfig, AuthenticationService } from "../../core/core";
import { Router, NavigationStart } from "@angular/router";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-internal-header",
  templateUrl: "./internal-header.component.html",
  styleUrls: ["./internal-header.component.scss"],
})
export class InternalHeaderComponent implements OnInit {
  @Input() routerLinkActiveOptions = { exact: false };
  @Input() navigationItems: INavigationItem[];
  @Input() accountNavigationItems: INavigationItem[];
  @Input() rightSideNavigationItem: INavigationItem;
  @Input() showAccountDropDown: boolean = true;
  @Input() showPhoneIcon: boolean = false;

  navbarOpen: boolean = false;
  baseImagePath: string = environment.baseImagePath;
  isLoggedIn: boolean;

  constructor(private authenticationService: AuthenticationService, private router: Router) {
    this.authenticationService.getUser().then((result) => {
      this.isLoggedIn = result != null;
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this.navbarOpen = false;
      }
    });
  }

  navbarImage(): string {
    switch (environment.clientId) {
      case "rubbl.admin":
        return environment.baseImagePath + "/pubweb/admin/images/logos/rubbl-admin.svg";

      case "rubbl.transportation":
        return environment.baseImagePath + "/pubweb/transportation/images/logos/rubbl-transport.svg";

      case "rubbl.inspections":
        return environment.baseImagePath + "/pubweb/marketplace/images/logos/rubbl-inspect-light.svg";

      default:
        return environment.baseImagePath + "/pubweb/marketplace/images/logos/rubbl-navbar.svg";
    }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  logIn() {
    this.authenticationService.login();
  }

  logOut() {
    this.authenticationService.logout();
  }
}
