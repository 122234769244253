import { IStorableEntity } from "./storable-entity.model";

export class BaseStorableEntity implements IStorableEntity {
  id?: string;
  isActive?: boolean;
  itemType?: Array<string>;
  partitionKey?: string;
  partition?: string;
  internalNotes?: string;
}
