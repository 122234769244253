import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
  ElementRef,
  Type,
  ComponentFactoryResolver,
  Injector,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import * as atlas from "azure-maps-control";
import { EnvironmentConfig } from "./../../../core/models/environment-config.model";
import { Location } from "../../../core/models/location.model";
import { AzureMapConfig } from "../../models/azure-map-config";
import { AzureMapService } from "../../services/azure-map.service";

declare var environment: EnvironmentConfig;
@Component({
  selector: "az-map",
  templateUrl: "./azure-map.component.html",
  styleUrls: ["./azure-map.component.scss"],
})
export class AzureMapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() id: string = "";
  @Input() locations: Location[];
  @Input() mapConfig: AzureMapConfig;
  @Input() markerTemplate: TemplateRef<any>;
  @Input() clickToAddMarker: boolean; // if this is true when you click on the map, the location will be published in the output parameter and a marker is added to the map
  @Input() moveSingleMarker: boolean; // if this and clickToAddMarker are true, it will only add a single marker
  @Input() reverseGeoCode: boolean; // if clickToAddMarker is true, then you can set this to true and it will attempt to get an address from the coordinates
  @Input() allowPartialAddress: boolean = false; // if true, only a city and state are required for an address to be returned by the reverse geocode
  @Input() isInModal: boolean = false;
  @Input() popupComponent: Type<any>;
  @ViewChild("defaultMarker") defaultMarkerTemplate: TemplateRef<any>;
  @ViewChild("marker") marker: ElementRef;

  @Output() selectedLocation = new EventEmitter<Location>();

  /* These are the map components we can interact with */
  public map: any;
  public popup: atlas.Popup;
  public symbolLayer: atlas.layer.SymbolLayer;

  private dataSource: atlas.source.DataSource;
  public locationMarker: atlas.HtmlMarker;

  mapId: string = "az-map-container";

  constructor(private componentFactory: ComponentFactoryResolver, private mapService: AzureMapService, private injector: Injector) {}

  ngOnInit() {
    this.mapId = `${this.mapId}-${this.id}`;
  }

  ngAfterViewInit() {
    let centerPosition: number[] = this.mapConfig.center.length === 2 ? this.mapConfig.center : [-95, 39];

    if (this.locations && this.locations[0]?.latitude && this.locations[0]?.longitude) {
      centerPosition = [this.locations[0].longitude, this.locations[0].latitude];
    }

    this.map = new atlas.Map(this.mapId, {
      center: centerPosition,
      zoom: this.mapConfig.zoom,
      maxZoom: this.mapConfig.maxZoom,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: environment.azureMapKey,
      },
    });

    this.map.events.add("ready", () => {
      this.dataSource = new atlas.source.DataSource();
      this.map.sources.add(this.dataSource);
      this.addMarkers();

      this.symbolLayer = new atlas.layer.SymbolLayer(this.dataSource, null);
      this.map.layers.add(this.symbolLayer);
      if (this.mapConfig.showControls) {
        this.map.controls.add(
          [
            new atlas.control.ZoomControl(),
            new atlas.control.StyleControl({
              mapStyles: ["road", "satellite_road_labels"],
            }),
          ],
          {
            position: "top-right",
          },
        );

        this.addStyleControlEventListeners();
      }

      if (this.mapConfig.showPopups) {
        this.popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false,
        });

        this.map.events.add("mouseover", this.symbolLayer, e => {
          // tslint:disable-next-line:variable-name
          const _this = this;
          if (e.shapes && e.shapes.length > 0) {
            let coordinate;

            const factory = _this.componentFactory.resolveComponentFactory(_this.popupComponent);
            const component = factory.create(_this.injector);
            const data = e.shapes[0].getProperties();
            console.log(data);
            (<any>component.instance).data = data;
            component.changeDetectorRef.detectChanges();

            const popupHtml = component.location.nativeElement;
            coordinate = e.shapes[0].getCoordinates();
            _this.popup.setOptions({
              // update the content of the popup.
              content: popupHtml,

              // update the popup's position with the symbol's coordinate.
              position: coordinate,
            });

            // open the popup.
            _this.popup.open(_this.map);
          }
        });

        this.map.events.add("mouseleave", this.symbolLayer, e => {
          this.popup.close();
        });
      }

      if (this.clickToAddMarker) {
        this.map.events.add("click", e => this.mapClicked(e));
      }
    });
  }

  addStyleControlEventListeners() {
    // reason for timeout is because we need the buttons to show up in the markup.
    // there is no callback yet from azure maps when adding controls
    // this will allow the style pop up to close if a style is selected
    // worse  comes to worst then the style pop up will not auto close :D
    setTimeout(() => {
      const buttons = document.querySelectorAll(".azure-maps-control-button:not([title='Select Style'])");
      const stylePicker = document.querySelector("[aria-label='Map Style Control']") as HTMLElement;
      const styleOptions = document.querySelector(".style-options.icons");
      buttons.forEach(e => {
        const element = e;
        element.addEventListener("click", target => {
          stylePicker.classList.remove("in-use");
          if (!this.isInModal) {
            // We removed it becase in modal some default behaviors as mouseover are not working in controls
            styleOptions.classList.add("hidden-accessible-element");
          }
        });
      });
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.locations.firstChange) {
      this.onLocationChange();
    }
  }

  onLocationChange() {
    this.map.markers.clear();
    this.setCamera();
    this.addMarkers();
  }

  private setCamera() {
    if (this.locations[0] == null) {
      return;
    }

    this.map.setCamera({ center: [this.locations[0].longitude, this.locations[0].latitude], type: "fly", duration: 2000, zoom: 13 });
  }

  private addMarkers(): void {
    const html = this.marker.nativeElement.innerHTML;

    this.locations
      .filter(l => l?.latitude && l?.longitude)
      .forEach(location => {
        if (!location) {
          return;
        }

        this.locationMarker = new atlas.HtmlMarker({
          position: [location.longitude, location.latitude],
          pixelOffset: [5, -18],
          htmlContent: html,
        });
        this.map.markers.add(this.locationMarker);
      });
  }

  public mapClicked(e) {
    var location = new Location();
    location.latitude = e.position[1];
    location.longitude = e.position[0];

    if (this.reverseGeoCode) {
      this.mapService.getReversedLocation(location, this.allowPartialAddress).subscribe(locationReversed => this.selectedLocation.emit(locationReversed));
    } else {
      this.selectedLocation.emit(location);
    }

    if (this.moveSingleMarker && this.locationMarker) {
      this.locationMarker.setOptions({ position: e.position });
    } else {
      const html = this.marker.nativeElement.innerHTML;
      var marker = new atlas.HtmlMarker({
        position: [location.longitude, location.latitude],
        pixelOffset: [5, -18],
        htmlContent: html,
      });

      if (!this.locationMarker && this.moveSingleMarker) {
        // we are moving a single marker, so we want to maintain a reference to it
        this.locationMarker = marker;
      }

      this.map.markers.add(marker);
    }
  }
}
