import { Component, Input } from "@angular/core";

@Component({
  selector: "common-icon-button",
  templateUrl: "./icon-button.component.html",
})
export class IconButtonComponent {
  @Input() iconClass: string = "";
  @Input() buttonText: string = "";
  @Input() disabled: boolean = false;

  constructor() {}
}
