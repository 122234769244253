<div class="h-100 mt-4 mx-xl-4" rt="account-map-filter-component">  
  <div class="clear" *ngIf="filtersToConsiderCount != filtersAppliedCount">
    <span class="text-grey" (click)="clearFilters()">Clear</span>
  </div> 
  <div class="sub-header">
    Filters
  </div>  
  <div class="bb-light mt-2 mb-4 main-bb"></div>
  <div rt="account-map-filter-container">
    <div class="checkbox-group" rt="filter-container-company-type">
      <label for="facetName">Company Type</label>
      <ng-container *ngIf="accountMapFilterFacets.companyTypes.length > 1">
        <app-input-checkbox (change)="filtersChange()" *ngFor="let facet of accountMapFilterFacets.companyTypes"
          [label]="facet.label" [(checked)]="facet.checked" [checkmarkColor]="facet.color">
        </app-input-checkbox>
      </ng-container>
    </div>
    <div class="bb-light my-2"></div>
    <div rt="filter-container-account-type">
      <label for="facetName">Accounts</label>
      <ng-container *ngIf="accountMapFilterFacets.accountTypes.length > 1">
        <app-input-checkbox (change)="filtersChange()" *ngFor="let facet of accountMapFilterFacets.accountTypes"
          [label]="facet.label" [(checked)]="facet.checked">
        </app-input-checkbox>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer w-100 d-xl-none">
   <div class="bb-light"></div>
   <div class="w-100 mx-2">
    <button type="button" class="btn btn-secondary btn-modal-footer w-100"
    (click)="applyFilterChanges()" rt="apply-filters-button" [disabled]="!accountMapFilterFacetsTouched">Apply</button>
   </div>
  </div>
</div>
