import { AssociatedItem } from './associated-item.model';
import { Attachment } from './attachment.model';

export class AttachmentViewModel extends AssociatedItem {
  attachment: Attachment;

  constructor() {
    super();
    this.attachment = new Attachment();
  }
}
