import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentConfig, BaseApiService, AuthenticationService } from "../../core/core";
import { InventoryGroup } from "../inventory";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class InventoryGroupService extends BaseApiService<InventoryGroup> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/group", true, authenticationService);
  }

  getImages(catalogId: string) {
    const headers = this.getAuth();
    return this.httpClient.get(`${this.baseUrl}${this.endpoint}/${catalogId}/image`, { headers });
  }

  uploadImage(entityId: string, partitionKey: string, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    let params = new HttpParams();
    params = params.append("partitionKey", partitionKey);

    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${entityId}/image`, uploadData, { headers, params });
  }

  deleteImage(catalogId: string, imageId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${catalogId}/image/${imageId}`, { headers });
  }
}
