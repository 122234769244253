import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AzureMapComponent } from "./components/azure-map/azure-map.component";
import { LocationTypeaheadMapComponent } from "./components/location-typeahead-map/location-typeahead-map.component";
import { MachineMapLocationComponent } from "./components/machine-map-location/machine-map-location.component";
import { AccountMapComponent } from "./components/account-map/account-map.component";
import { AccountMapFilterComponent } from "./components/account-map-filter/account-map-filter.component";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { CoreModule } from "../core/core";
import { RubblFormsModule } from "../forms/forms";
import { AzureMapRoutesComponent } from "./components/azure-map-routes/azure-map-routes.component";

@NgModule({
  declarations: [
    AzureMapComponent,
    LocationTypeaheadMapComponent,
    MachineMapLocationComponent,
    AccountMapComponent,
    AccountMapFilterComponent,
    AzureMapRoutesComponent,
  ],
  imports: [CommonModule, RubblSharedModule, CoreModule, RubblFormsModule],
  exports: [
    AzureMapComponent,
    LocationTypeaheadMapComponent,
    MachineMapLocationComponent,
    AccountMapComponent,
    AccountMapFilterComponent,
    AzureMapRoutesComponent,
  ],
})
export class AzureMapsModule {}
