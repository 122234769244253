import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LogService } from "../../services/log.service";

declare var MediaRecorder: any;

@Component({
  selector: "common-video-capture",
  templateUrl: "./video-capture.component.html",
  styleUrls: ["./video-capture.component.scss"],
})
export class VideoCaptureComponent implements OnInit, OnDestroy {
  recordingTimeMS = 1000 * 30;
  recording: boolean = true;
  finished: boolean = false;
  recorder: any;
  recordedBlob: Blob;
  recordedUrl: string;
  secondCounter: number = 0;
  quarterSecondCounter: number = 0;
  secondCounterInterval: any;
  recordingTimeout: any;
  quarterSecondInterval: any;

  constructor(private ngbModal: NgbActiveModal, private logService: LogService) {}

  ngOnInit(): void {
    this.start();
  }

  ngOnDestroy() {
    // on destroy, always hit stop just incase we leave anything open such as the video recording
    this.stopButton();
  }

  start() {
    this.recording = true;
    this.finished = false;
    this.recordedUrl = null;
    let preview = document.getElementById("preview");
    preview["muted"] = "muted"; // for some reason we have to do this because the muted attribute does not work

    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: "environment", width: { min: 800, ideal: 800, max: 800 }, height: { min: 600, ideal: 600, max: 600 } },
        // video: { facingMode: "environment", width: { min: 640, ideal: 640, max: 640 }, height: { min: 400, ideal: 400, max: 400 } }, // android was 2mb. ios was 15mb
        audio: true,
      })
      .then((stream) => {
        preview["srcObject"] = stream;
        // since preview does not have a capture stream method on IOS we have to start recording rigth away and just use the stream for the user media
        // instead of using the stream from the video tag preview. i think the reason to use the video tag preview is we can control the size of the video.
        // preview["captureStream"] = preview["captureStream"] || preview["mozCaptureStream"];
        this.startRecording(stream, this.recordingTimeMS);
        return new Promise((resolve) => (preview.onplaying = resolve));
      })
      .then(() => {})
      // .then(() => this.startRecording(preview["captureStream"](), this.recordingTimeMS))
      .catch((e) => {
        this.logService.error(this.constructor.name, e);
        alert("Please allow camera use");
        this.ngbModal.close();
      });
  }

  get progressbarValue() {
    return (this.quarterSecondCounter / this.recordingTimeMS) * 100;
  }

  stopButton() {
    this.recording = false;
    this.recorder?.state == "recording" && this.recorder?.stop();
    this.stopPreview();
    this.clearIntervals();
  }

  stopPreview() {
    let preview = document.getElementById("preview");
    if (preview) {
      preview["srcObject"]?.getTracks().forEach((track) => track.stop());
    }
  }

  startRecording(stream, lengthInMS) {
    this.recorder = new MediaRecorder(stream);
    let data = [];

    // this gets called whenever the recorder gets stopped
    this.recorder.ondataavailable = (event) => {
      data.push(event.data);
      this.finishRecording([event.data]);
    };
    this.recorder.start();

    this.recorder.onstop = (e) => {};
    this.recorder.onerror = (event) => {};

    this.secondCounterInterval = setInterval(() => {
      this.secondCounter++;
    }, 1000);

    this.quarterSecondInterval = setInterval(() => {
      this.quarterSecondCounter += 250;
    }, 250);

    this.recordingTimeout = setTimeout(() => {
      // our timeout limit has been reached we need to stop the recorder and stop the preview
      this.recording = false;
      this.recorder.state == "recording" && this.recorder.stop();
      this.stopPreview();
    }, lengthInMS + 1000); // the reason for +1000 is because it actually stops a second before
  }

  finishRecording(recordedChunks) {
    // we now have the data, lets create a blob and create a recording of the video
    this.recordedBlob = new Blob(recordedChunks, { type: "video/webm" });
    this.ngbModal.close(this.recordedBlob);
    this.clearIntervals();
  }

  clearIntervals() {
    if (this.secondCounterInterval) {
      clearInterval(this.secondCounterInterval);
    }

    if (this.recordingTimeout) {
      clearTimeout(this.recordingTimeout);
    }

    if (this.quarterSecondInterval) {
      clearInterval(this.quarterSecondInterval);
    }
  }

  closeModal() {
    this.ngbModal.close(this.recordedBlob);
  }

  cancel() {
    this.ngbModal.dismiss();
  }

  toHHMMSS(seconds: number) {
    var date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  }
}
