import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TypeDefinitionDropdownComponent } from "./components/type-definition-dropdown/type-definition-dropdown.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SpecificationsTableComponent } from "./components/specifications-table/specifications-table.component";
import { SpecificationsEditModalComponent } from "./components/specifications-edit-modal/specifications-edit-modal.component";
import { AddLocationModalComponent } from "./components/add-location-modal/add-location-modal.component";
import { AccountDropdownComponent } from "./components/account-dropdown/account-dropdown.component";
import { ShippingDimensionsComponent } from "./components/shipping-dimensions/shipping-dimensions.component";
import { MakeModelDropdownComponent } from "./components/make-model-dropdown/make-model-dropdown.component";
import { InventoryAttachmentPositionsComponent } from "./components/inventory-attachment-positions/inventory-attachment-positions.component";
import { UploadImageComponent } from "./components/upload-image/upload-image.component";
import { AttachmentPositionsComponent } from "./components/attachment-positions/attachment-positions.component";
import { DisplayNameDropdownComponent } from "./components/display-name-dropdown/display-name-dropdown.component";
import { RubblFormsModule } from "@rubbl-forms";
import { CoreModule, JoinArrayPipe } from "@rubbl-core";
import { AzureMapsModule } from "@rubbl-azure-maps";
import { InventoryModule } from "@rubbl-inventory";

@NgModule({
  declarations: [
    AccountDropdownComponent,
    AddLocationModalComponent,
    AttachmentPositionsComponent,
    InventoryAttachmentPositionsComponent,
    MakeModelDropdownComponent,
    DisplayNameDropdownComponent,
    ShippingDimensionsComponent,
    SpecificationsEditModalComponent,
    SpecificationsTableComponent,
    TypeDefinitionDropdownComponent,
    UploadImageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxDatatableModule,
    RouterModule,
    ToastrModule.forRoot(),
    RubblFormsModule,
    ReactiveFormsModule,
    CoreModule,
    AzureMapsModule,
    InventoryModule,
  ],
  exports: [
    AccountDropdownComponent,
    AddLocationModalComponent,
    AttachmentPositionsComponent,
    FormsModule,
    InventoryAttachmentPositionsComponent,
    MakeModelDropdownComponent,
    DisplayNameDropdownComponent,
    NgbModule,
    NgxDatatableModule,
    ShippingDimensionsComponent,
    SpecificationsEditModalComponent,
    SpecificationsTableComponent,
    ToastrModule,
    TypeDefinitionDropdownComponent,
    UploadImageComponent,
  ],
})
export class SharedModule {}
