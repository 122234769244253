import { Injectable } from "@angular/core";
import { UserManager, User, WebStorageStateStore, UserManagerSettings } from "oidc-client";
import { BehaviorSubject } from "rxjs";
import { LogService } from "./log.service";
import { EnvironmentConfig } from "../models/environment-config.model";
import { LocalStorageService } from "../services/local-storage.service";
import { Router } from "@angular/router";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private userManager: UserManager;
  private userManagerConfig: UserManagerSettings;

  private user: User;
  user$ = new BehaviorSubject<User>(null);

  public initialized = new BehaviorSubject<boolean>(false);
  public onLoggedIn = new BehaviorSubject<any>(null);

  constructor(private logService: LogService, private localStorageService: LocalStorageService, private router: Router) {
    this.userManagerConfig = {
      authority: `${environment.identityProvider}/`,
      client_id: environment.clientId,
      redirect_uri: environment.clientLoginRedirectUri,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.clientSilentLoginRedirectUri,
      post_logout_redirect_uri: environment.clientLogoutRedirectUri,
      response_type: "id_token token",
      scope: environment.identityScope ? environment.identityScope : "openid catalog inventory account user estimate contracts profile inspections",
      filterProtocolClaims: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    this.userManager = new UserManager(this.userManagerConfig);

    this.userManager.getUser().then(user => {
      if (user && !user.expired) {
        this.user = user;
        this.user$.next(user);
        this.logService.setUser(this.user.profile.sub, this.user.profile.account_id);
        this.localStorageService.set("hasLoggedIn", true);
        return;
      }

      // kind of a hack
      if (this.localStorageService.get("hasLoggedIn")) {
        // try to sign in the user silently.
        this.userManager.signinSilent().then(
          user => {
            this.user = user;
            this.user$.next(user);
            this.logService.setUser(this.user.profile.sub, this.user.profile.account_id);
          },
          error => {
            this.userManager.removeUser();
            this.userManager.clearStaleState();
            this.logService.clearUser();
          },
        );
      }
    });
    this.queryStringLogic();
    // we need to refresh the user in this service once the silent refresh callback occurs in silent-callback.html
    this.userManager.events.addUserLoaded(user => (this.user = user));
  }

  queryStringLogic() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("login")) {
      this.logService.pushTagToGtm({
        event: "accountAccess",
        accessType: "login",
        client: environment.clientId,
      });
      urlParams.delete("login");
    }

    if (urlParams.get("signup")) {
      this.logService.pushTagToGtm({
        event: "accountAccess",
        accessType: "signUp",
        client: environment.clientId,
      });
      urlParams.delete("signup");
    }

    // we need to store any first visit query params such as utm paramters so we can send it to the signup
    if (!this.localStorageService.get("firstVisitQueryParams")) {
      let firstVisitQueryParams = {};
      for (var value of urlParams["keys"]()) {
        firstVisitQueryParams[value] = urlParams.get(value);
      }

      if (Object.keys(firstVisitQueryParams).length != 0) {
        this.localStorageService.set("firstVisitQueryParams", firstVisitQueryParams);
      }
    }
  }

  getUser(): Promise<User> {
    return this.userManager.getUser();
  }

  getUserNonAsync() {
    return this.user;
  }

  login(redirectPath: string = "/equipment-rentals"): Promise<void> {
    if (environment.routePrefix) {
      redirectPath = `${environment.routePrefix}${redirectPath}`; // the redirect path comes with a trailing slash
    }

    redirectPath += redirectPath.includes("?") ? "&login=true" : "?login=true"; // need this to fire a login succesful event. really do not like this solution but will get reworked when we have new identity provider

    return this.userManager.signinRedirect({ state: { redirectPath } });
  }

  logout(): Promise<void> {
    this.userManager.clearStaleState();
    this.logService.clearUser();

    return this.userManager.signoutRedirect();
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getAccessToken(): string {
    return this.user ? this.user.access_token : "";
  }

  getRoles(): string[] {
    if (!this.user) {
      return null;
    }

    return Array.isArray(this.user.profile.role) ? this.user.profile.role : [this.user.profile.role];
  }

  signInSilent() {
    return this.userManager.signinSilent();
  }

  signoutRedirectCallback(): Promise<any> {
    return this.userManager.signoutRedirectCallback();
  }

  signUp(redirectPath: string = "/equipment-rentals"): Promise<any> {
    if (this.user) {
      // user is already logged in
      this.router.navigate([redirectPath]);
      return;
    }

    const firstVisitQueryParams = this.localStorageService.get("firstVisitQueryParams");
    let firstVisitQueryParamsString = "";

    if (firstVisitQueryParams) {
      const urlParams = new URLSearchParams(window.location.search);
      // if we have an affId query string but the user has already been to the site and the affId is not in local storage lets grab the aff id from the query string
      // its an edge case, but someone couldn have came from facebook. then saw an affiliates post and used the affiliates signup link
      if (urlParams.get("affId") != null && !firstVisitQueryParams.hasOwnProperty("affId")) {
        firstVisitQueryParams.affId = urlParams.get("affId");
      }

      firstVisitQueryParamsString = Object.keys(firstVisitQueryParams)
        .map(key => key + "=" + firstVisitQueryParams[key])
        .join("&");
    }

    redirectPath += redirectPath.includes("?") ? "&signup=true" : "?signup=true"; // need this to fire a signup succesful event
    return this.userManager.createSigninRequest({ state: { redirectPath } }).then(req => {
      const keepAuth = this.userManagerConfig.authority.replace("/auth/", "");
      const localUrl = req.url.replace(keepAuth, "");
      window.location.href = `${this.userManagerConfig.authority}Account/SignUp?${firstVisitQueryParamsString}&returnUrl=${encodeURIComponent(localUrl)}`;
    });
  }

  setUser(user) {
    this.user = user;
    this.onLoggedIn.next(user);
  }
}
