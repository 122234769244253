import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ThousandSuffixPipe } from "../../../core/core";
import { ComparisonTable } from "../../../tables/tables";
import { InventoryItem } from "../../models/inventory-item.model";

@Component({
  selector: "common-machine-rpo-table",
  templateUrl: "./machine-rpo-table.component.html",
  styleUrls: ["./machine-rpo-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ThousandSuffixPipe],
})
export class MachineRpoTableComponent implements OnInit {
  @Input() machine: InventoryItem;
  @Input() fontClass = "";
  comparisonTable: ComparisonTable;
  constructor(private thousandSuffix: ThousandSuffixPipe) {}

  ngOnInit(): void {
    const rentDeduction3 = this.machine.rentalRate * 0.8 * 3;
    const rentDeduction6 = this.machine.rentalRate * 0.8 * 6;

    const adjustedPrice3 = this.machine.purchasePrice - rentDeduction3;
    const adjustedPrice6 = this.machine.purchasePrice - rentDeduction6;

    this.comparisonTable = {
      headers: [],
      rows: [
        {
          columns: [{ label: "<b>Rental Duration</b>" }, { label: "<b>3 Mos</b>" }, { label: "<b>6 Mos</b>" }],
        },
        {
          columns: [
            { label: "Option Price" },
            { label: `$${this.thousandSuffix.transform(this.machine.purchasePrice, "1")}` },
            { label: `$${this.thousandSuffix.transform(this.machine.purchasePrice, "1")}` },
          ],
        },
        {
          columns: [
            { label: "Rental Credit" },
            { label: `<span class="badge badge-pill badge-success p-1 font-weight-light">$${this.thousandSuffix.transform(rentDeduction3, "1")}</span>` },
            { label: `<span class="badge badge-pill badge-success p-1 font-weight-light">$${this.thousandSuffix.transform(rentDeduction6, "1")}</span>` },
          ],
        },
        {
          columns: [
            { label: "<b>Purchase Price*</b>" },
            { label: `$${this.thousandSuffix.transform(adjustedPrice3, "1")}` },
            { label: `$${this.thousandSuffix.transform(adjustedPrice6, "1")}` },
          ],
        },
      ],
    };
  }
}
