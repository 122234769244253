import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { Directive } from "@angular/core";
import { BaseApiService } from "../../services/base-api.service";

@Directive()
export abstract class BaseEntityEditModalComponent {
  entity: any;
  originalEntity: any;
  newEntity: boolean;
  loading: boolean;
  activatedRoute: ActivatedRoute;
  navigateOnSave: boolean = true;
  savingEntity: boolean = false;

  activeInactiveDropdownItems: any[] = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  constructor(
    protected apiService: BaseApiService<any>,
    protected toastrService: ToastrService,
    protected router: Router,
    protected activeModal: NgbActiveModal,
    protected cancelCreatePath: string = "../",
    protected usePatch: boolean = false,
    protected createToastrText: string = "Successfuly created",
  ) {}

  save() {
    this.savingEntity = true;
    // todo add loading boolean to disable save and cancel buttons
    if (this.newEntity) {
      this.beforeSave(this.entity)
        .pipe(
          switchMap((e) => this.apiService.create(e)),
          switchMap((createdEntity) => this.createRelatedEntities(createdEntity)),
          switchMap((createdEntity) => {
            if (this.navigateOnSave) {
              this.router.navigateByUrl(this.router.url.replace("create", createdEntity.id)).then(() => {
                // i hate this but it works. eventually we need to have the edit slide out modal just pop out instead of using the create route.
                // this is because we are using the same route for create and details and the header will not load
                window.location.reload();
              });
            }
            // reason for timeout is we need to wait for the modal to close
            setTimeout(() => this.toastrService.success(this.createToastrText), 300);
            if (this.entity) {
              this.entity.id = createdEntity.id;
            }
            this.activeModal.close(this.entity);
            this.savingEntity = false;
            return of(createdEntity);
          }),
        )
        .subscribe();
      return;
    }

    let saveMethod = this.getSaveMethodForEdit();
    if (!saveMethod) {
      saveMethod = this.usePatch ? this.apiService.patch(this.entity.id, this.entity) : this.apiService.update(this.entity.id, this.entity);
    }

    this.beforeSave(this.entity)
      .pipe(
        switchMap((e) => {
          this.entity = e;
          return saveMethod.pipe(switchMap(() => this.updateRelatedEntities(this.entity)));
        }),
        tap((x) => {
          this.toastrService.success("Successfully saved");
          this.activeModal.close(this.entity);
        }),
      )
      .subscribe(); // TODO move this subscription out to either the child component or the view with the async pipe
  }

  cancel() {
    this.activeModal.dismiss();

    if (this.newEntity && this.cancelCreatePath) {
      this.router.navigate([this.cancelCreatePath], { relativeTo: this.activatedRoute, queryParamsHandling: "merge" });
    }
  }

  getSaveMethodForEdit() {
    return null;
  }

  // if you need to create related entities with the parent entity Id, override this function
  createRelatedEntities(createdEntity: any) {
    return of(createdEntity);
  }

  // currently not used right now, but we may want to start using this for updating related entities
  updateRelatedEntities(entity: any) {
    return of(entity);
  }

  // provides a hook for manipulating the entity prior to saving it
  // useful for compiling multiple view components into an entity
  // or in cases where the api entity doesn't match the objects in the view
  beforeSave(entity: any) {
    return of(entity);
  }
}
