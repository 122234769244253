import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfig, BaseApiService, AuthenticationService } from "../../core/core";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class RoleService extends BaseApiService<any> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.adminApiDomain, "/api/admin/v1/role", true, authenticationService);
  }
}
