import { Injectable } from "@angular/core";

@Injectable()
export class ArrayUtils {
  private static operation = (list1, list2, isUnion = false) => list1.filter(((set) => (a) => isUnion === set.has(a.id))(new Set(list2.map((b) => b.id))));

  static inBoth = (list1, list2) => ArrayUtils.operation(list1, list2, true);
  static inFirstOnly = ArrayUtils.operation;
  static inSecondOnly = (list1, list2) => ArrayUtils.inFirstOnly(list2, list1);
}
