<ng-container *ngIf="standardAttachments.length">
  <div class="bg-smoke-grey text-white d-flex px-3 py-2 justify-content-between align-items-center">
    <div>STANDARD ATTACHMENTS</div>
  </div>

  <p class="p-2 mb-0">Your first attachment, in each standard position, is included with your rental rate.</p>
  <!-- standard front -->
  <div class="bb-light bt-light bg-light-grey px-2" *ngIf="standardFront.length">Front</div>
  <ng-container
    *ngFor="let attachment of standardFront; last as isLast"
    [ngTemplateOutlet]="attachmentTemplate"
    [ngTemplateOutletContext]="{ attachment: attachment, isLast: isLast }"
  >
  </ng-container>

  <!-- standard rear -->
  <div class="bb-light bt-light bg-light-grey px-2" *ngIf="standardRear.length">Rear</div>
  <ng-container
    *ngFor="let attachment of standardRear; last as isLast"
    [ngTemplateOutlet]="attachmentTemplate"
    [ngTemplateOutletContext]="{ attachment: attachment, isLast: isLast }"
  >
  </ng-container>
</ng-container>
<ng-container *ngIf="nonStandardAttachments.length">
  <div class="bg-smoke-grey text-white d-flex px-3 py-2 justify-content-between align-items-center">
    <div>SPECIALTY ATTACHMENTS</div>
  </div>

  <!-- non standard front -->
  <div class="bb-light bt-light bg-light-grey px-2" *ngIf="nonStandardFront.length">Front</div>
  <ng-container
    *ngFor="let attachment of nonStandardFront; last as isLast"
    [ngTemplateOutlet]="attachmentTemplate"
    [ngTemplateOutletContext]="{ attachment: attachment, isLast: isLast }"
  >
  </ng-container>

  <!-- non standard rear -->
  <div class="bb-light bt-light bg-light-grey px-2" *ngIf="nonStandardRear.length">Rear</div>
  <ng-container
    *ngFor="let attachment of nonStandardRear; last as isLast"
    [ngTemplateOutlet]="attachmentTemplate"
    [ngTemplateOutletContext]="{ attachment: attachment, isLast: isLast }"
  >
  </ng-container>
</ng-container>

<ng-template #attachmentTemplate let-attachment="attachment" let-isLast="isLast">
  <div class="d-flex align-items-center py-2 px-2" [class.border-attachment]="!isLast">
    <div class="attachment-header-desc fs-15 mr-lg-3 mr-2">
      <div class="bold">{{ attachment.displayName }}</div>
      <div class="font-weight-light">{{ attachment.description }}</div>
    </div>
    <div class="ml-auto mr-3" *ngIf="!attachment.alreadyInCart">
      {{ attachment.price | currency: "USD":"symbol":"1.0-0" }}<span class="font-weight-light fs-14">/mo</span>
    </div>
    <div class="ml-auto mr-3 font-weight-light" *ngIf="attachment.alreadyInCart">Attachment in cart</div>
    <div class="flex-column" [class.d-flex]="!attachment.alreadyInCart" [class.d-none]="attachment.alreadyInCart">
      <button class="mb-2" [ngClass]="{ 'btn-selected': attachment.selected, 'btn-select': !attachment.selected }" (click)="selectAttachment(attachment)">
        Select
      </button>
      <button [ngClass]="{ 'btn-installed': attachment.install, 'btn-install': !attachment.install }" (click)="installAttachment(attachment)">Install</button>
    </div>
  </div>
</ng-template>
