import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AzureSearchDropdownComponent } from "./components/azure-search-dropdown/azure-search-dropdown.component";
import { RubblFormsModule } from "../forms/forms";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [AzureSearchDropdownComponent],
  imports: [CommonModule, RubblFormsModule, NgbDropdownModule],
  exports: [AzureSearchDropdownComponent],
})
export class AzureSearchModule {}
