<div class="date-picker-component" #datePickerComponent>
  <ng-container *ngIf="useAsInput">
    <input
      class="form-control cursor-pointer show-errors input-focus-border"
      rt="date-picker"
      [ngClass]="customClass"
      [class.invalid]="showErrorState && !valid"
      [class.active]="isOpen"
      type="text"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      (dateSelect)="onDateSelection($event)"
      autocomplete="off"
      [displayMonths]="numberOfMonthsToShow"
      [outsideDays]="outsideDays"
      navigation="arrows"
      autoClose="outside"
      [attr.placeholder]="placeHolder"
      (click)="openDatePicker()"
      [value]="inputValue"
      [markDisabled]="isDisabled"
      [firstDayOfWeek]="7"
      [dayTemplate]="dayTemplate"
      placement="bottom"
      [footerTemplate]="footerTemplate"
      (closed)="isOpen = false"
      [maxDate]="maxDate"
      [minDate]="minDate"
      #datePickerInput
      onfocus="this.blur()"
      (navigate)="navigate($event)"
    />

    <span class="focus-border" *ngIf="showFocusBorder"></span>
    <div class="icon-carrot-container">
      <i class="icon-carrot" *ngIf="showCarrot" (click)="openDatePicker()"></i>
    </div>

    <!-- only show job duration required if date is not complete and @input showErrorState is set to true -->
    <small class="form-text text-red" *ngIf="showErrorState && !valid">Job duration required</small>
  </ng-container>
  <ng-container *ngIf="!useAsInput">
    <ngb-datepicker
      class="not-dropdown-menu"
      rt="date-picker"
      [ngClass]="customClass"
      type="text"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="numberOfMonthsToShow"
      [outsideDays]="outsideDays"
      navigation="arrows"
      [firstDayOfWeek]="7"
      [dayTemplate]="dayTemplate"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (navigate)="navigate($event)"
    ></ngb-datepicker
  ></ng-container>

  <!-- day range template -->
  <ng-template #dayTemplate let-date let-currentMonth="currentMonth" let-focused="focused" let-selected="selected" let-disabled="disabled" let-test="disabled">
    <span
      [ngbTooltip]="(isBlockedOff(date) && blockedOffTooltip) || (isMaxDate(date) && maxDateTooltip)"
      triggers="click:blur"
      tooltipClass="custom-tooltip"
      class="custom-day"
      [class.h-100]="!useAsInput"
      [class.focused]="focused"
      [class.range]="isDateSelected(date)"
      [class.disabled]="disabled"
      [class.blocked-off]="isBlockedOff(date)"
      [class.outside-day]="isOutsideDay(currentMonth, date)"
      [class.max-date]="isMaxDate(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>

    <ng-template #blockedOffTooltip>
      This day is blocked off for order processing and transportation.
      <i class="icon-close text-light-grey"></i>
    </ng-template>

    <ng-template *ngIf="useAsInput" #maxDateTooltip>
      Rentals can only be placed within 14 days of today’s date.
      <i class="icon-close text-light-grey"></i>
    </ng-template>
  </ng-template>

  <!-- footer template -->
  <ng-template #footerTemplate>
    <!-- had to put dropdown arrow here because the day template gets called however many days there are -->
    <div class="dropdown-arrow-container">
      <img class="dropdown-arrow" [src]="baseImagePath + '/pubweb/marketplace/images/icons/dropdown-arrow-up.svg'" (load)="arrowLoaded()" />
    </div>
  </ng-template>
</div>
