<video [hidden]="!recording" class="w-100" id="preview" autoplay playsInline muted></video>
<!-- <video [hidden]="!recordedFile" class="w-100" id="recording" playsInline controls></video> -->

<div class="w-100 d-flex mt-5 justify-content-center" *ngIf="!recording && !finished">
  <div class="mt-5">
    <common-spinner-loader></common-spinner-loader>
    <p class="mt-5">Processing video</p>
  </div>
</div>

<div class="portrait">
  <div class="bg-white fixed-bottom p-3 bt-light" *ngIf="recording">
    <ng-container>
      <ngb-progressbar type="dark" [value]="progressbarValue"></ngb-progressbar>
      <div class="d-flex justify-content-end">
        <p>
          <small>{{ toHHMMSS(secondCounter) }} / {{ toHHMMSS(recordingTimeMS / 1000) }}</small>
        </p>
      </div>
      <div class="text-center">
        <button *ngIf="recording" class="btn btn-secondary px-5 btn-mobile mx-auto" type="button" (click)="stopButton()">Stop</button>
      </div>
    </ng-container>

    <!-- <ng-container *ngIf="!recording">
      <div class="d-flex align-items-baseline">
        <button class="btn btn-outline-secondary btn-block btn-mobile mr-2" type="button" (click)="cancel()">Cancel</button>
        <button class="btn btn-secondary btn-block btn-mobile ml-2" type="button" [disabled]="!finished" (click)="closeModal()">Save</button>
      </div>
    </ng-container> -->
  </div>
</div>
<div class="landscape">
  <div class="d-flex justify-content-between fixed-bottom p-2 bg-white bt-light">
    <ng-container *ngIf="recording">
      <div class="w-75">
        <ngb-progressbar type="dark" [value]="progressbarValue"></ngb-progressbar>
        <div class="d-flex justify-content-end">
          <p>
            <small>{{ toHHMMSS(secondCounter) }} / {{ toHHMMSS(recordingTimeMS / 1000) }}</small>
          </p>
        </div>
      </div>
      <button class="btn btn-secondary btn-mobile px-5" type="button" (click)="stopButton()">Stop</button>
    </ng-container>
    <!-- <ng-container *ngIf="!recording">
      <div class="w-50"></div>
      <button class="btn btn-outline-secondary btn-mobile px-5" type="button" (click)="cancel()">Cancel</button>
      <button class="btn btn-secondary btn-mobile px-5" type="button" [disabled]="!finished" (click)="closeModal()">Save</button>
    </ng-container> -->
  </div>
</div>
