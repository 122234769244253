<div rt="machine-summary">
  <div class="d-flex mb-1">
    <h4 class="section-title">{{ machine.year }} {{ machine.primaryType }}</h4>
    <div class="d-lg-none ml-auto pl-3" (click)="share()"><i class="icon-share text-red mr-1 fs-24"></i></div>
  </div>

  <div class="d-flex">
    <h2 class="model-header">{{ machine.make }} {{ machine.model }}</h2>
  </div>

  <div class="d-inline-flex">
    <common-top-owner *ngIf="machine.isTopOwner" class="mr-2" [showTopOwner]="machine.isTopOwner"></common-top-owner>

    <div class="alert alert-success fs-13 p-1 font-weight-light d-flex align-items-center" role="alert" *ngIf="machine.recentCancellations == 0">
      <i class="icon-insurance mr-1"></i> {{ machine.recentCancellations }} cancels in the past 3 months
    </div>

    <div class="alert alert-warning fs-13 p-1 font-weight-light d-flex align-items-center" role="alert" *ngIf="machine.recentCancellations == 1">
      <i class="icon-notification1 mr-1"></i> {{ machine.recentCancellations }} cancel in the past 3 months
    </div>

    <div class="alert alert-danger fs-13 p-1 font-weight-light d-flex align-items-center" role="alert" *ngIf="machine.recentCancellations > 1">
      <i class="icon-notification1 mr-1"></i> {{ machine.recentCancellations }} cancels in the past 3 months
    </div>
  </div>

  <div class="d-flex mb-2">
    <ng-container *ngIf="machine.location">
      <span class="subhead-2 mr-2">{{ machine.location | location: "cityState":false }}</span>
      <span class="text-light-grey mr-2">|</span>
    </ng-container>
    <span class="subhead-2 mr-2" *ngIf="machine.weight != null">{{ machine.weight | number: "1.0-0" }} lbs</span>
    <span class="text-light-grey" *ngIf="machine.weight != null && machine.enginePower != null">|</span>
    <span class="ml-2 subhead-2" *ngIf="machine.enginePower != null">{{ machine.enginePower | number: "1.0-0" }} HP</span>

    <span class="text-light-grey mr-2" *ngIf="machine.enginePower != null || machine.weight != null">|</span>
    <span class="subhead-2" *ngIf="machine.usage != null">{{ machine.usage | number: "1.0-0" }} {{ machine.usageLabel }}</span>
  </div>
  <div class="d-flex mb-2"></div>
</div>
