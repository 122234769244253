export class Address {
  address1: string = "";
  address2: string = "";
  city: string = "";
  stateProvince: string = "";
  postalCode?: string = "";
  country?: string = "";
  countryCode?: string = "";
  empty?: boolean;

  toString = (address: Address): string => {
    let str = "";
    if (address?.address1) {
      str = `${address.address1}`;
    }
    if (address?.address2?.length) {
      if (str.length) {
        str += " ";
      }
      str += `${address.address2}`;
    }
    if (address?.city?.length && address?.stateProvince?.length) {
      if (str.length) {
        str += " ";
      }
      str += `${address.city}, ${address.stateProvince}`;
    }
    if (address?.postalCode?.length) {
      if (str.length) {
        str += ", ";
      }
      str += `${address.postalCode}`;
    }

    return str;
  };
}
