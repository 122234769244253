import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Location } from '../../core/models/location.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LogService } from '../../core/services/log.service';
import { EnvironmentConfig } from '../../core/models/environment-config.model';
import { Address } from '../../core/models/address.model';

declare var environment: EnvironmentConfig;
@Injectable({
  providedIn: 'root'
})
export class AzureMapService {
  private url = "https://atlas.microsoft.com";
  constructor(private httpClient: HttpClient, private logger: LogService) {}

  public geoCode(location: string): Observable<Location> {
    const params = new HttpParams()
      .append('subscription-key', environment.azureMapKey)
      .append('api-version', '1.0')
      .append('query', location);

    return this.httpClient.get(`${this.url}/search/address/json`, { params })
      .pipe(map((http: any) => {
        const resp = new Location();
        this.logger.debug("Azure Map Service", http);

        if(http.summary.numResults > 0){
          const bestResult = http.results[0];
          resp.latitude = bestResult.position.lat;
          resp.longitude = bestResult.position.lon;
          resp.address.address1 = `${bestResult.address.streetNumber} ${bestResult.address.streetName}`;
          resp.address.address2 = "";
          resp.address.city = bestResult.address.municipality;
          resp.address.stateProvince = bestResult.address.countrySubdivision;
          resp.address.postalCode = bestResult.address.postalCode;
          resp.address.country = bestResult.address.countryCodeISO3;
          resp.name = `${resp.address.city}, ${resp.address.stateProvince}`


        }
        return resp;
      }));
  }

  public reverseGeocode(location: Location){
    const params = new HttpParams()
      .append('subscription-key', environment.azureMapKey)
      .append('api-version', '1.0')
      .append('query', `${location.latitude},${location.longitude}`)
      .append('number', '1'); // only return 1 result

      return this.httpClient.get(`${this.url}/search/address/reverse/json`, { params })
      .pipe(
        map( (resp:any) => {
          if(resp.addresses.length) {
            location.address = new Address();
            let address = resp.addresses[0].address;
            if(address.postalCode && address.streetNameAndNumber && address.municipality && address.countrySubdivision){
                location.address.address1 = address.streetNameAndNumber;
                location.address.postalCode = address.postalCode;
                location.address.city = address.municipality;
                location.address.stateProvince = address.countrySubdivision;
            }

            return location;
          }
        })
      );
  }

  public getReversedLocation(location: Location, allowPartialAddress: boolean = false) {
    return this.reverseGeocode(location).pipe(
      switchMap( x=> {
        // if we have a postal code and address 1 set the address. otherwise set the address to null since we do not have one
        if (x.address.postalCode && x.address.address1) {
          location.address = x.address;
        } else if( x.address.stateProvince && x.address.city && allowPartialAddress ){
          location.address = x.address;
        } else {
          location.address = null;
        }
        return of(location);
      })
    )
  }


}
