import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { AsideCartModalComponent } from "../../../cart/components/aside-cart-modal/aside-cart-modal.component";
import { CartService } from "../../../cart/cart.service";
import { ModalService } from "../../../core/core";

@Component({
  selector: "app-header-cart",
  templateUrl: "./header-cart.component.html",
  styleUrls: ["./header-cart.component.scss"],
})
export class HeaderCartComponent implements OnInit, OnDestroy {
  @Input() darkTheme: boolean = false;
  @Input() disableClick: boolean = false;

  numberOfItemsInCart = 0;

  cartSubscription: Subscription;

  constructor(private cartService: CartService, private modalService: ModalService) {}

  ngOnInit() {
    // if the cart status is not active we do not want to show the length of cart items
    // a cart will be set to inactive once we convert it from a cart to an order
    this.cartSubscription = this.cartService.cartBSubject.subscribe(
      cart => (this.numberOfItemsInCart = cart?.activeStatus === "Active" ? cart.items.length : 0),
    );
  }

  open() {
    if (this.disableClick) {
      return;
    }

    this.modalService.slideFromRight(AsideCartModalComponent, false);
  }

  ngOnDestroy() {
    if (this.cartSubscription.unsubscribe) {
      this.cartSubscription.unsubscribe();
    }
  }
}
