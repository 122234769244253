export class AzureSuggestsRequest {
  search: string;
  top: number = 4;
  suggesterName: string = "HomeSuggester";
  select: string = "displayText, searchPriority, autoFacetFields, autoFacetValues";
  highlightPreTag: string = "<b class='text-grey'>";
  highlightPostTag: string = "</b>";
  fuzzy: boolean = true;
  orderby: string = "searchPriority asc, displayText asc";
}
