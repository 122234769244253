import { Injectable } from '@angular/core';

@Injectable()

export class SortList {

  static sortByProperty(data: Array<any>, property: string, asc: boolean = true): Array<any> {

    const propertySliced = property.split('.');

    // ordering items
    if (asc) {
      data.sort((a, b) => {
        if (propertySliced.length === 1) {
          if (a[propertySliced[0]] < b[propertySliced[0]]) { return -1; }
          if (a[propertySliced[0]] > b[propertySliced[0]]) { return 1; }
        }
        if (propertySliced.length === 2) {
          if (a[propertySliced[0]][propertySliced[1]] < b[propertySliced[0]][propertySliced[1]]) { return -1; }
          if (a[propertySliced[0]][propertySliced[1]] > b[propertySliced[0]][propertySliced[1]]) { return 1; }
        }
        return 0;
      });
      return data;
    } 

    data.sort((a, b) => {
      if (propertySliced.length === 1) {
        if (a[propertySliced[0]] < b[propertySliced[0]]) { return 1; }
        if (a[propertySliced[0]] > b[propertySliced[0]]) { return -1; }
      }
      if (propertySliced.length === 2) {
        if (a[propertySliced[0]][propertySliced[1]] < b[propertySliced[0]][propertySliced[1]]) { return 1; }
        if (a[propertySliced[0]][propertySliced[1]] > b[propertySliced[0]][propertySliced[1]]) { return -1; }
      }
      return 0;
    });

    return data;
  }

  static sortList(data: Array<any>): Array<any> {
    // ordering items
    data.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    });

    return data;
  }
}
