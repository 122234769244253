import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'machineCategory'
})
export class MachineCategoryPipe implements PipeTransform {

  transform(value: string[] = [], args?: any): any {
    if (value.length === 0) {
      return "";
    }

    // remove last s. dozers => dozer
    return value[0].replace(/s$/, '').replace(/\b\w/g, l => l.toUpperCase());
  }

}
