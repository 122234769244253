import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ShippingDimension, Measurement } from '../models/machine-catalog.interface';

@Pipe({
  name: 'shippingDimension',
  pure: false
})
export class ShippingDimensionPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe, ) { }

  transform(shippingDimension: ShippingDimension, args?: any): any {
    const measurement = args === "metric" ? shippingDimension.metric : shippingDimension.standard;
    if (!measurement) {
      return "";
    }

    return `${this.decimalPipe.transform(measurement.value)} 
    <span class='subhead-4 text-grey ml-2'>${measurement.unitOfMeasure.primaryAbbreviation != null
        ? measurement.unitOfMeasure.primaryAbbreviation.toUpperCase() : ""}</span>`;

  }

}
