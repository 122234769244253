import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentCategory } from '../models/catalog-common.model';

@Pipe({
  name: 'equipmentCategory'
})
export class EquipmentCategoryPipe implements PipeTransform {

  transform(value: EquipmentCategory[] = [], args?: any): any {
    if (value.length === 0) {
      return "";
    }

    return value.map(x => x.name).join(", ");
  }
}
