import { CountValueFacetItem, Facet } from "./facet.model";
import { ParentChildCollectionFacet } from "./parent-child-collection-facet.model";

export class AttachmentFacet extends Facet {
  catalogs: ParentChildCollectionFacet;
  specs: ParentChildCollectionFacet;

  constructor(name: string, catalogs: CountValueFacetItem[], specs: CountValueFacetItem[]) {
    super(name, []);
    this.catalogs = new ParentChildCollectionFacet("relatedAttachments/typeDefinition", "attachmentCatalog", catalogs || []);
    this.specs = new ParentChildCollectionFacet("relatedAttachments/size", "attachmentSpec", specs || []);
  }

  get selected(): boolean {
    return this.catalogs.facets.some(d => d.checked);
  }

  filter(urlParams: URLSearchParams): string {
    const catalogFilter = this.catalogs.filter(urlParams, "or");

    if (this.catalogs.selected) {
      const selectedCatalogs = this.catalogs.facets.filter(d => d.checked).map(d => d.label);
      this.specs.facets = [];
      for (const spec of this.specs.allFacets) {
        const split = spec.value.split("|");
        const catalogForSpec = split[0];

        if (!selectedCatalogs.includes(catalogForSpec)) {
          continue;
        }
        if (spec.value.toLowerCase().includes("bucket") && split[1] == "") {
          spec.label = "Unknown Size";
        }
        spec.parentValue = catalogForSpec;
        this.specs.facets.push(spec);
      }
    } else {
      this.specs.clear();
    }

    const specsFilter = this.specs.filter(urlParams);
    const allAttachmentFilters = [catalogFilter, specsFilter].filter(d => d);
    return allAttachmentFilters.length ? `(${allAttachmentFilters.join(" and ")})` : null;
  }

  update = (catalogs: CountValueFacetItem[], specs: CountValueFacetItem[]) => {
    this.catalogs.update(catalogs);
    this.specs.update(specs);
    this.filter(new URLSearchParams(window.location.search));
  };

  uncheck = () => {
    this.catalogs.uncheck();
    this.specs.uncheck();
  };

  clear = () => {
    this.catalogs.clear();
    this.specs.clear();
  };

  toQueryParam = (): any => {
    if (!this.catalogs.selected) {
      this.specs.clear();
    }

    return [this.catalogs.toQueryParam(), this.specs.toQueryParam()];
  };
}
