import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SimpleSearchComponent } from "./components/simple-search/simple-search.component";

import { FormsModule } from "@angular/forms";
import { FormsModule as RubblFormsModule } from "../forms/forms.module";
import { HomeSearchComponent } from "./components/home-search/home-search.component";
import { CoreModule } from "../core/core";

@NgModule({
  declarations: [SimpleSearchComponent, HomeSearchComponent],
  imports: [CommonModule, FormsModule, RubblFormsModule, CoreModule],
  exports: [SimpleSearchComponent, HomeSearchComponent],
})
export class SearchResultsModule {}
