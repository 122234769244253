import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { EnvironmentConfig } from "../models/environment-config.model";

declare let environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class SeoService {
  clientNames = {
    "rubbl.website": {
      title: "Rent Construction & Earth Moving Equipment Near You | Rubbl",
      description: "We rent heavy machinery and specialized equipment near you. Insurance, delivery options, and special rates available. Start today.",
      keywords: "rubbl,heavy,equipment,construction,rental,rent,dirt,moving,dozer,excavator,caterpillar,near,me",
      image: "https://company.rubbl.com/pubweb/marketplace/images/logos/og-rubbl-dark.jpg",
    },
    "rubbl.admin": {
      title: "Admin | Rubbl",
      description: "",
      keywords: "",
      image: "https://company.rubbl.com/pubweb/marketplace/images/logos/og-rubbl-dark.jpg",
    },
    "rubbl.transportation": {
      title: "Transport | Rubbl",
      description: "",
      keywords: "",
      image: "https://company.rubbl.com/pubweb/marketplace/images/logos/og-rubbl-dark.jpg",
    },
    "rubbl.inspections": {
      title: "List your machines with Rubbl Inspect | Rubbl",
      description: "We rent heavy machinery and specialized equipment near you. Insurance, delivery options, and special rates available. Start today",
      keywords: "rubbl,heavy,equipment,construction,rental,rent,dirt,moving,dozer,excavator,caterpillar,near,me",
      image: "https://company.rubbl.com/pubweb/marketplace/images/logos/og-rubbl-dark.jpg",
    },
  };

  constructor(private title: Title, private meta: Meta) {}

  setSeo(routeData: SeoRouteData) {
    const clientMetadata = this.clientNames[environment.clientId];
    if (environment.production !== "true") {
      if (Object.keys(routeData).length === 0) {
        console.log(clientMetadata);
      } else {
        console.log(routeData);
      }
      return;
    }

    if (routeData.doNotUpdateSeo === true) {
      return;
    }

    // if route data has a title set it, otherwise set it to a generic title
    if (routeData.title) {
      this.title.setTitle(`${routeData.title}`);
      this.meta.updateTag({ property: "og:title", content: routeData.title }); // og title
    } else if (clientMetadata.title) {
      this.title.setTitle(clientMetadata.title);
      this.meta.updateTag({ property: "og:title", content: clientMetadata.title }); // og title
    }

    // set keywords
    if (routeData.keywords) {
      this.meta.updateTag({ name: "keywords", content: routeData.keywords });
    } else if (clientMetadata.keywords) {
      this.meta.updateTag({ name: "keywords", content: clientMetadata.keywords });
    }

    // if route has description set it, otherwise remove meta description
    if (routeData.description) {
      this.meta.updateTag({ name: "description", content: routeData.description });
      this.meta.updateTag({ property: "og:description", content: routeData.description });
    } else if (clientMetadata.description) {
      this.meta.updateTag({ name: "description", content: clientMetadata.description });
      this.meta.updateTag({ property: "og:description", content: clientMetadata.description });
    }

    // set og url
    this.meta.updateTag({ property: "og:url", content: window.location.href });

    // set og img
    if (routeData.image) {
      this.meta.updateTag({ property: "og:image", content: routeData.image });
    } else if (clientMetadata.image) {
      this.meta.updateTag({ property: "og:image", content: clientMetadata.image });
    }

    window["prerenderReady"] = true;
  }
}

export class SeoRouteData {
  doNotUpdateSeo: boolean;
  title: string;
  keywords: string;
  description: string;
  image: string;
}
