import { Component, OnInit, ComponentFactoryResolver, Injector, OnDestroy } from "@angular/core";
import * as atlas from "azure-maps-control";
import { EnvironmentConfig } from "../../../core/core";
import { AzureSearchService, AzureSearchRequest } from "../../../azure-search/azure-search";
import { AccountMapModel } from "./account-map.model";
import { flyInFromBelow } from "../../../rubbl-shared/animations";
import { ActivatedRoute } from "@angular/router";
import { Subscription, BehaviorSubject, forkJoin } from "rxjs";
import { tap, switchMap, filter, mergeMap } from "rxjs/operators";
import { AccountMapConfig } from "./account-map-config.model";
import { AccountMapService } from "./account-map.service";
import { AccountCardComponent } from "../../../account/account";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-account-map",
  templateUrl: "./account-map.component.html",
  styleUrls: ["./account-map.component.scss"],
  animations: [flyInFromBelow("250ms")],
})
export class AccountMapComponent implements OnInit, OnDestroy {
  map: any;
  datasource: any;
  popup = new atlas.Popup();
  request = new AzureSearchRequest();
  accountMapModel: AccountMapModel;
  onMobile: boolean;
  autoPlay: boolean;
  accountMapConfigSubscription: Subscription;
  accountMapConfig: AccountMapConfig = new AccountMapConfig();
  isInitialized: boolean = false;
  isAccountMapFilterModalOpened: boolean = false;
  onMapReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected azureSearchService: AzureSearchService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private route: ActivatedRoute,
    private accountMapService: AccountMapService,
  ) {
    this.request.top = 1000;
    this.onMobile = innerWidth < 767.98;
    this.autoPlay = this.route.snapshot.queryParamMap.get("autoplay") === "true";
  }

  ngOnDestroy() {
    this.accountMapConfigSubscription.unsubscribe();
  }

  ngOnInit() {
    this.request = new AzureSearchRequest();
    this.request.top = 5000;

    this.accountMapService
      .init$() // go retrieve the initial data needed to load the page
      .pipe(
        tap(result => {
          // create a new map instance after our config data is back
          //  Initialize a map instance.
          this.map = new atlas.Map("map", {
            center: this.onMobile ? [-97, 30] : [-95, 38],
            zoom: this.onMobile ? 3 : 4,
            maxZoom: 14,
            authOptions: {
              authType: atlas.AuthenticationType.subscriptionKey,
              subscriptionKey: environment.azureMapKey,
            },
          });

          this.map.events.add("ready", () => this.onMapReady());
        }),
        switchMap(x => this.onMapReady$), // subscribe to all onMapReady events
        filter(res => res), // only continue the pipe when map is ready
        mergeMap(res =>
          forkJoin(
            // add all of our images to the map sprites collection
            this.map.imageSprite.add("rental-company-icon", `${environment.baseImagePath}/pubweb/marketplace/images/icons/map-icons/house.png`),
            this.map.imageSprite.add("contractor", `${environment.baseImagePath}/pubweb/marketplace/images/icons/map-icons/hard-hat.png`),
            this.map.imageSprite.add("dealer", `${environment.baseImagePath}/pubweb/marketplace/images/icons/map-icons/key.png`),
            this.map.imageSprite.add("service", `${environment.baseImagePath}/pubweb/marketplace/images/icons/map-icons/wrench.png`),
          ),
        ),
        tap(res => {
          // perform some more map initialization after the map resources have loaded
          this.datasource = new atlas.source.DataSource(null, { cluster: true, clusterRadius: 25 }); // initialize our datasource
          this.map.sources.add(this.datasource);
          this.map.events.add("click", e => this.clickEvent(e));
          this.map.layers.add([
            this.createBubbleLayer(16, ["has", "point_count"]), // bubble layer for the clusters
            new atlas.layer.SymbolLayer(this.datasource, null, {
              // text layer for cluster
              iconOptions: { image: "none" },
              textOptions: {
                textField: "{point_count_abbreviated}",
                offset: [0, 0.4],
                color: "#FFFFFF",
                size: 18,
                font: ["StandardFont-Bold"],
              },
              filter: ["has", "point_count"],
            }),
            this.createBubbleLayer(16, ["!", ["has", "point_count"]]), // individual account bubble layer
            new atlas.layer.SymbolLayer(this.datasource, null, {
              // indivdual account marker
              iconOptions: {
                image: [
                  "case",
                  ["==", ["get", "companyType"], "Contractor"],
                  "contractor",
                  ["==", ["get", "companyType"], "RentalCompany"],
                  "rental-company-icon",
                  ["==", ["get", "companyType"], "Dealer"],
                  "dealer",
                  ["==", ["get", "companyType"], "Service"],
                  "service",
                  "rental-company-icon", // default
                ],
                offset: [0, 8],
              },
              filter: ["!", ["has", "point_count"]],
            }),
          ]);
        }),
        switchMap(x => this.accountMapService.accountMapConfig$), // from here down will be called every time config changes
        switchMap(result => {
          // new config is passed into this method where we build a search string and then call azure search
          this.accountMapConfig = result;
          let filter = "(amountCommitted gt 0 or machinesCommitted gt 0)";
          let accountTypeFilter = "";
          let companyTypeFilter = "";
          const companyTypes = result.filtersConfig.companyTypes
            .filter(companyType => companyType.checked)
            .map(companyTypeFiltered => companyTypeFiltered.value)
            .join(",");

          if (companyTypes) {
            companyTypeFilter = `search.in(companyType, '${companyTypes}')`;
          }

          let accountTypes = result.filtersConfig.accountTypes.filter(t => t.checked).map(t => t.value);

          if (accountTypes.length > 0) {
            accountTypeFilter = `search.in(status, '${accountTypes.join(",")}')`;
          }

          if (companyTypeFilter) {
            filter += " and " + companyTypeFilter;
          }

          if (accountTypeFilter) {
            filter += " and " + accountTypeFilter;
          }

          this.request.filter = filter;

          return this.azureSearchService.search(this.request, "accounts");
        }),
        tap(searchResult => {
          // after the call to azure search, take the data and add it to the map
          this.accountMapModel = new AccountMapModel(searchResult.value);
          if (this.datasource != null) {
            this.datasource.clear();
            this.datasource.add(this.accountMapModel);
          }
        }),
      )
      .subscribe();

    if (this.autoPlay) {
      setInterval(() => {
        this.showPopup(this.datasource.shapes[Math.floor(Math.random() * this.datasource.shapes.length)]);
      }, 5000);
    }

    this.isInitialized = true;
  }

  onMapReady() {
    this.map.controls.add(new atlas.control.ZoomControl(), {
      position: "top-right",
    });
    this.onMapReady$.next(true);
  }

  private createBubbleLayer(radius: number, filter: atlas.Expression) {
    const bubbleLayer = new atlas.layer.BubbleLayer(this.datasource, null, {
      radius,
      color: [
        "case",
        ["==", ["get", "companyType"], "Contractor"],
        "#FDAF09",
        ["==", ["get", "companyType"], "RentalCompany"],
        "#f53331",
        ["==", ["get", "companyType"], "Dealer"],
        "#5AD4D9",
        ["==", ["get", "companyType"], "Service"],
        "#0184FF",
        "#009688", // default
      ],
      strokeWidth: 3,
      blur: 0,
      filter,
    });

    this.map.events.add("mouseenter", bubbleLayer, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.events.add("mouseleave", bubbleLayer, () => {
      this.map.getCanvas().style.cursor = "";
    });
    return bubbleLayer;
  }

  clickEvent(e) {
    this.hidePopup();
    if (!e.shapes || e.shapes.length === 0) {
      return;
    }
    if (e.shapes[0].properties && e.shapes[0].properties.cluster === true) {
      this.clusterClicked(e.shapes[0]);
    } else if (e.shapes[0].getProperties) {
      this.showPopup(e.shapes[0]);
    }
  }

  clusterClicked(cluster) {
    this.datasource.getClusterExpansionZoom(cluster.properties.cluster_id).then(clusterZoom => {
      this.map.setCamera({
        center: cluster.geometry.coordinates,
        zoom: clusterZoom,
        type: "ease",
        duration: 500,
      });
    });
  }

  showPopup(shape) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(AccountCardComponent);
    const component = factory.create(this.injector);
    component.instance.account = shape.getProperties() || shape.properties;
    component.instance.yardCity = shape.data.geometry.city || shape.geometry.city;
    component.instance.brandColor = shape.data.properties.brandColor && shape.data.properties.brandColor.length ? shape.data.properties.brandColor : "";
    component.instance.logo =
      shape.data.properties.logo && shape.data.properties.logo.length ? `${environment.baseImagePath}${shape.data.properties.logo}` : "";
    component.changeDetectorRef.detectChanges();
    const popupContent = component.location.nativeElement;

    this.map.setCamera({
      center: shape.getCoordinates(),
      type: "ease",
      duration: 500,
      centerOffset: [0, -75],
    });
    this.popup.setOptions({
      position: shape.getCoordinates(),
      content: popupContent,
      pixelOffset: [0, -20],
    });
    this.popup.open(this.map);
  }

  hidePopup() {
    this.popup.close();
  }
}
