import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "common-button-spinner",
  templateUrl: "./button-spinner.component.html",
  styleUrls: ["./button-spinner.component.scss"],
})
export class ButtonSpinnerComponent {
  @Input() buttonClass: string;
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() svgSpinnerHeight = "35px";

  @Output() clickChange = new EventEmitter();

  constructor() {}

  onClick() {
    if (this.loading) {
      return;
    }

    this.clickChange.emit(null);
  }
}
