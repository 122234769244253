import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-rotating-caret",
  template: '<span class="caret icon-carrot" [class.caret-open]="rotateCaret" [style.fontSize]="fontSize" [style.color]="color"></span>',
  styleUrls: ["./rotating-caret.component.scss"],
})
export class RotatingCaretComponent implements OnInit {
  @Input() rotateCaret: boolean;
  @Input() fontSize: string;
  @Input() color: string;

  constructor() {}

  ngOnInit() {}
}
