import { AuditMetadata } from "../../core/core";
import { UnitOfMeasure } from "./machine-type.interface";

export interface CatalogItem {
  auditMetadata?: AuditMetadata;
  compatibleWith: CatalogItem[];
  description: string;
  displayName?: string;
  id: string;
  indexKey?: any;
  industries: Industry[];
  isActive: boolean;
  itemType?: string[];
  make: string;
  model: string;
  partitionKey?: string;
  primaryType: string;
  shippingDimensions?: ShippingDimensions;
  specifications?: any;
  status: CatalogStatus;
  typeDefinitionCode: string;
  typeDefinitionId: string;
  syncStatus: string;
  appendPrimarySpec: boolean;
  equipmentWatchModelId: number;
}

export interface MachineCatalog extends CatalogItem {}
export interface AttachmentCatalog extends CatalogItem {}

export enum CatalogStatus {
  Draft = "Draft",
  Published = "Published",
  Archived = "Archived",
}

export interface ShippingDimensions {
  width?: ShippingDimension;
  height?: ShippingDimension;
  length?: ShippingDimension;
  weight?: ShippingDimension;
  trucks?: any[];
}

export interface ShippingDimension {
  standard: Measurement;
  metric: Measurement;
}

export interface Measurement {
  value: number;
  unitOfMeasure: UnitOfMeasure;
}

export interface Industry {
  code: string;
  name: string;
}
