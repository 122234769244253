export class MarketTrackingEvents {
  static readonly Home = {
    Visit: "home-visit",
    // not using this for now. too granular for soft launch - JL
    // SearchSuggestSelect: "home-search-suggest-select",
    // JobStartDateSelect: "home-job-start-date-select",
    // JobDurationSelect: "home-job-duration-select",
    SearchButtonClick: "home-search-btn-click", // properties: searchTerm, job start date, job duration

    ExploreCardClick: "home-explore-card-click", // properties: category
    HowItWorksVideoButtonClick: "home-how-it-works-video-btn-click",
    HowItWorksLearnMoreButtonClick: "home-how-it-works-learn-more-btn-click",
    ListYourMachinesButtonClick: "home-list-your-machines-btn-click",
  };

  static readonly Search = {
    // search
    Header: "search-header", // properties: searchTerm
    SimpleSearch: "search-simple-search", // properties: searchTerm
  };

  static readonly SearchResults = {
    Visit: "search-results-visit",
  };

  static readonly SearchResult = {
    Click: "search-result-click", // properties: inventoryId
    ListViewClick: "search-result-list-view-click",
  };

  static readonly MachineDetails = {
    Visit: "machine-details-visit", // properties: inventoryId
  };

  static readonly Cart = {
    InitialAddToCartClick: "cart-initial-add-to-cart-click", // properties: inventoryId
    TryAddItem: "cart-try-add-item", // properties: inventoryId
    ItemAdded: "cart-item-added", // properties: inventoryId
    TryRemoveItem: "cart-try-remove-item", // properties inventoryId
    ItemRemoved: "cart-item-removed", // properties: inventoryId
    CartDeleted: "cart-deleted",
    Opened: "cart-opened",
    Closed: "cart-closed",
    CheckoutClick: "cart-checkout-click",
  };

  static readonly Checkout = {
    Review: "review",
    Coverage: "coverage",
    Transportation: "transportation",
    Terms: "terms",
    CreditCheck: "credit check",
    Pay: "pay",
    Verification: "verification",
    Complete: "complete",
  };
}
