import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { FormsModule as RubblFormsModule } from "../forms/forms.module";
import { InventoryModule } from "../inventory/inventory.module";
import { CartItemRemoveConfirmationComponent } from "./components/cart-item-remove-confirmation/cart-item-remove-confirmation.component";
import { MachineCardAddToCartComponent } from "./components/machine-card-add-to-cart/machine-card-add-to-cart.component";
import { AddToCartAttachmentsComponent } from "./components/machine-card-add-to-cart/add-to-cart-attachments/add-to-cart-attachments.component";
import { AddToCartDatesComponent } from "./components/machine-card-add-to-cart/add-to-cart-dates/add-to-cart-dates.component";
import { AddToCartDetailsComponent } from "./components/machine-card-add-to-cart/add-to-cart-details/add-to-cart-details.component";
import { FormsModule } from "@angular/forms";
import { AsideCartModalComponent } from "./components/aside-cart-modal/aside-cart-modal.component";
import { MachineCardAdvancedComponent } from "./components/machine-card-advanced/machine-card-advanced.component";
import { RouterModule } from "@angular/router";
import { CoreModule } from "../core/core.module";
import { AccountModule } from "../account/account.module";

@NgModule({
  declarations: [
    CartItemRemoveConfirmationComponent,
    MachineCardAddToCartComponent,
    AddToCartAttachmentsComponent,
    AddToCartDatesComponent,
    AddToCartDetailsComponent,
    AsideCartModalComponent,
    MachineCardAdvancedComponent,
  ],
  imports: [CommonModule, RouterModule, RubblSharedModule, RubblFormsModule, InventoryModule, FormsModule, CoreModule, AccountModule],
  exports: [
    CartItemRemoveConfirmationComponent,
    MachineCardAddToCartComponent,
    AddToCartDetailsComponent,
    MachineCardAdvancedComponent,
    AddToCartDatesComponent,
  ],
})
export class CartModule {}
