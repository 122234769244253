<div class="modal-header" rt="cart-item-remove-confirmation">
  <div></div>
  <h4 class="mb-0">Remove from Cart</h4>
  <i class="icon-close text-grey" id="icon-close" (click)="activeModal.dismiss()"></i>
</div>
<div class="modal-body p-4">
  Are you sure you want to remove this {{cartItemTitle}} from your cart?
</div>

<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="btn btn-outline-dark btn-modal-footer mr-4"
    (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-secondary btn-modal-footer" (click)="activeModal.close(true)">Remove</button>
</div>