import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EnvironmentConfig } from "./../../../core/models/environment-config.model";

declare var environment: EnvironmentConfig;
@Component({
  selector: "app-thumbnail-with-x",
  templateUrl: "./thumbnail-with-x.component.html",
  styleUrls: ["./thumbnail-with-x.component.scss"],
})
export class ThumbnailWithXComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() height: string = "100px";
  @Input() width: string = "100px";
  @Input() borderRadius: string = "0px";
  @Input() thumbnailClickPath = "";
  @Input() hideX: boolean = false;

  @Output() xClicked: EventEmitter<any> = new EventEmitter();

  fullImageUrl: string;
  imageLoadSuccess: boolean = true;

  constructor() {}

  ngOnInit() {
    if (!this.imgSrc) {
      this.imageLoadSuccess = false;
      return;
    }

    // need to remove if check. not needed since images from server should not include baseimagepath
    if (this.imgSrc.startsWith("http")) {
      this.fullImageUrl = this.imgSrc;
      return;
    }

    this.fullImageUrl = environment.baseImagePath + this.imgSrc;
  }

  xClick() {
    this.xClicked.emit(null);
  }

  thumbnailClick() {
    if (this.thumbnailClickPath === "") {
      return;
    }

    window.open(this.thumbnailClickPath, "_blank");
  }

  onImgError(event: Event) {
    this.imageLoadSuccess = false;
  }
}
