<div class="modal-header">
  <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="activeModal.dismiss()">Cancel</button>
  <input type="color" class="js-color" (input)="watchColorPicker($event)" />
  <button type="button" class="btn btn-secondary" (click)="savePhoto()">Save photo</button>
</div>
<div class="toolbar"></div>
<div class="wrapper" [ngStyle]="cssWrapper">
  <canvas #canvasBg class="js-canvas-bg"></canvas>
  <canvas #canvas class="js-canvas"></canvas>
</div>

<div class="modal-footer"></div>
