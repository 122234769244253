import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Status, IStatus } from '../models/status.model';
import { StatusList } from './status.service.data';

/*  
  Service for retrieving all of the configured statuses and their positions
  TODO make an API for retrieving this from the back end and use that
  TODO make subsets of statuses for different domains (i.e. CatalogStatuses, InventoryStatus, etc)
*/
@Injectable({
  providedIn: 'root'
})
export class StatusService {

  statusList: IStatus[] = StatusList;

  public get(entityName: string) {
    const statusFiltered = this.statusList
      .filter((statusToFilter: IStatus) => statusToFilter.availableForList &&
        statusToFilter.availableForList.some(availableFor => availableFor.availableFor === entityName))
      .map((status: IStatus) => {
        return new Status({
          status: status.status,
          color: status.color,
          border: status.border,
          availableForList: status.availableForList,
        });
      });

    const dataSorted = statusFiltered.sort((n1, n2) => {
      const availableFor1 = n1.availableForList.find(availableFor => availableFor.availableFor === entityName);
      const availableFor2 = n2.availableForList.find(availableFor => availableFor.availableFor === entityName);
      if (availableFor1.displayOrder > availableFor2.displayOrder) {
        return 1;
      }
      if (availableFor1.displayOrder < availableFor2.displayOrder) {
        return -1;
      }
      return 0;
    });
    return of(dataSorted);
  }

}
