import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { AccountService } from "../../services/account.service";
import { AccountSummary } from "../../models/account.model";
import { EnvironmentConfig } from "../../../core/core";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-owner-card",
  templateUrl: "./owner-card.component.html",
  styleUrls: ["./owner-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerCardComponent implements OnInit {
  @Input() searchResults: boolean = false;
  @Input() account: AccountSummary;
  @Input() accountId: string;
  logo: string;
  showAccountImage: boolean = true;

  constructor(private accountService: AccountService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.account) {
      this.logo = `${environment.baseImagePath}${this.account.logo}`;
      return;
    }

    // we dont have an account lets query for one
    this.accountService.getAccountSumary(this.accountId).subscribe(result => {
      this.account = result;
      this.logo = `${environment.baseImagePath}${this.account.logo}`;
      this.cd.detectChanges();
    });
  }

  onImgError(event: Event) {
    this.showAccountImage = false;
  }
}
