import { StringTransformations } from '../../../core/core';

export class RadioButton {
  groupName?: string;
  label: string;
  value: any;
}

export class RadioButtonItems {

  static createFromEnum(enumToUse): RadioButton[] {
    const radioButtonItems = Object.keys(enumToUse).map(e => ({
      label: enumToUse[e] === enumToUse[e].toUpperCase() ? enumToUse[e] : StringTransformations.capitalizeAndSeparate(enumToUse[e]),
      value: enumToUse[e]
    }) as RadioButton);

    return radioButtonItems;
  }

}
