import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogService } from "../../../core/core";

@Component({
  selector: "common-machine-category-card",
  templateUrl: "./machine-category-card.component.html",
  styleUrls: ["./machine-category-card.component.scss"],
})
export class MachineCategoryCardComponent {
  header: string;
  subHeader: string;
  cardImgSrc: string;
  trackingEvent: string;

  constructor(private router: Router, private logService: LogService) {}

  navigate() {
    // had to remove the trailing s from the header for some categories
    const primaryType = this.header.substring(0, this.header.length - 1);
    this.router.navigate(["equipment-rentals"], { queryParams: { primaryType } });
    if (this.trackingEvent) {
      this.logService.trackEvent(this.trackingEvent, { category: this.header });
    }
  }
}
