import { InventorySpec } from "./inventory-spec.model";
import { Valuation } from "./valuation.model";
import { AuditMetadata } from "../../core/models/audit-metadata.model";
import { ShippingDimensions, Specification } from "../inventory";
import { Address, IndexLocation } from "../../core/core";
import { AccountSummary } from "../../account/account";
import { Attachment } from "./attachment.model";
import { RelatedItem } from "../../cart/cart";

export class InventoryItem {
  id: string = "";
  affiliateId: string;
  catalogItemId: string;
  make: string;
  model: string;
  primaryType: string;
  enginePower: string;
  description: string;
  accountId: string;
  specification: InventorySpec = new InventorySpec();
  year: string;
  rentalRate: number;
  propertyTag: string;
  serialNumber: string;
  rubblAssetId: string;
  customerAssetId: string;
  rentalHistory: any[];
  appraisals: any[];
  images: string[];
  thumbnails: string[];
  blackoutDays: any[];
  scheduledRentals: any[];
  valuations: Valuation[];
  hours: number;
  itemType: string[];
  partitionKey: string;
  name?: any;
  auditMetadata: AuditMetadata;
  categories: string[];
  rating: number = 0;
  ratingInfo: RatingInfo = new RatingInfo();
  isTopOwner: boolean;
  location: InventoryLocation = new InventoryLocation();
  weight?: any;
  horsepower?: any;
  primaryCategory: string;
  usage: any;
  usageLabel: string;
  usageAbbreviation: string;
  shippingDimensions: ShippingDimensions;
  inventoryItemType: string;
  status: string;
  accountSummary: AccountSummary;
  account: AccountSummary;
  typeDefinitionId: string;
  displayName: string;
  relatedAttachments: Attachment[];
  relatedItems: RelatedItem[];
  positions: string[];
  specifications: Specification[];
  rateSchedules: RateSchedule[];
  internalNotes: string;
  typeDefinitionName: string;
  purchasePrice: number;
  rpoEnabled: boolean;
  insuranceValue: number;
  onboardingInspectionId: string;
  automaticAttachments: boolean;
  requiresAdminApproval: boolean; // obsolete
  approvalStatus: "Approved" | "Pending" | "Denied";
  deniedReason: string;
  distanceFromUser: number; //front end property only
  unavailableReason: string;
  autoAvailableDate: string;
  recentCancellations: number;
  createdDateTime: string; // index
  displayDate: string; // front end property only
  views30to60DaysAgo: number;
  viewsLast30Days: number;
  viewsPercentChange: number;
  viewsTotal: number;
  lastUpdatedByOwnerDateTime: string;
  inventoryGroupId: string;
  buyItNowEnabled: boolean;
  buyItNowPrice: number;
}

export class InventoryLocation {
  name: string;
  latitude: string;
  longitude: string;
  point: IndexLocation = new IndexLocation();
  address: Address;
}

export class RatingInfo {
  overall: number = 0;
  accuracy: number = 0;
  communication: number = 0;
  experience: number = 0;
  value: number = 0;
  machine: number = 0;
  inspection: number = 0;
}

export interface RateSchedule {
  label: string;
  numDays: number;
  cost: number;
  discount: number;
  discountPercent: number;
}
