import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { HeaderComponent } from "./header/header.component";
import { HeaderListMachineComponent } from "./header/header-list-machine/header-list-machine.component";
import { HeaderSignUpComponent } from "./header/header-sign-up/header-sign-up.component";
import { HeaderLoginComponent } from "./header/header-login/header-login.component";
import { HeaderCartComponent } from "./header/header-cart/header-cart.component";
import { HeaderAccountComponent } from "./header/header-account/header-account.component";
import { HeaderOverlayComponent } from "./header/header-overlay/header-overlay.component";
import { SearchSuggestsOverlayComponent } from "./body/search-suggests-overlay/search-suggests-overlay.component";
import { HeaderSearchComponent } from "./header/header-search/header-search.component";
import { TopBarNavigationComponent } from "./body/top-bar-navigation/top-bar-navigation.component";
import { FooterComponent } from "./footer/footer.component";
import { CommonModule } from "@angular/common";
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from "@angular/forms";
import { SideBarNavigationComponent } from "./body/side-bar-navigation/side-bar-navigation.component";
import { CancelConfirmModalComponent } from "./modals/cancel-confirm-modal/cancel-confirm.modal.component";
import { InternalHeaderComponent } from "./internal-header/internal-header.component";
import { InternalHeaderAccountComponent } from "./internal-header/internal-header-account/internal-header-account.component";
import { BottomNavigationComponent } from "./bottom-navigation/bottom-navigation.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { AppearDirective } from "./services/appear.directive";
import { SwiperModule } from "ngx-swiper-wrapper";
import { CoreModule } from "../core/core.module";
import { CartModule } from "../cart/cart.module";
import { HeaderConnectComponent } from "./header/header-connect/header-connect.component";

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderListMachineComponent,
    HeaderSignUpComponent,
    HeaderLoginComponent,
    HeaderCartComponent,
    HeaderAccountComponent,
    HeaderOverlayComponent,
    SearchSuggestsOverlayComponent,
    HeaderSearchComponent,
    TopBarNavigationComponent,
    FooterComponent,
    SideBarNavigationComponent,
    CancelConfirmModalComponent,
    InternalHeaderComponent,
    InternalHeaderAccountComponent,
    BottomNavigationComponent,
    AppearDirective,
    HeaderConnectComponent,
  ],
  imports: [CommonModule, RouterModule, NgbDropdownModule, AngularFormsModule, ReactiveFormsModule, CoreModule, SwiperModule, CartModule],
  exports: [
    HeaderComponent,
    HeaderOverlayComponent,
    SearchSuggestsOverlayComponent,
    FooterComponent,
    TopBarNavigationComponent,
    SideBarNavigationComponent,
    InternalHeaderComponent,
    BottomNavigationComponent,
    AppearDirective,
  ],
})
export class LayoutModule {}
