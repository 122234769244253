import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from "@angular/core";
import { AzureMapConfig } from "../../models/azure-map-config";

import { Location } from "../../../core/core";
import { AzureMapComponent } from "../azure-map/azure-map.component";

@Component({
  selector: "common-machine-map-location",
  templateUrl: "./machine-map-location.component.html",
  styleUrls: ["./machine-map-location.component.scss"],
})
export class MachineMapLocationComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() location: Location;
  @Input() enableUpdate: boolean = false;
  @Input() lastUpdate: Date;
  @Input() emptyMessage: string = "There are not location for this machine";
  @Input() header: string = "Location";

  @Output() requestUpdate = new EventEmitter<any>();

  @ViewChild("azureMapComponent") azureMapComponent: AzureMapComponent;

  mapConfig = new AzureMapConfig();
  requestDate: Date;

  constructor() {}

  ngOnInit() {
    this.mapConfig.zoom = 13;
    this.requestDate = new Date();
  }

  ngAfterViewInit() {
    this.updateLocation(this.location);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location.isFirstChange) {
      this.updateLocation(this.location);
    }
  }

  updateLocation(location: Location) {
    this.location = location;
    if (!this.azureMapComponent) {
      return;
    }

    this.azureMapComponent.locations = [this.location];
    this.azureMapComponent.onLocationChange();
    this.updateRequestDate();
  }

  requestUpdateLocation() {
    this.requestUpdate.emit();
  }

  updateRequestDate() {
    this.requestDate = new Date();
  }
}
