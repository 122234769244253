import { Injectable } from "@angular/core";
import { NavigationItem } from "../models/navigation-item.model";

@Injectable({
  providedIn: "root",
})
// this needs to be in market. the reason its in common is because we have the marketplace header in common. marketplace header also needs to be moved into market
export class RoutesService {
  renterNavigationItem: NavigationItem = {
    label: "Renter",
    route: "/renter",
    children: [
      {
        label: "Dashboard",
        route: "/renter/dashboard",
      },
      {
        label: "Rentals",
        route: "/renter/rentals",
      },
      {
        label: "Verification",
        route: "/renter/verification",
      },
      // {
      //   label: "Insurance",
      //   route: "/renter/insurance",
      // },
      // {
      //   label: "Tax",
      //   route: "/renter/tax",
      // },
      {
        label: "Billing",
        route: "/renter/billing",
      },
    ],
  };

  ownerNavigationItem: NavigationItem = {
    label: "Owner",
    route: "/owner",
    children: [
      {
        label: "Dashboard",
        route: "/owner/dashboard",
      },
      {
        label: "Fleet",
        route: "/owner/fleet",
      },
      {
        label: "Fulfillments",
        route: "/owner/fulfillments",
      },
      {
        label: "Inspections",
        route: "/owner/inspections",
      },
      {
        label: "Earnings",
        route: "/owner/earnings",
      },
    ],
  };

  affiliateNavigationItem: NavigationItem = {
    label: "Affiliate",
    route: "/affiliate",
    children: [
      {
        label: "Dashboard",
        route: "/affiliate/dashboard",
      },
      {
        label: "Invite",
        route: "/affiliate/invite",
      },
    ],
  };

  connectNavigationItems: NavigationItem = {
    label: "Rubbl Connect",
    route: "/connect",
    children: [
      {
        label: "Rentals",
        route: "/affiliate/alerts",
      },
      {
        label: "Transport",
        route: "/affiliate/alerts",
      },
      {
        label: "Service",
        route: "/affiliate/alerts",
      },
      {
        label: "Settings",
        route: "/affiliate/alerts",
      },
    ],
  };

  accountNavigationItem: NavigationItem = {
    label: "Account",
    route: "/account",
    children: [
      {
        label: "Personal Info",
        route: "/account/personal-info",
      },
      {
        label: "Payment Methods",
        route: "/account/payment",
      },
      // {
      //   label: "Credit Info",
      //   route: "/account/credit",
      // },
      {
        label: "Notifications",
        route: "/account/notifications",
      },
    ],
  };

  allNavigationItems: NavigationItem[] = [this.renterNavigationItem, this.ownerNavigationItem, this.affiliateNavigationItem, this.accountNavigationItem];
  constructor() {}
}
