import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RotatingCaretComponent } from "./components/rotating-caret/rotating-caret.component";
import { ThumbnailWithXComponent } from "./components/thumbnail-with-x/thumbnail-with-x.component";
import { StepProgressBarComponent } from "./components/step-progress-bar/step-progress-bar.component";
import { DotLoaderComponent } from "./components/dot-loader/dot-loader.component";
import { BackToPageLinkComponent } from "./components/back-to-page-link/back-to-page-link.component";
import { StarRatingComponent } from "./components/star-rating/star-rating.component";
import { SwiperModule } from "ngx-swiper-wrapper";
import { CustomToastComponent } from "./components/custom-toast/custom-toast.component";
import { IconProgressBarComponent } from "./components/icon-progress-bar/icon-progress-bar.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { NgxMaskModule } from "ngx-mask";
import { NgProgressModule } from "ngx-progressbar";

import { TypewriterDirective } from "./directives/typewriter.directive";
import { ListDropdownGroupComponent } from "./components/list-dropdown-group/list-dropdown-group.component";
import { SpinnerLoaderComponent } from "./components/spinner-loader/spinner-loader.component";
import { ReadMoreTextComponent } from "./components/read-more-text/read-more-text.component";
import { ImageGalleryComponent } from "./components/image-gallery/image-gallery.component";
import { FormsModule } from "@angular/forms";
import { QrCodeComponent } from "./components/qr-code/qr-code.component";
import { ShowEntityJsonComponent } from "./components/show-entity-json/show-entity-json.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    RotatingCaretComponent,
    ThumbnailWithXComponent,
    StepProgressBarComponent,
    DotLoaderComponent,
    BackToPageLinkComponent,
    StarRatingComponent,
    CustomToastComponent,
    IconProgressBarComponent,
    BreadcrumbComponent,
    TypewriterDirective,
    ListDropdownGroupComponent,
    SpinnerLoaderComponent,
    ReadMoreTextComponent,
    ImageGalleryComponent,
    QrCodeComponent,
    ShowEntityJsonComponent,
  ],
  imports: [CommonModule, SwiperModule, NgxMaskModule.forRoot({}), NgProgressModule, FormsModule, RouterModule],
  exports: [
    RotatingCaretComponent,
    ThumbnailWithXComponent,
    StepProgressBarComponent,
    DotLoaderComponent,
    BackToPageLinkComponent,
    StarRatingComponent,
    SwiperModule,
    CustomToastComponent,
    IconProgressBarComponent,
    BreadcrumbComponent,
    NgxMaskModule,
    NgProgressModule,
    TypewriterDirective,
    ListDropdownGroupComponent,
    SpinnerLoaderComponent,
    ReadMoreTextComponent,
    ImageGalleryComponent,
    QrCodeComponent,
    ShowEntityJsonComponent,
  ],
})
export class RubblSharedModule {}
