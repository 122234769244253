import { StringTransformations } from "../utils/string-transformations/string-transformations";

export interface IStatusAvailableFor {
  availableFor: string;
  displayOrder?: number;
}

export class StatusAvailableFor implements IStatusAvailableFor {
  availableFor: string;
  displayOrder?: number;
  constructor(availableFor: string, displayOrder: number = 0) {
    this.availableFor = availableFor;
    this.displayOrder = displayOrder;
  }
}
export interface IStatus {
  status: string;
  color: string;
  border: string;
  availableForList?: IStatusAvailableFor[];
  displayName?: string;
}
export class Status implements IStatus {
  status: string;
  color: string;
  border: string;
  get displayName(): string {
    return StringTransformations.capitalizeAndSeparate(this.status);
  }
  availableForList?: StatusAvailableFor[];
  constructor(items?: IStatus) {
    this.status = items?.status || "";
    this.color = items?.color || "";
    this.border = items?.border || "";
    this.availableForList = items?.availableForList || [];
  }
}
